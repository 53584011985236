import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik, FieldArray, FormikProvider } from 'formik'
import * as Yup from 'yup'
import { client } from '../../../libs/apollo'
import toast from 'react-hot-toast'
import { PlusCircle, Trash, Collection } from 'react-bootstrap-icons'

const NewDefaultServiceItemGroupModal = (props) => {
  const { showModal, toggleModal } = props

  const [createDefaultServiceItemGroup] = useMutation(
    gql`
      mutation CreateServiceItemGroup(
        $input: CreateDefaultServiceItemGroupInput!
      ) {
        createDefaultServiceItemGroup(input: $input) {
          defaultServiceItemGroup {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        toast.success('Task Group Created')
        toggleModal()
        formik.resetForm()
      },
      errorPolicy: 'all',
      refetchQueries: ['DefaultServiceItemGroupsQuery'],
    }
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      defaultServiceItems: [{ description: '' }],
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('required')
        .test('isUnique', 'Name must be unique', async (value) => {
          const { data } = await client.query({
            query: gql`
              query UniqueNewServiceItemQuery($name: String) {
                defaultServiceItemGroups(name_Iexact: $name) {
                  nodeCount
                }
              }
            `,
            fetchPolicy: 'network-only',
            variables: {
              name: value,
            },
          })
          return data.defaultServiceItemGroups.nodeCount === 0
        }),
      defaultServiceItems: Yup.array()
        .of(
          Yup.object().shape({
            description: Yup.string().required('Description is required'),
          })
        )
        .required('Must have at least one task'),
    }),
    onSubmit: (values) => {
      const input = {
        defaultServiceItemGroupInput: {
          name: values.name,
          defaultServiceItems: values.defaultServiceItems.map((item) => ({
            description: item.description,
          })),
        },
      }

      createDefaultServiceItemGroup({
        variables: {
          input,
        },
      })
    },
  })

  const innerToggleModal = () => {
    toggleModal()
    formik.resetForm()
  }

  return (
    <FormikProvider value={formik}>
      <div className="newServiceItemGroupModal">
        <Modal
          size="lg"
          show={showModal}
          onHide={innerToggleModal}
          aria-labelledby="taskGroupModal"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="taskGroupModal">
                <Collection className="mr-2" />
                New Task Collection
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3 align-items-center">
                <Form.Label column sm="2">
                  Collection Name
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="text"
                    name="name"
                    className="form-control-sm"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Tasks
                </Form.Label>
              </Form.Group>

              <FieldArray
                name="defaultServiceItems"
                render={(arrayHelpers) => (
                  <>
                    <div
                      style={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        marginBottom: '10px',
                      }}
                    >
                      {formik.values.defaultServiceItems.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-3 align-items-center"
                          >
                            <Form.Label column sm="2">
                              Name
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                rows={2}
                                name={`defaultServiceItems.${index}.description`}
                                value={item.description}
                                onChange={formik.handleChange}
                                isInvalid={
                                  formik.errors.defaultServiceItems &&
                                  formik.errors.defaultServiceItems[index]
                                    ?.description
                                }
                                className="form-control-sm"
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.defaultServiceItems &&
                                  formik.errors.defaultServiceItems[index]
                                    ?.description}
                              </Form.Control.Feedback>
                            </Col>
                            <Col sm="2" className="text-left">
                              <Button
                                type="button"
                                variant="link"
                                size="sm"
                                style={{ color: '#007bff' }}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <Trash className="mr-2" />
                                Remove
                              </Button>
                            </Col>
                          </Form.Group>
                        </div>
                      ))}
                    </div>
                    <Button
                      type="button"
                      size="sm"
                      className="btn-link"
                      onClick={() => arrayHelpers.push({ description: '' })}
                    >
                      <PlusCircle className="mr-2" />
                      Add Task
                    </Button>
                  </>
                )}
              />

              <Row className="mt-4">
                <Col md={3}>
                  <Button
                    type="submit"
                    size="sm"
                    block
                    variant="outline-primary"
                  >
                    <PlusCircle className="mr-2" />
                    Create
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </FormikProvider>
  )
}

export default NewDefaultServiceItemGroupModal
