import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Form, ProgressBar } from 'react-bootstrap'
import Report from '../chart/LineChart'
import DateFilter from '../common/DateFilter'
import { useLazyQuery, gql } from '@apollo/client'
import { BarChartLine } from 'react-bootstrap-icons'

const NotificationChartModal = (props) => {
  const {
    showModal,
    toggleModal,
    subjectId,
    jobId,
    gaiaUserId,
    sessionId,
    employeeScheduleId,
    organizationId,
    subjectGroupId,
    stripeInvoiceId,
    employeeId,
    contactId,
  } = props

  const [chartSeries, setChartSeries] = useState([])
  const [chartCategories, setChartCategories] = useState([])
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [notifications, setNotifications] = useState([])
  const [fetchedAllNotifications, setFetchedAllNotifications] = useState(false)
  const [viewType, setViewType] = useState('Total Notifications')
  const [notificationType, setNotificationType] = useState(
    'Email and SMS Notifications'
  )
  const [emailCount, setEmailCount] = useState(0)
  const [smsCount, setSmsCount] = useState(0)
  const [averageDays, setAverageDays] = useState(1)
  const [totalNotifications, setTotalNotifications] = useState(0)
  const [fetchedNotificationsCount, setFetchedNotificationsCount] = useState(0)

  const generateDateRange = (start, end) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const dates = []
    let currentDate = new Date(startDate)
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0])
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return dates
  }

  const [query, { data: notificationsData, loading }] = useLazyQuery(
    gql`
      query NotificationsQuery(
        $cursor: String
        $subjects: [ID]
        $jobs: [ID]
        $sessions: [ID]
        $employees: [ID]
        $stripeInvoices: [ID]
        $subjectGroups: [ID]
        $organizations: [ID]
        $gaiaUsers: String
        $employeeSchedules: [ID]
        $contact: Boolean
        $startDateGte: DateTime
        $startDateLte: DateTime
        $orderBy: String
      ) {
        notifications(
          first: 250
          after: $cursor
          subject: $subjects
          job: $jobs
          session: $sessions
          employee: $employees
          stripeInvoice: $stripeInvoices
          subjectGroup: $subjectGroups
          organization: $organizations
          gaiaUser: $gaiaUsers
          employeeSchedule: $employeeSchedules
          contact: $contact
          created_Gte: $startDateGte
          created_Lte: $startDateLte
          orderBy: $orderBy
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              created
              smtpConnector {
                id
              }
              twilioConnector {
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const queryVariables = () => {
    const variables = {
      startDateGte: startDateFilter,
      startDateLte: endDateFilter,
      orderBy: '-created',
    }

    if (subjectId) variables.subjects = [subjectId]
    if (jobId) variables.jobs = [jobId]
    if (sessionId) variables.sessions = [sessionId]
    if (employeeId) variables.employees = [employeeId]
    if (stripeInvoiceId) variables.stripeInvoices = [stripeInvoiceId]
    if (subjectGroupId) variables.subjectGroups = [subjectGroupId]
    if (organizationId) variables.organizations = [organizationId]
    if (gaiaUserId) variables.gaiaUsers = gaiaUserId
    if (employeeScheduleId) variables.employeeSchedules = [employeeScheduleId]
    if (contactId) variables.contact = contactId

    return variables
  }

  useEffect(() => {
    if (startDateFilter && endDateFilter) {
      const daysDiff =
        Math.ceil(
          (new Date(endDateFilter) - new Date(startDateFilter)) /
            (1000 * 60 * 60 * 24)
        ) + 1
      setAverageDays(Math.max(1, daysDiff))
      query({ variables: queryVariables() })
      // Reset state when dates change
      setNotifications([])
      setFetchedAllNotifications(false)
      setFetchedNotificationsCount(0)
    }
  }, [startDateFilter, endDateFilter])

  useEffect(() => {
    if (notificationsData?.notifications) {
      const total = notificationsData.notifications.nodeCount
      setTotalNotifications(total)

      setNotifications((prevState) => [
        ...prevState,
        ...notificationsData.notifications.edges,
      ])
      setFetchedNotificationsCount(
        (prevState) => prevState + notificationsData.notifications.edges.length
      )
      if (notificationsData.notifications.pageInfo.hasNextPage) {
        const variables = {
          ...queryVariables(),
          cursor: notificationsData.notifications.pageInfo.endCursor,
        }
        query({ variables })
      } else {
        setFetchedAllNotifications(true)
      }
    }
  }, [notificationsData])

  useEffect(() => {
    if (fetchedAllNotifications) {
      const dateRange = generateDateRange(startDateFilter, endDateFilter)
      const notificationsList = notifications.map((edge) => ({
        date: new Date(edge.node.created).toISOString().split('T')[0],
        isSMS: !!edge.node.twilioConnector,
        isEmail: !!edge.node.smtpConnector,
      }))

      // Calculate email and SMS counts
      const totalEmailCount = notificationsList.filter((n) => n.isEmail).length
      const totalSmsCount = notificationsList.filter((n) => n.isSMS).length

      // Update state with the calculated counts
      setEmailCount(totalEmailCount)
      setSmsCount(totalSmsCount)

      let filteredNotifications = notificationsList
      if (notificationType === 'SMS Notifications') {
        filteredNotifications = notificationsList.filter((n) => n.isSMS)
      } else if (notificationType === 'Email Notifications') {
        filteredNotifications = notificationsList.filter((n) => n.isEmail)
      }

      // Aggregate total, SMS, and email notifications by date
      const aggregatedTotalData = filteredNotifications.reduce(
        (acc, { date }) => {
          acc[date] = (acc[date] || 0) + 1
          return acc
        },
        {}
      )

      const aggregatedSMSData = notificationsList.reduce(
        (acc, { date, isSMS }) => {
          if (isSMS) {
            acc[date] = (acc[date] || 0) + 1
          }
          return acc
        },
        {}
      )

      const aggregatedEmailData = notificationsList.reduce(
        (acc, { date, isEmail }) => {
          if (isEmail) {
            acc[date] = (acc[date] || 0) + 1
          }
          return acc
        },
        {}
      )

      let totalSeriesData = []
      let smsSeriesData = []
      let emailSeriesData = []

      if (notificationType === 'Email and SMS Notifications') {
        totalSeriesData = dateRange.map(
          (date) => aggregatedTotalData[date] || 0
        )
        smsSeriesData = dateRange.map((date) => aggregatedSMSData[date] || 0)
        emailSeriesData = dateRange.map(
          (date) => aggregatedEmailData[date] || 0
        )
      } else if (notificationType === 'SMS Notifications') {
        totalSeriesData = dateRange.map(
          (date) => aggregatedTotalData[date] || 0
        )
        smsSeriesData = dateRange.map((date) => aggregatedSMSData[date] || 0)
      } else if (notificationType === 'Email Notifications') {
        totalSeriesData = dateRange.map(
          (date) => aggregatedTotalData[date] || 0
        )
        emailSeriesData = dateRange.map(
          (date) => aggregatedEmailData[date] || 0
        )
      }

      if (viewType === 'Total Notifications') {
        let runningTotalTotal = 0
        let runningTotalSMS = 0
        let runningTotalEmail = 0

        totalSeriesData = dateRange.map((date) => {
          runningTotalTotal += aggregatedTotalData[date] || 0
          return runningTotalTotal
        })

        smsSeriesData = dateRange.map((date) => {
          runningTotalSMS += aggregatedSMSData[date] || 0
          return runningTotalSMS
        })

        emailSeriesData = dateRange.map((date) => {
          runningTotalEmail += aggregatedEmailData[date] || 0
          return runningTotalEmail
        })
      }

      // Set categories and chart series
      setChartCategories(dateRange)

      const newChartSeries = []
      if (notificationType === 'Email and SMS Notifications') {
        newChartSeries.push(
          { name: 'Total Notifications', data: totalSeriesData },
          { name: 'SMS Notifications', data: smsSeriesData },
          { name: 'Email Notifications', data: emailSeriesData }
        )
      } else if (notificationType === 'SMS Notifications') {
        newChartSeries.push(
          { name: 'Total Notifications', data: totalSeriesData },
          { name: 'SMS Notifications', data: smsSeriesData }
        )
      } else if (notificationType === 'Email Notifications') {
        newChartSeries.push(
          { name: 'Total Notifications', data: totalSeriesData },
          { name: 'Email Notifications', data: emailSeriesData }
        )
      }

      setChartSeries(newChartSeries)
    }
  }, [
    fetchedAllNotifications,
    notifications,
    notificationType,
    viewType,
    startDateFilter,
    endDateFilter,
  ])

  // Determine the progress bar variant based on the percentage
  const transferPercent = totalNotifications
    ? Math.round((fetchedNotificationsCount / totalNotifications) * 100)
    : 0

  let transferVariant = 'info'
  if (transferPercent < 33) {
    transferVariant = 'danger'
  } else if (transferPercent < 66) {
    transferVariant = 'warning'
  } else if (transferPercent >= 66) {
    transferVariant = 'success'
  }

  return (
    <>
      <div className="notificationModal">
        <Modal size={'xl'} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <BarChartLine className="mr-2" />
              Notification Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-2">
              <Col md={5}>
                <DateFilter
                  startDateFilter={startDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  endDateFilter={endDateFilter}
                  setEndDateFilter={setEndDateFilter}
                  placeholderStart="Notifications From"
                  placeholderEnd="Notifications Until"
                />
              </Col>
              <Col md={3} className="mt-2">
                <Form.Control
                  as="select"
                  className="form-control-sm"
                  value={viewType}
                  onChange={(e) => setViewType(e.target.value)}
                >
                  <option value="Notifications Per Day">
                    Notifications Per Day
                  </option>
                  <option value="Total Notifications">
                    Total Notifications
                  </option>
                </Form.Control>
              </Col>
              <Col md={3} className="mt-2">
                <Form.Control
                  as="select"
                  className="form-control-sm"
                  value={notificationType}
                  onChange={(e) => setNotificationType(e.target.value)}
                >
                  <option value="Email and SMS Notifications">
                    Email and SMS Notifications
                  </option>
                  <option value="Email Notifications">
                    Email Notifications
                  </option>
                  <option value="SMS Notifications">SMS Notifications</option>
                </Form.Control>
              </Col>
            </Row>
            {startDateFilter &&
              endDateFilter &&
              !fetchedAllNotifications &&
              totalNotifications > 0 &&
              !isNaN(fetchedNotificationsCount) && (
                <Row className="mt-4">
                  <Col className="text-center" md={{ offset: 1, span: 10 }}>
                    <ProgressBar
                      animated
                      variant={transferVariant}
                      now={transferPercent}
                      label={`${transferPercent}% Complete Loading Notifications`}
                    />
                  </Col>
                </Row>
              )}
            {fetchedAllNotifications && (
              <>
                <div className="statistics-summary">
                  <Row className="ml-2 mb-4 mt-4">
                    <Col md={4} className="statistic">
                      <h5>
                        {viewType === 'Notifications Per Day'
                          ? 'Average Notifications Per Day'
                          : 'Total Notifications'}
                      </h5>
                      <p>
                        {viewType === 'Notifications Per Day'
                          ? Math.round(notifications.length / averageDays)
                          : notifications.length}
                      </p>
                    </Col>
                    {notificationType === 'Email and SMS Notifications' && (
                      <>
                        <Col md={4} className="statistic">
                          <h5>
                            {viewType === 'Notifications Per Day'
                              ? 'Average Email Notifications Per Day'
                              : 'Total Email Notifications'}
                          </h5>
                          <p>
                            {viewType === 'Notifications Per Day'
                              ? Math.round(emailCount / averageDays)
                              : emailCount}
                          </p>
                        </Col>
                        <Col md={4} className="statistic">
                          <h5>
                            {viewType === 'Notifications Per Day'
                              ? 'Average SMS Notifications Per Day'
                              : 'Total SMS Notifications'}
                          </h5>
                          <p>
                            {viewType === 'Notifications Per Day'
                              ? Math.round(smsCount / averageDays)
                              : smsCount}
                          </p>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
                {chartSeries.length > 0 && (
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Report
                        series={chartSeries}
                        categories={chartCategories}
                        yAxisFormatter={(value) => value}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default NotificationChartModal
