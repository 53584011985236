import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useMutation, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import {
  Cash,
  CloudArrowDown,
  BarChartLine,
  Trash,
  Funnel,
  PlusCircle,
} from 'react-bootstrap-icons'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { DateTime } from 'luxon'
import toast from 'react-hot-toast'
import SendInvoiceModal from './SendInvoiceModal'
import DateFilter from '../common/DateFilter'
import { loggedInUserVar } from '../../libs/apollo'
import OrganizationBilling from '../organization/OrganizationBilling'
import InvoiceModal from './InvoiceModal'
import DeleteInvoicesModal from './DeleteInvoicesModal'
import InvoiceChartModal from './InvoiceChartModal'
import { useDownloadFile } from '../../libs/downloadFile'

const InvoiceTable = (props) => {
  const {
    stripeCustomerId,
    gaiaUserId,
    organizationId,
    invoiceRecipient,
    subjectGroupId,
    jobId,
    productId,
    productModal,
    sessionId,
    tableHeight,
    fetchPolicy,
  } = props
  const { downloadAndDeleteFile } = useDownloadFile()
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [viewInvoice, setViewInvoice] = useState()
  const [loading, setLoading] = useState(true)
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [showInvoiceModal, setShowInvoiceModal] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [showChartModal, setShowChartModal] = useState(false)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [showDeleteInvoicesModal, setShowDeleteInvoicesModel] = useState(false)
  const [checkedInvoiceIds, setCheckedInvoiceIds] = useState([])
  const [filterPaid, setFilterPaid] = useState(false)
  const [filterPaymentFailed, setFilterPaymentFailed] = useState(false)
  const [filterVoid, setFilterVoid] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)
  const canCreateInvoices = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const defaultOrderBy = '-created'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  const handleInvoiceCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedInvoiceIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedInvoiceIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const [query, { error, data, fetchMore: queryFetchMore }] = useLazyQuery(
    gql`
      query StripeInvoices(
        $cursor: String
        $status: String
        $void: Boolean
        $paid: Boolean
        $job: String
        $paymentFailed: Boolean
        $searchTerm: String
        $orderBy: String
        $stripeCustomerId: ID
        $session: String
        $organizationId: ID
        $productId: ID
        $gaiaUserId: ID
        $subjectGroup: ID
        $startDateGte: DateTime
        $startDateLte: DateTime
      ) {
        stripeInvoices(
          first: 20
          after: $cursor
          paid: $paid
          void: $void
          job: $job
          status: $status
          paymentFailed: $paymentFailed
          stripeCustomer: $stripeCustomerId
          stripeCustomer_Organization: $organizationId
          created_Gte: $startDateGte
          created_Lte: $startDateLte
          stripeInvoiceItems_Session_SubjectGroup: $subjectGroup
          session: $session
          search_Icontains: $searchTerm
          stripeCustomer_GaiaUser: $gaiaUserId
          stripeInvoiceItems_Product: $productId
          orderBy: $orderBy
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              recordId
              created
              paymentFailed
              paidManually
              paidOn
              price
              status
              fee
              netAmount
              recipientEmail
              amountRemaining
              amountPaid
              amountDue
              effectiveAt
              dueDate
              stripeInvoiceId
              invoicePdfUrl
              invoicePaymentUrl
              stripePaymentIntents {
                edges {
                  node {
                    processing
                  }
                }
              }
              paidManuallyBy
              stripeCustomer {
                organization {
                  id
                  name
                }
                gaiaUser {
                  subject {
                    id
                  }
                  fullName
                }
              }
              sessionCount
              products {
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: fetchPolicy ? fetchPolicy : 'network-only',
      errorPolicy: 'all',
      // pollInterval: 5000,
    }
  )

  const queryVariables = () => {
    const variables = {
      cursor: data?.stripeInvoices?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (sessionId) {
      variables.session = sessionId
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (jobId) {
      variables.job = jobId
    }
    if (organizationId) {
      variables.organizationId = organizationId
    }
    if (subjectGroupId) {
      variables.subjectGroup = subjectGroupId
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    if (stripeCustomerId) {
      variables.stripeCustomerId = stripeCustomerId
    }
    if (productId) {
      variables.productId = productId
    }
    if (gaiaUserId) {
      variables.gaiaUserId = gaiaUserId
    }
    if (filterOpen) {
      variables.status = 'Open'
    }
    if (filterPaid) {
      variables.paid = true
    }
    if (filterPaymentFailed) {
      variables.paymentFailed = true
    }
    if (filterVoid) {
      variables.void = true
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      setInitialQueryRun(true)
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (initialQueryRun) {
      setLoading(true)
      const variables = queryVariables()
      variables.cursor = null
      query({ variables })
    }
  }, [filterOpen, filterPaid, filterVoid, filterPaymentFailed])

  useEffect(() => {
    if (data?.stripeInvoices?.edges) {
      setLoading(false)
      let text = 'Search 0 Invoices'
      const current = data.stripeInvoices.edges.map((stripeInvoice) => ({
        node: stripeInvoice.node,
      }))
      setInvoices(current)
      if (data.stripeInvoices.nodeCount > 0) {
        text = `Search ${data.stripeInvoices.nodeCount} Invoices`
      }
      setSearchText(text)
    }
  }, [data])

  const fetchMore = () => {
    const variables = queryVariables()
    queryFetchMore({
      variables,
    })
  }

  useEffect(() => {
    if (initialQueryRun) {
      setLoading(true)
      const variables = queryVariables()
      variables.cursor = null
      variables.startDateGte = startDateFilter
      variables.startDateLte = endDateFilter
      query({ variables })
    }
  }, [startDateFilter, endDateFilter])

  const handleSearchTermChange = (event) => {
    setLoading(true)
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    const variables = queryVariables()
    variables.cursor = null
    variables.searchTerm = currentSearchTerm
    query({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return
    setLoading(true)
    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = queryVariables()
    variables.cursor = null
    variables.orderBy = currentOrderBy
    query({ variables })
  }

  const toggleModal = (stripeInvoiceId = null) => {
    if (stripeInvoiceId) {
      setViewInvoice(stripeInvoiceId)
    } else {
      setViewInvoice()
    }
  }

  let tableColumns = [
    {
      Header: 'ID',
      id: 'id',
      serverSort: true,
      accessor: (row) => {
        return row.node.recordId
      },
    },
    {
      Header: 'Invoice #',
      id: 'number',
      serverSort: true,
      orderBy: 'stripe_invoice_id',
      accessor: (row) => {
        return row.node.stripeInvoiceId
      },
    },
    {
      Header: 'Status',
      id: 'status',
      serverSort: true,
      accessor: (row) => {
        if (row.node.paidManually) {
          return <span style={{ color: 'green' }}>Paid Manually</span>
        } else if (row.node.paymentFailed) {
          return <span style={{ color: 'red' }}>Payment Failed</span>
        } else if (
          row.node.stripePaymentIntents.edges.length > 0 &&
          row.node.stripePaymentIntents.edges[0].node.processing
        ) {
          return <span style={{ color: 'red' }}>Processing Payment</span>
        }
        const status = row.node.status
        let color
        if (['Open', 'Uncollectible'].includes(status)) {
          color = 'red'
        } else if (status === 'Void') {
          color = 'orange'
        } else if (status === 'Paid') {
          color = 'green'
        }
        return <span style={{ color }}>{status}</span>
      },
    },
    {
      Header: 'Invoice Items',
      id: 'product',
      serverSort: true,
      orderBy: 'stripe_invoice_items__product__name',
      accessor: (row) => {
        let productDisplay = []
        if (row.node.products.length > 0) {
          const productCounts = row.node.products.reduce((acc, product) => {
            acc[product.name] = (acc[product.name] || 0) + 1
            return acc
          }, {})
          productDisplay = Object.entries(productCounts).map(
            ([name, count], i) => (
              <span key={i} style={{ fontSize: '12px' }}>
                {count} {name}
                <br />
              </span>
            )
          )
        }
        if (row.node.sessionCount) {
          const sessions = row.node.sessionCount === 1 ? 'Session' : 'Sessions'
          productDisplay.push(
            <span style={{ fontSize: '12px' }}>
              {row.node.sessionCount} {sessions}
              <br />
            </span>
          )
        }

        return <>{productDisplay}</>
      },
    },
    {
      Header: 'Amount Due',
      id: 'due',
      serverSort: true,
      orderBy: 'amount_due',
      accessor: (row) => {
        return `${(row.node.amountDue / 100).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}`
      },
    },
    {
      Header: 'Amount Paid',
      id: 'paid',
      serverSort: true,
      orderBy: 'amount_paid',
      accessor: (row) => {
        if (row.node.paidManually) {
          return `${(row.node.amountDue / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}`
        } else {
          return `${(row.node.amountPaid / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}`
        }
      },
    },
    {
      Header: 'Stripe Fee',
      id: 'fee',
      accessor: (row) => {
        if (row.node.fee) {
          return `${row.node.fee.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}`
        }
      },
    },
    {
      Header: 'Net Amount',
      id: 'netAmount',
      accessor: (row) => {
        if (row.node.netAmount) {
          return `${row.node.netAmount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}`
        }
      },
    },
    {
      Header: 'Sent On',
      id: 'sentOn',
      serverSort: true,
      orderBy: 'effective_at',
      accessor: (row) => {
        return DateTime.fromISO(row.node.effectiveAt).toFormat('MMMM dd, yyyy')
      },
    },
    {
      Header: 'Due On',
      id: 'dueOn',
      serverSort: true,
      orderBy: 'due_date',
      accessor: (row) => {
        return DateTime.fromISO(row.node.dueDate).toFormat('MMMM dd, yyyy')
      },
    },
    {
      Header: 'Paid On',
      id: 'paidOn',
      serverSort: true,
      orderBy: 'paid_on',
      accessor: (row) => {
        if (row.node.paidOn) {
          return DateTime.fromISO(row.node.paidOn).toFormat('MMMM dd, yyyy')
        }
      },
    },
    {
      Header: 'Download',
      id: 'download',
      disableSortBy: true,
      accessor: (row) => {
        return (
          <Button variant="link">
            <a
              href={row.node.invoicePdfUrl}
              download
              style={{ fontSize: '22px' }}
            >
              <CloudArrowDown />
            </a>
          </Button>
        )
      },
    },
    {
      Header: 'Open Stripe',
      id: 'openStripe',
      disableSortBy: true,
      accessor: (row) => {
        return (
          <Button
            variant="link"
            onClick={() => {
              window.open(row.node.invoicePaymentUrl, '_blank')
            }}
          >
            <span style={{ fontSize: '22px' }}>
              <Cash />
            </span>
          </Button>
        )
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  invoices.forEach((invoice) => {
                    if (!checkedInvoiceIds.includes(invoice.node.id)) {
                      appendIds.push(invoice.node.id)
                    }
                  })
                  setCheckedInvoiceIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedInvoiceIds([])
                }
              }}
            />
            {checkedInvoiceIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedInvoiceIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'downloads',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedInvoiceIds.includes(row.node.id)}
                onChange={(e) => handleInvoiceCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]

  if (productId) {
    tableColumns.splice(2, 1)
  }

  if (!organizationId && !gaiaUserId) {
    tableColumns.splice(2, 0, {
      Header: 'Organization',
      id: 'organization',
      serverSort: true,
      orderBy: 'stripe_customer__organization__name',
      accessor: (row) => {
        if (row.node.stripeCustomer.organization) {
          return <span>{row.node.stripeCustomer.organization.name}</span>
        }
      },
    })
    tableColumns.splice(2, 0, {
      Header: 'Subject',
      id: 'subject',
      serverSort: true,
      orderBy: 'stripe_customer__gaia_user__full_name',
      accessor: (row) => {
        if (row.node.stripeCustomer.gaiaUser) {
          return <span>{row.node.stripeCustomer.gaiaUser.fullName}</span>
        }
      },
    })
  }

  const [downloadInvoice] = useMutation(
    gql`
      mutation DownloadStripeInvoices($input: DownloadStripeInvoicesInput!) {
        downloadStripeInvoices(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadStripeInvoices.file.fileName,
          data.downloadStripeInvoices.file.displayName,
          data.downloadStripeInvoices.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const onTdClick = (cell) => {
    toggleModal(cell.row.original.node.id)
  }

  if (error) return <>Error loading</>
  return (
    <>
      <div>
        {organizationId && (
          <OrganizationBilling organizationId={organizationId} />
        )}
        {showChartModal && (
          <InvoiceChartModal
            showModal={showChartModal}
            startDateTime={startDateFilter}
            stripeCustomerId={stripeCustomerId}
            organizationId={organizationId}
            subjectGroupId={subjectGroupId}
            productId={productId}
            jobId={jobId}
            gaiaUserId={gaiaUserId}
            endDataTime={endDateFilter}
            toggleModal={() => {
              setShowChartModal()
            }}
          />
        )}
        <Row>
          <Col>
            {(organizationId || gaiaUserId) && canCreateInvoices && (
              <Button
                variant="link"
                onClick={() => {
                  setShowInvoiceModal(true)
                }}
              >
                <PlusCircle className="mr-2" />
                New Invoice
              </Button>
            )}
            {canMutate && (
              <Button
                variant="link"
                onClick={() => {
                  setShowChartModal(true)
                }}
              >
                <BarChartLine className="mr-2" />
                Report
              </Button>
            )}
            <Button
              variant="link"
              onClick={() => {
                setFilterPaid(!filterPaid)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterPaid ? <>Paid</> : <>All</>}
              </span>
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setFilterPaymentFailed(!filterPaymentFailed)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterPaymentFailed ? <>Payment Failed</> : <>All</>}
              </span>
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setFilterOpen(!filterOpen)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterOpen ? <>Open</> : <>All</>}
              </span>
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setFilterVoid(!filterVoid)
              }}
            >
              <span>
                <Funnel className="mr-2" />
                {!filterVoid ? <>Void</> : <>All</>}
              </span>
            </Button>
            {startDateFilter && endDateFilter && (
              <>
                <Button
                  variant="link"
                  disabled={downloadingPdf}
                  onClick={() => {
                    setDownloadingPdf(true)
                    downloadInvoice({
                      variables: {
                        input: {
                          fileType: 'pdf',
                          startDate: startDateFilter,
                          endDate: endDateFilter,
                          stripeCustomerId,
                          gaiaUserId,
                          organizationId,
                          subjectGroupId,
                          productId,
                          paid: filterPaid,
                          void: filterVoid,
                          open: filterOpen,
                          paymentFailed: filterPaymentFailed,
                        },
                      },
                    })
                  }}
                >
                  <CloudArrowDown className="mr-2" />
                  {!productModal && <>Download</>} PDF
                </Button>
                <Button
                  variant="link"
                  disabled={downloadingExcel}
                  onClick={() => {
                    setDownloadingExcel(true)
                    downloadInvoice({
                      variables: {
                        input: {
                          fileType: 'xlsx',
                          startDate: startDateFilter,
                          endDate: endDateFilter,
                          stripeCustomerId,
                          gaiaUserId,
                          organizationId,
                          subjectGroupId,
                          productId,
                          paid: filterPaid,
                          void: filterVoid,
                          open: filterOpen,
                          paymentFailed: filterPaymentFailed,
                        },
                      },
                    })
                  }}
                >
                  <CloudArrowDown className="mr-2" />
                  {!productModal && <>Download</>} Excel
                </Button>
              </>
            )}
            {checkedInvoiceIds.length > 0 && (
              <>
                {canMutate && (
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowDeleteInvoicesModel(true)
                    }}
                  >
                    <Trash className="mr-2" />
                    Delete {!productModal && <>Invoices</>}
                  </Button>
                )}
              </>
            )}
          </Col>
        </Row>
        <Row className="mt-2 d-flex">
          <Col md={startDateFilter && endDateFilter ? 3 : 4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={searchText}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          <Col md={4} style={{ marginTop: '-8px' }}>
            <DateFilter
              startDateFilter={startDateFilter}
              setStartDateFilter={setStartDateFilter}
              endDateFilter={endDateFilter}
              setEndDateFilter={setEndDateFilter}
              placeholderStart="Invoices From"
              placeholderEnd={'Invoices Until'}
            />
          </Col>
        </Row>

        <>
          <Row className="mt-3 mb-3">
            <Col md={12}>
              <SortableInfiniteTable
                tableData={invoices ? invoices : []}
                loading={loading}
                tableColumns={tableColumns}
                fetchMoreTableData={fetchMore}
                loadingMessage="Loading Invoices..."
                hasMoreTableData={data?.stripeInvoices?.pageInfo?.hasNextPage}
                onTdClicks={{
                  id: onTdClick,
                  number: onTdClick,
                  paidManually: onTdClick,
                  status: onTdClick,
                  emailedTo: onTdClick,
                  subject: onTdClick,
                  product: onTdClick,
                  due: onTdClick,
                  paid: onTdClick,
                  remaining: onTdClick,
                  sentOn: onTdClick,
                  dueOn: onTdClick,
                  organization: onTdClick,
                }}
                tableHeight={tableHeight ? tableHeight : productId ? 300 : 700}
                rowPointer
                hideGlobalFilter
                handleSortByChange={handleSortByChange}
              />
            </Col>
          </Row>
        </>
        <SendInvoiceModal
          toggleModal={setShowInvoiceModal}
          showModal={showInvoiceModal}
          organizationId={organizationId}
          recipient={invoiceRecipient}
          gaiaUserId={gaiaUserId}
          hideOrganization={organizationId ? true : false}
        />
        <InvoiceModal
          showModal={viewInvoice}
          toggleModal={toggleModal}
          stripeInvoiceId={viewInvoice}
          hideOrganization={organizationId ? true : false}
        />
        <DeleteInvoicesModal
          showModal={showDeleteInvoicesModal}
          toggleModal={() => {
            setShowDeleteInvoicesModel(false)
          }}
          setCheckedInvoiceIds={setCheckedInvoiceIds}
          stripeInvoiceIds={
            checkedInvoiceIds.length > 1 ? checkedInvoiceIds : null
          }
          stripeInvoiceId={
            checkedInvoiceIds.length == 1 ? checkedInvoiceIds[0] : null
          }
        />
      </div>
    </>
  )
}
export default InvoiceTable
