import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'
import { DateTime } from 'luxon'

const JobSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="job"
      searchDescription={searchDescription ? searchDescription : 'jobs'}
      nodeNamePlural="jobs"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      gql={gql`
        query Jobs($first: Int, $after: String, $search: String) {
          jobs(
            first: $first
            after: $after
            orderBy: "name"
            name_Icontains: $search
          ) {
            nodeCount
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                name
                startDateTime
                endDateTime
              }
            }
          }
        }
      `}
      formatDescription={(node) => {
        const startDateTime = DateTime.fromISO(node.startDateTime)
        const endDateTime = DateTime.fromISO(node.endDateTime)
        return `${node.name} ${startDateTime.toFormat(
          'MMMM dd yyyy hh:mma'
        )}-${endDateTime.toFormat('hh:mma')}`
      }}
    />
  )
}

export default JobSearchInput
