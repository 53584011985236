import React, { useState, useEffect } from 'react'
import { Form, Table, Alert, Col, Row } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import Loading from '../Loading'
import { XCircle, Trash, PlusCircle } from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'

const SearchInput = (props) => {
  const {
    formik,
    nodeNamePlural,
    nodeName,
    disabled,
    searchDescription,
    gql,
    create,
    formatDescription,
    multiple,
    formikValue,
    variables,
    setAdditionalFields,
    dropdown,
    fetchPolicy,
    discludeResults,
    error,
    placeholder,
  } = props

  const [displayResults, setDisplayResults] = useState(false)

  const [query, { data, fetchMore, error: queryError }] = useLazyQuery(gql, {
    fetchPolicy: fetchPolicy ? fetchPolicy : 'network-only',
    variables,
  })

  const formikValueFromFieldName = (path) => {
    const parts = path.replace(/\[(\w+)\]/g, '.$1').split('.')
    let current = formik.values
    for (let part of parts) {
      if (current[part] === undefined) {
        return undefined
      }
      current = current[part]
    }
    return current ? current : ''
  }

  useEffect(() => {
    if (dropdown) {
      query()
    }
  }, [dropdown])

  if (queryError) {
    return <Alert variant="danger">Error Loading</Alert>
  }

  let _discludeResults = discludeResults || []
  let _formikIdValue
  let _formikDescriptionValue
  let _formikMultipleValue
  if (formikValue) {
    _formikIdValue = `${formikValue}Id`
    _formikDescriptionValue = `${formikValue}Description`
    _formikMultipleValue = `${formikValue}s`
  } else {
    _formikDescriptionValue = `${nodeName}Description`
    _formikIdValue = `${nodeName}Id`
    _formikMultipleValue = nodeNamePlural
  }
  return (
    <>
      <Row className="align-items-center" style={{ position: 'relative' }}>
        {dropdown && data && data[nodeNamePlural] && (
          <Col>
            <select
              className="form-control-sm form-select"
              disabled={disabled}
              name={_formikIdValue}
              value={formikValueFromFieldName(_formikIdValue)}
              onChange={(e) => {
                if (e.target.value) {
                  formik.setFieldValue(_formikIdValue, e.target.value)
                  if (setAdditionalFields) {
                    setAdditionalFields(
                      data[nodeNamePlural].edges.filter(
                        (edge) => edge.node.id === e.target.value
                      )[0].node,
                      _formikIdValue
                    )
                  }
                } else {
                  formik.setFieldValue(_formikIdValue, '')
                  if (setAdditionalFields) {
                    setAdditionalFields(null, _formikIdValue)
                  }
                }
              }}
            >
              <option value="">{placeholder ? placeholder : '- - -'}</option>
              {data[nodeNamePlural].edges
                .filter((edge) => !_discludeResults.includes(edge.node.id))
                .map((edge) => (
                  <option key={edge.node.id} value={edge.node.id}>
                    {formatDescription(edge.node)}
                  </option>
                ))}
            </select>
            {error && <small className="text-danger">{error}</small>}
          </Col>
        )}
        {!dropdown && (
          <>
            <Col>
              <Form.Control
                placeholder={
                  placeholder ? placeholder : `Search ${searchDescription}`
                }
                value={formikValueFromFieldName(_formikDescriptionValue)}
                isInvalid={error}
                onBlur={() => {
                  setDisplayResults(false)
                  formik.setFieldTouched(_formikIdValue, true)
                }}
                onChange={(e) => {
                  formik.setFieldValue(_formikDescriptionValue, e.target.value)
                  if (e.target.value) {
                    setDisplayResults(true)
                    query({
                      variables: {
                        ...variables,
                        ...{
                          first: 10,
                          search: e.target.value,
                        },
                      },
                    })
                  } else {
                    setDisplayResults(false)
                  }
                }}
                disabled={disabled}
                readOnly={Boolean(formikValueFromFieldName(_formikIdValue))}
                className={'form-control-sm'}
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </Col>
            {!disabled && formikValueFromFieldName(_formikIdValue) && (
              <Col xs="auto">
                <button
                  type="button"
                  className="p-0 mr-1 btn-link"
                  onClick={() => {
                    formik.setFieldValue(_formikDescriptionValue, '')
                    formik.setFieldValue(_formikIdValue, '')
                  }}
                >
                  <XCircle />
                </button>
              </Col>
            )}
            {create &&
              !formikValueFromFieldName(_formikIdValue) &&
              formikValueFromFieldName(_formikDescriptionValue) &&
              data &&
              data[nodeNamePlural].edges.length === 0 && (
                <Col xs="auto">
                  <button
                    type="button"
                    className="p-0 mr-1 btn-link"
                    onClick={() => {
                      if (multiple) {
                        setDisplayResults(false)
                        formik.setFieldValue(_formikDescriptionValue, '')
                        formik.setFieldValue(
                          _formikMultipleValue,
                          [
                            {
                              description: formikValueFromFieldName(
                                _formikDescriptionValue
                              ),
                              id: null,
                            },
                          ].concat(formik.values[_formikMultipleValue])
                        )
                      } else {
                        setDisplayResults(false)
                        formik.setFieldValue(
                          _formikDescriptionValue,
                          formikValueFromFieldName(_formikDescriptionValue)
                        )
                        formik.setFieldValue(_formikIdValue, null)
                      }
                    }}
                  >
                    <small>Create</small>
                  </button>
                </Col>
              )}
          </>
        )}
      </Row>
      {data && displayResults && data[nodeNamePlural].edges.length > 0 && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: 3000,
            width: '95%',
          }}
        >
          <InfiniteScroll
            height={100}
            dataLength={data[nodeNamePlural].edges.length}
            next={() => {
              fetchMore({
                variables: {
                  ...variables,
                  ...{
                    after: data[nodeNamePlural].pageInfo.cursor,
                    first: 10,
                    search: formik.values[_formikDescriptionValue],
                  },
                },
              })
            }}
            hasMore={data[nodeNamePlural].pageInfo.hasNextPage}
            loader={<Loading />}
          >
            <Table size="sm" hover>
              <tbody>
                {data[nodeNamePlural].edges
                  .filter((edge) => !_discludeResults.includes(edge.node.id))
                  .map((edge) => {
                    const { node } = edge
                    if (
                      !multiple ||
                      !formik.values[_formikMultipleValue].some(
                        (item) => item.id === node.id
                      )
                    ) {
                      return (
                        <tr
                          onMouseDown={() => {
                            if (multiple) {
                              setDisplayResults(false)
                              formik.setFieldValue(_formikDescriptionValue, '')
                              if (setAdditionalFields) {
                                setAdditionalFields(node, _formikMultipleValue)
                              }
                              formik.setFieldValue(
                                _formikMultipleValue,
                                [
                                  {
                                    description: formatDescription(node),
                                    id: node.id,
                                  },
                                ].concat(formik.values[_formikMultipleValue])
                              )
                            } else {
                              setDisplayResults(false)
                              formik.setFieldValue(
                                _formikDescriptionValue,
                                formatDescription(node)
                              )
                              formik.setFieldValue(_formikIdValue, node.id)
                              if (setAdditionalFields) {
                                setAdditionalFields(node, _formikIdValue)
                              }
                            }
                          }}
                          key={node.id}
                          className="hover text-decoration-none"
                        >
                          <td>
                            <small>{formatDescription(node)}</small>
                          </td>
                        </tr>
                      )
                    }
                  })}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
      )}
      {multiple &&
        formikValueFromFieldName(_formikMultipleValue).length > 0 && (
          <div className="mt-2">
            {formikValueFromFieldName(_formikMultipleValue).map((node, i) => {
              return (
                <span
                  className={i > 0 ? 'ml-1' : ''}
                  style={{
                    fontSize: '14px',
                    border: '.3px solid',
                    padding: '5px',
                  }}
                  key={node.id}
                >
                  {!disabled && (
                    <Trash
                      className="mr-2 btn-link"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const updatedNodes = []
                        formik.values[_formikMultipleValue].forEach(
                          (currentNode) => {
                            if (currentNode.id !== node.id) {
                              updatedNodes.push(currentNode)
                            }
                          }
                        )
                        formik.setFieldValue(_formikMultipleValue, updatedNodes)
                      }}
                    />
                  )}
                  {node.description}
                </span>
              )
            })}
          </div>
        )}
    </>
  )
}

export default SearchInput
