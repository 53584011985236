import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Row, Col, Collapse, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { formatDateTimeToString } from '../../libs/utils'
import JobDetailModal from '../schedule/JobDetailModal'
import AdminSessionDetailModal from '../sessions/AdminSessionDetailModal'
import SessionDetailModal from '../subject_sessions/SessionDetailModal'
import NotificationTableSearch from './NotificationTableSearch'
import { Search } from 'react-bootstrap-icons'
import NotificationModal from './NotificationModal'
import { useParams } from 'react-router-dom'
import ContactModal from '../organization/ContactModal'
import NotificationChartModal from './NotificationsChart'

const NotificationsTable = (props) => {
  const {
    tableHeight,
    notificationsLoading,
    setNotificationsLoading,
    subject,
    job,
    gaiaUser,
    session,
    employeeSchedule,
    organization,
    subjectGroup,
    stripeInvoice,
    employee,
  } = props
  const { contact } = useParams()
  const defaultOrderBy = '-created'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [searchText, setSearchText] = useState('Search')
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showNotificationChart, setShowNotificationChart] = useState(false)
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [showNotification, setShowNotification] = useState()
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [filteredUsers, setFilteredUsers] = useState()
  const [componentMounted, setComponentMounted] = useState()
  const [notifications, setNotifications] = useState([])
  const [filteredJobs, setFilteredJobs] = useState()
  const [contactId, setContactId] = useState()
  const [filteredEmployees, setFilteredEmployees] = useState()
  const [filteredOrganizations, setFilteredOrganizations] = useState()
  const [filteredSubjectGroups, setFilteredSubjectGroups] = useState()
  const [filteredSubjects, setFilteredSubjects] = useState()
  const [appliedSearchFilters, setAppliedSearchFilters] = useState({})
  const [jobDetailId, setJobDetailId] = useState()
  const [showJobDetailModal, setShowJobDetailModal] = useState()
  const [showSessionDetailModal, setShowSessionDetailModal] = useState()
  const [showSubjectSessionDetailModal, setShowSubjectSessionDetailModal] =
    useState()
  const [sessionDetailId, setSessionDetailId] = useState()
  const history = useHistory()
  const tableColumns = [
    {
      Header: 'Type',
      accessor: 'notificationTemplate',
      id: 'type',
      serverSort: true,
      orderBy: 'notification_template__name',
    },
    {
      Header: 'To',
      accessor: (row) => {
        if (row.sendToRedirect) {
          const sendToRedirect = row.sendToRedirect.nodeType
          return (
            <div
              className={
                !contact || (contact && sendToRedirect === 'subject')
                  ? 'sessions-buttons btn-link'
                  : ''
              }
              onClick={() => {
                if (!contact && row.sendToRedirect.nodeType === 'employee') {
                  history.push(`/employee/${row.sendToRedirect.nodeId}`)
                } else if (row.sendToRedirect.nodeType === 'subject') {
                  if (contact) {
                    history.push(
                      `/subject/${row.sendToRedirect.nodeId}/contact`
                    )
                  } else {
                    history.push(`/subject/${row.sendToRedirect.nodeId}`)
                  }
                } else if (row.sendToRedirect.nodeType === 'contact') {
                  setContactId(row.sendToRedirect.nodeId)
                }
              }}
            >
              {row.sentTo}
              {!row.sentTo && row.to && <>{row.to}</>}
              {row.sentTo && row.to && <> ({row.to})</>}
            </div>
          )
        }
      },
      id: 'to',
      serverSort: true,
    },
    {
      Header: 'For Subject',
      accessor: (row) => {
        if (row.subject) {
          return (
            <div
              className="sessions-buttons btn-link"
              onClick={() => {
                if (contact) {
                  history.push(`/subject/${row.node.subject.id}/contact`)
                } else {
                  history.push(`/subject/${row.node.subject.id}`)
                }
              }}
            >
              {row.subject}
            </div>
          )
        }
      },
      id: 'subject',
      serverSort: true,
      orderBy: 'subject__gaia_user__full_name',
    },
    {
      Header: 'Job',
      accessor: (row) => {
        if (row.job) {
          return (
            <div
              className={!contact ? 'sessions-buttons btn-link' : ''}
              onClick={() => {
                if (!contact) {
                  setJobDetailId(row.jobId)
                  toggleJobDetailModal()
                }
              }}
            >
              {row.job}
            </div>
          )
        }
      },
      id: 'job',
      serverSort: true,
      orderBy: 'job__name',
    },
    {
      Header: 'Organization',
      accessor: (row) => {
        if (row.organization) {
          return (
            <div
              className="sessions-buttons btn-link"
              onClick={() => {
                if (contact) {
                  history.push(`/organization/${row.organizationId}/contact`)
                } else {
                  history.push(`/organization/${row.organizationId}`)
                }
              }}
            >
              {row.organization}
            </div>
          )
        }
      },
      id: 'organization',
      serverSort: true,
      orderBy: 'organization__name',
    },
    {
      Header: 'Subject Group',
      accessor: (row) => {
        if (row.subjectGroup) {
          return (
            <div
              className="sessions-buttons btn-link"
              onClick={() => {
                if (contact) {
                  history.push(`/subject-group/${row.subjectGroupId}/contact`)
                } else {
                  history.push(`/subject-group/${row.subjectGroupId}`)
                }
              }}
            >
              {row.subjectGroup}
            </div>
          )
        }
      },
      id: 'subject group',
      serverSort: true,
      orderBy: 'subject_group__name',
    },
    {
      Header: 'Session',
      accessor: (row) => {
        if (row.session) {
          return (
            <div
              className="sessions-buttons btn-link"
              onClick={() => {
                setSessionDetailId(row.sessionId)
                toggleSessionDetailModal()
              }}
            >
              {row.session}
            </div>
          )
        }
      },
      id: 'session',
      serverSort: true,
      orderBy: 'session__start_date_time',
    },
    {
      Header: 'Sent',
      accessor: 'on',
      id: 'on',
      serverSort: true,
      orderBy: 'created',
    },
    {
      Header: 'Initially Opened',
      accessor: (row) => {
        let intiallyOpened = ''
        if (row.node.emailIntiallyOpened) {
          intiallyOpened = formatDateTimeToString(
            new Date(row.node.emailIntiallyOpened)
          )
        }
        return intiallyOpened
      },
      id: 'emailIntiallyOpened',
      serverSort: true,
      orderBy: 'email_intially_opened',
    },
    {
      Header: 'Last Opened',
      accessor: (row) => {
        let lastOpened = ''
        if (row.node.emailLastOpened) {
          lastOpened = formatDateTimeToString(
            new Date(row.node.emailLastOpened)
          )
        }
        return lastOpened
      },
      id: 'emailLastOpened',
      serverSort: true,
      orderBy: 'email_last_opened',
    },
    {
      Header: 'Opened',
      accessor: (row) => {
        if (
          typeof row.node.emailOpenCount === 'number' &&
          row.node.emailOpenCount > 0
        ) {
          return `${row.node.emailOpenCount} Times`
        }
      },
      id: 'emailOpenCount',
      serverSort: true,
      orderBy: 'email_open_count',
    },
    {
      Header: 'View',
      id: 'content',
      disableSortBy: true,
      accessor: (row) => {
        if (row.node.content) {
          return (
            <Button
              variant="link"
              onClick={() => {
                setShowNotificationModal(true)
                setShowNotification(row)
              }}
            >
              <Search />
            </Button>
          )
        }
      },
    },
  ]
  if (job) {
    tableColumns.splice(4, 1)
  }
  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query NotificationsQuery(
        $cursor: String
        $subjects: [ID]
        $jobs: [ID]
        $sessions: [ID]
        $employees: [ID]
        $stripeInvoice: [ID]
        $subjectGroups: [ID]
        $organizations: [ID]
        $gaiaUsers: String
        $employeeSchedule: [ID]
        $contact: Boolean
        $startDateGte: DateTime
        $startDateLte: DateTime
        $orderBy: String
      ) {
        notifications(
          orderBy: $orderBy
          subject: $subjects
          job: $jobs
          contact: $contact
          created_Gte: $startDateGte
          created_Lte: $startDateLte
          employee: $employees
          session: $sessions
          employeeSchedule: $employeeSchedule
          stripeInvoice: $stripeInvoice
          subjectGroup: $subjectGroups
          organization: $organizations
          gaiaUser: $gaiaUsers
          first: 40
          after: $cursor
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              job {
                id
                name
              }
              to
              emailIntiallyOpened
              emailLastOpened
              emailOpenCount
              gaiaUser {
                id
                fullName
                email
                dummyUsername
                subject {
                  id
                }
                employee {
                  id
                }
              }
              subjectGroup {
                name
                id
                organization {
                  name
                  id
                }
              }
              channel
              job {
                name
                id
              }
              adHocContact {
                firstName
                lastName
              }
              organization {
                name
                id
              }
              session {
                id
                startDateTime
                endDateTime
              }
              created
              subject {
                id
                gaiaUser {
                  fullName
                  email
                  dummyUsername
                }
              }
              employee {
                id
                gaiaUser {
                  fullName
                }
              }
              content
              notificationTemplate {
                name
              }
              smtpConnector {
                id
              }
              twilioConnector {
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: 5000,
      onCompleted: () => {
        if (notificationsLoading) {
          setNotificationsLoading(false)
        }
      },
    }
  )

  const closeNotificationModal = () => {
    setShowNotification()
    setShowNotificationModal(false)
  }
  useEffect(() => {
    if (queryData?.notifications) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      let text = 'Search Notifications'
      setSearchText(text)
      const currentNotifications = queryData.notifications.edges.map(
        (notification) => {
          const notificationNode = notification.node
          let subjectGroupId
          let subjectGroup
          let organization
          let organizationId
          if (notificationNode.subjectGroup) {
            subjectGroupId = notificationNode.subjectGroup.id
            subjectGroup = notificationNode.subjectGroup.name
            organization = notificationNode.subjectGroup?.organization?.name
            organizationId = notificationNode.subjectGroup?.organization?.id
          }
          let jobId
          let job
          if (notificationNode.job) {
            jobId = notificationNode.job.id
            job = notificationNode.job.name
          }
          let sentTo
          let sendToRedirect
          const gaiaUserNode = notificationNode.gaiaUser
          if (gaiaUserNode?.employee) {
            sentTo = gaiaUserNode?.fullName
            sendToRedirect = {
              nodeType: 'employee',
              nodeId: gaiaUserNode?.employee?.id,
            }
          } else if (gaiaUserNode?.subject) {
            if (gaiaUserNode?.fullName) {
              sentTo = gaiaUserNode?.fullName
            }
            sendToRedirect = {
              nodeType: 'subject',
              nodeId: gaiaUserNode?.subject?.id,
            }
          } else if (notificationNode.adHocContact) {
            sentTo = `${notificationNode.adHocContact.firstName} ${notificationNode.adHocContact.lastName}`
          } else if (gaiaUserNode) {
            sendToRedirect = {
              nodeType: 'contact',
              nodeId: gaiaUserNode.id,
            }
            sentTo = gaiaUserNode?.fullName
          } else {
            sentTo = 'Deleted User'
          }
          let sessionId
          if (notificationNode?.session) {
            sessionId = notificationNode.session.id
          }
          let subject
          if (notificationNode?.subject) {
            subject = notificationNode.subject?.gaiaUser?.fullName
          }
          return {
            notificationTemplate: notificationNode.notificationTemplate.name,
            on: formatDateTimeToString(new Date(notificationNode.created)),
            session: formatSessionOn(notificationNode.session),
            sentTo,
            sessionId,
            sendToRedirect,
            organization,
            organizationId,
            subjectGroup,
            subject,
            subjectGroupId,
            jobId,
            job,
            to: notificationNode.to,
            channel: notificationNode.channel,
            content: notificationNode.content,
            node: notificationNode,
          }
        }
      )
      setNotifications(currentNotifications)
    }
  }, [queryData])

  useEffect(() => {
    if (componentMounted) {
      const currentFilteredJobs = []
      const currentFilteredEmployees = []
      const currentFilteredSubjectGroups = []
      const currentFilteredOrganizations = []
      const currentFilteredSubjects = []
      const currentFilteredUsers = []
      Object.entries(appliedSearchFilters).forEach((appliedSearchFilter) => {
        const appliedSearchFilterEntries = appliedSearchFilter[1]
        if (appliedSearchFilterEntries.nodeType === 'Job') {
          currentFilteredJobs.push(appliedSearchFilterEntries.id)
        } else if (appliedSearchFilterEntries.nodeType === 'Employee') {
          currentFilteredEmployees.push(appliedSearchFilterEntries.id)
        } else if (appliedSearchFilterEntries.nodeType === 'SubjectGroup') {
          currentFilteredSubjectGroups.push(appliedSearchFilterEntries.id)
        } else if (appliedSearchFilterEntries.nodeType === 'Organization') {
          currentFilteredOrganizations.push(appliedSearchFilterEntries.id)
        } else if (appliedSearchFilterEntries.nodeType === 'Subject') {
          currentFilteredSubjects.push(appliedSearchFilterEntries.id)
        }
      })
      if (subject) {
        currentFilteredUsers.push(subject.gaiaUser.id)
      }
      if (job) {
        currentFilteredJobs.push(job.id)
      }
      if (organization) {
        currentFilteredOrganizations.push(organization.id)
      }
      if (subjectGroup) {
        currentFilteredSubjectGroups.push(subjectGroup.id)
      }
      if (employee) {
        currentFilteredEmployees.push(employee.id)
      }
      if (gaiaUser) {
        currentFilteredUsers.push(gaiaUser.id)
      }
      let variables = {
        orderBy: defaultOrderBy,
      }
      if (currentFilteredJobs.length) {
        variables.jobs = currentFilteredJobs
      }
      if (contact) {
        variables.contact = true
      }
      if (employeeSchedule) {
        variables.employeeSchedule = employeeSchedule.id
      }
      if (session) {
        variables.sessions = [session.id]
      }
      if (stripeInvoice) {
        variables.stripeInvoice = [stripeInvoice]
      }
      if (currentFilteredEmployees.length) {
        variables.employees = currentFilteredEmployees
      }
      if (currentFilteredSubjectGroups.length) {
        variables.subjectGroups = currentFilteredSubjectGroups
      }
      if (currentFilteredOrganizations.length) {
        variables.organizations = currentFilteredOrganizations
      }
      if (currentFilteredSubjects.length) {
        variables.subjects = currentFilteredSubjects
      }
      if (currentFilteredUsers.length) {
        variables.gaiaUsers = currentFilteredUsers.join(',')
      }
      if (startDateFilter) {
        variables.startDateGte = startDateFilter
      }
      if (endDateFilter) {
        variables.startDateLte = endDateFilter
      }
      setLoadingSearch(true)
      query({ variables })
      setFilteredJobs(currentFilteredJobs)
      setFilteredEmployees(currentFilteredEmployees)
      setFilteredSubjectGroups(currentFilteredSubjectGroups)
      setFilteredOrganizations(currentFilteredOrganizations)
      setFilteredSubjects(currentFilteredSubjects)
      setFilteredUsers(currentFilteredUsers)
    }
  }, [appliedSearchFilters, componentMounted, startDateFilter, endDateFilter])

  const formatSessionOn = (session) => {
    let sessionOn
    if (session) {
      const startDateTime = new Date(session.startDateTime)
      const endDateTime = new Date(session.endDateTime)
      const date = startDateTime.toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
      })
      const startTime = startDateTime.toLocaleTimeString()
      const endTime = endDateTime.toLocaleTimeString()
      sessionOn = `${date} ${startTime} - ${endTime}`
    }
    return sessionOn
  }
  const fetchMoreNotifications = () => {
    let variables = {}
    if (filteredJobs.length) {
      variables.jobs = filteredJobs
    }
    if (filteredEmployees.length) {
      variables.employees = filteredEmployees
    }
    if (session) {
      variables.sessions = [session.id]
    }
    if (filteredSubjectGroups.length) {
      variables.subjectGroups = filteredSubjectGroups
    }
    if (filteredOrganizations.length) {
      variables.organizations = filteredOrganizations
    }
    if (filteredSubjects.length) {
      variables.subjects = filteredSubjects
    }
    if (filteredUsers.length) {
      variables.gaiaUsers = filteredUsers.join(',')
    }
    if (stripeInvoice) {
      variables.stripeInvoice = [stripeInvoice]
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    variables.cursor = queryData.notifications.pageInfo.endCursor
    queryFetchMore({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (filteredJobs.length) {
      variables.jobs = filteredJobs
    }
    if (filteredEmployees.length) {
      variables.employees = filteredEmployees
    }
    if (session) {
      variables.sessions = [session.id]
    }
    if (filteredSubjectGroups.length) {
      variables.subjectGroups = filteredSubjectGroups
    }
    if (filteredOrganizations.length) {
      variables.organizations = filteredOrganizations
    }
    if (filteredSubjects.length) {
      variables.subjects = filteredSubjects
    }
    if (filteredUsers.length) {
      variables.gaiaUsers = filteredUsers.join(',')
    }
    if (stripeInvoice) {
      variables.stripeInvoice = [stripeInvoice]
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }

    query({ variables })
  }

  const toggleJobDetailModal = () => {
    if (showJobDetailModal) {
      setJobDetailId(null)
    }
    setShowJobDetailModal(!showJobDetailModal)
  }

  const toggleSessionDetailModal = () => {
    if (showSessionDetailModal) {
      setSessionDetailId(null)
    }
    if (contact) {
      setShowSubjectSessionDetailModal(!showSubjectSessionDetailModal)
    } else {
      setShowSessionDetailModal(!showSessionDetailModal)
    }
  }

  useEffect(() => {
    if (!componentMounted) {
      const currentFilteredJobs = []
      const currentFilteredEmployees = []
      const currentFilteredSubjectGroups = []
      const currentFilteredOrganizations = []
      const currentFilteredSubjects = []
      const currentFilteredUsers = []
      if (subject) {
        currentFilteredUsers.push(subject.gaiaUser.id)
      }
      if (gaiaUser) {
        currentFilteredUsers.push(gaiaUser.id)
      }
      if (organization) {
        currentFilteredOrganizations.push(organization.id)
      }
      if (subjectGroup) {
        currentFilteredSubjectGroups.push(subjectGroup.id)
      }
      if (employee) {
        currentFilteredEmployees.push(employee.id)
      }
      if (job) {
        currentFilteredJobs.push(job.id)
      }
      let variables = {
        orderBy: defaultOrderBy,
      }
      if (currentFilteredJobs.length) {
        variables.jobs = currentFilteredJobs
      }
      if (currentFilteredEmployees.length) {
        variables.employees = currentFilteredEmployees
      }
      if (session) {
        variables.session = [session.id]
      }
      if (stripeInvoice) {
        variables.stripeInvoice = [stripeInvoice]
      }
      if (currentFilteredSubjectGroups.length) {
        variables.subjectGroups = currentFilteredSubjectGroups
      }
      if (currentFilteredOrganizations.length) {
        variables.organizations = currentFilteredOrganizations
      }
      if (currentFilteredUsers.length) {
        variables.gaiaUsers = currentFilteredUsers.join(',')
      }
      query(variables)
      setFilteredJobs(currentFilteredJobs)
      setFilteredEmployees(currentFilteredEmployees)
      setFilteredSubjectGroups(currentFilteredSubjectGroups)
      setFilteredOrganizations(currentFilteredOrganizations)
      setFilteredSubjects(currentFilteredSubjects)
      setFilteredUsers(currentFilteredUsers)
      setComponentMounted(true)
    }
  }, [componentMounted])

  if (!componentMounted) return <></>
  if (queryError) return <>Error loading notifications</>
  return (
    <>
      <div className="notifications mt-3">
        <Collapse in={true}>
          <div id="notificationsCollapse">
            <div className="mb-3">
              <NotificationTableSearch
                searchText={searchText}
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
                appliedSearchFilters={appliedSearchFilters}
                setAppliedSearchFilters={setAppliedSearchFilters}
                setShowNotificationChart={setShowNotificationChart}
              />
            </div>
            <Row className="mb-3">
              <Col>
                {notifications && (
                  <SortableInfiniteTable
                    loading={loadingSearch || !notifications}
                    tableData={notifications}
                    tableColumns={tableColumns}
                    fetchMoreTableData={fetchMoreNotifications}
                    hasMoreTableData={
                      queryData?.notifications?.pageInfo?.hasNextPage
                    }
                    tableHeight={tableHeight ? tableHeight : 400}
                    rowPointer
                    hideGlobalFilter
                    loadingMessage={'Loading Notifications...'}
                    handleSortByChange={handleSortByChange}
                  />
                )}
              </Col>
            </Row>
          </div>
        </Collapse>
        {jobDetailId && (
          <JobDetailModal
            jobId={jobDetailId}
            toggleModal={toggleJobDetailModal}
            showModal={showJobDetailModal}
          />
        )}

        {showSessionDetailModal && (
          <AdminSessionDetailModal
            sessionId={sessionDetailId}
            showModal={showSessionDetailModal}
            toggleModal={toggleSessionDetailModal}
          />
        )}

        {showNotification && (
          <NotificationModal
            notification={showNotification}
            showModal={showNotificationModal}
            toggleModal={closeNotificationModal}
          />
        )}
        {contactId && (
          <ContactModal
            contactId={contactId}
            showModal={true}
            toggleModal={setContactId}
          />
        )}

        {showSubjectSessionDetailModal && (
          <SessionDetailModal
            showModal={showSubjectSessionDetailModal}
            toggleModal={toggleSessionDetailModal}
            sessionId={sessionDetailId}
          />
        )}
        {showNotificationChart && (
          <NotificationChartModal
            showModal={true}
            toggleModal={setShowNotificationChart}
            jobId={job?.id}
            employeeId={employee?.id}
            subjectId={subject?.id}
            organizationId={organization?.id}
            subjectGroupId={subjectGroup?.id}
            contactId={gaiaUser?.id}
            sessionId={session?.id}
            employeeScheduleId={employeeSchedule?.id}
            stripeInvoice={stripeInvoice?.id}
          />
        )}
      </div>
    </>
  )
}

export default NotificationsTable
