import React, { useState, useEffect } from 'react'
import {
  useLazyQuery,
  gql,
  useReactiveVar,
  useMutation,
  useQuery,
} from '@apollo/client'
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { loggedInUserVar } from '../../libs/apollo'
import {
  People,
  Trash,
  BarChartLine,
  CloudArrowDown,
  ArrowRepeat,
  PlusCircle,
} from 'react-bootstrap-icons'
import Page from '../layout/Page'
import SubjectGroupModal from './SubjectGroupModal'
import DeleteSubjectGroupModal from './DeleteSubjectGroupModal'
import toast from 'react-hot-toast'
import DateFilter from '../common/DateFilter'
import {
  fotomerchantClientSessionAdminUrl,
  useFotomerchantEnabled,
} from '../../libs/fotomerchant'
import { useParams } from 'react-router-dom'
import CopyTriggerModal from '../notifications/CopyTriggerModal'
import StatusCircle from '../common/StatusCircle'
import Loading from '../common/Loading'
import { useDownloadFile } from '../../libs/downloadFile'
import SubjectGroupReportModal from './SubjectGroupReportModal'
import PieChart from '../chart/PieChart'

const SubjectGroups = (props) => {
  const { contact } = useParams()
  const { organization } = props
  const [hasMoreSubjectGroups, setHasMoreSubjectGroups] = useState(true)
  const [cursor, setCursor] = useState()
  const [searchText, setSearchText] = useState()
  const [subjectGroups, setSubjectGroups] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [subjectGroupReport, setSubjectGroupReport] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [checkedSubjectGroupIds, setCheckedSubjectGroupIds] = useState([])
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const [startDateFilter, setStartDateFilter] = useState()
  const [endDateFilter, setEndDateFilter] = useState()
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showSyncNotificationsModal, setShowSyncNotificationsModal] =
    useState(false)
  const canModify = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const history = useHistory()
  const fotomerchantEnabled = useFotomerchantEnabled()
  const { downloadAndDeleteFile } = useDownloadFile()

  const [downloadSubjects] = useMutation(
    gql`
      mutation DownloadSubjectGroups($input: DownloadSubjectGroupsInput!) {
        downloadSubjectGroups(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadSubjectGroups.file.fileName,
          data.downloadSubjectGroups.file.displayName,
          data.downloadSubjectGroups.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'subjectGroup',
      serverSort: true,
    },
    {
      Header: 'Category',
      id: 'category',
      accessor: 'category',
      serverSort: true,
    },
    {
      Header: 'Organization',
      id: 'organization',
      accessor: 'organization',
      serverSort: true,
      orderBy: 'organization__name',
    },
    {
      Header: 'Bookable Between',
      id: 'bookingStartDate',
      accessor: (row) => {
        return `${row.bookingStartDate} - ${row.bookingEndDate}`
      },
      serverSort: true,
      orderBy: 'start_date_time',
    },
    {
      Header: 'Subjects',
      id: 'subjectCount',
      accessor: 'subjectCount',
    },
    {
      Header: 'Booked',
      id: 'report',
      accessor: (row) => {
        return (
          <PieChart
            dataLabelStyle={{
              fontSize: '13px',
              colors: ['#000'],
              fontWeight: '1',
            }}
            width={'100'}
            hideLedgend
            colors={['#FF9999', '#FFCC66', '#99CC99']}
            labels={[
              'Subjects Not Booked',
              'Subjects Booked',
              'Subjects Photographed',
            ]}
            series={[
              row.node.subjectsNotBookedCount,
              row.node.subjectsBookedCount,
              row.node.subjectsSessionCompleteCount,
            ]}
          />
        )
      },
    },
  ]
  if (fotomerchantEnabled && !contact) {
    tableColumns.push({
      Header: 'Fotomerchant',
      id: 'fotomerchantSubject',
      accessor: (row) => {
        if (row.node.fotomerchantClientSessions.edges.length > 0) {
          const fmClientSession =
            row.node.fotomerchantClientSessions.edges[0].node
          return (
            <>
              <Button variant="link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={fotomerchantClientSessionAdminUrl(
                    fmClientSession.fotomerchantClient.fotomerchantId,
                    fmClientSession.fotomerchantId
                  )}
                >
                  <img
                    className="mr-2"
                    src={window.location.origin + '/fotomerchant.svg'}
                    style={{ height: '20px' }}
                    alt="Organization logo"
                  />
                  <span style={{ fontSize: '14px' }}>Client Session</span>
                </a>
              </Button>
            </>
          )
        }
      },
    })
  }

  if (canModify) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  subjectGroups.forEach((sg) => {
                    if (!checkedSubjectGroupIds.includes(sg.node.id)) {
                      appendIds.push(sg.node.id)
                    }
                  })
                  setCheckedSubjectGroupIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedSubjectGroupIds([])
                }
              }}
            />
            {checkedSubjectGroupIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedSubjectGroupIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedSubjectGroupIds.includes(row.node.id)}
                onChange={(e) => handleCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedSubjectGroupIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedSubjectGroupIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  const { data: settingsData } = useQuery(
    gql`
      query SubjectGroupsSettingsQuery {
        settings {
          edges {
            node {
              id
              syncingSubjectGroupNotifications
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: 2000,
    }
  )

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query SubjectGroupsQuery(
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $startDateTimeGte: DateTime
        $startDateTimeLte: DateTime
        $organization: ID
      ) {
        subjectGroups(
          first: 20
          after: $cursor
          orderBy: $orderBy
          search_Icontains: $searchTerm
          startDateTime_Gte: $startDateTimeGte
          startDateTime_Lte: $startDateTimeLte
          organization: $organization
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              name
              category
              organization {
                name
              }
              subjectsBookedCount
              subjectsSessionCompleteCount
              subjectsNotBookedCount
              fotomerchantClientSessions {
                edges {
                  node {
                    fotomerchantId
                    fotomerchantClient {
                      fotomerchantId
                    }
                  }
                }
              }
              sessionPackages {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
              startDateTime
              endDateTime
              lastDateToPickPose
              lastDateForRetouch
              lastDateToBePhotographed
              subjects {
                nodeCount
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      pollInterval: 10000,
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = {
        orderBy: defaultOrderBy,
      }
      if (organization) {
        variables.organization = organization.id
      }
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.subjectGroups) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      if (queryData.subjectGroups.pageInfo.endCursor) {
        setCursor(queryData.subjectGroups.pageInfo.endCursor)
      }
      setHasMoreSubjectGroups(queryData.subjectGroups.pageInfo.hasNextPage)
      const currentSubjectGroups = queryData.subjectGroups.edges.map(
        (subjectGroup) => {
          const subjectGroupNode = subjectGroup.node
          return {
            node: subjectGroupNode,
            category: subjectGroupNode.category,
            subjectGroup: subjectGroupNode.name,
            subjectsNotBooked: subjectGroupNode.subjectsNotBookedCount,
            subjectsBooked: subjectGroupNode.subjectsBookedCount,
            subjectsSessionComplete:
              subjectGroupNode.subjectsSessionCompleteCount,
            organization: subjectGroupNode.organization?.name,
            subjectCount: subjectGroupNode.subjects?.nodeCount,
            bookingStartDate: DateTime.fromISO(
              subjectGroupNode.startDateTime
            ).toFormat('cccc, MMMM dd, yyyy'),
            bookingEndDate: DateTime.fromISO(
              subjectGroupNode.endDateTime
            ).toFormat('cccc, MMMM dd, yyyy'),
            id: subjectGroupNode.id,
          }
        }
      )
      let text = 'Search 0 Subject Groups'
      if (queryData.subjectGroups.nodeCount > 0) {
        text = `Search ${queryData.subjectGroups.nodeCount} Subject Groups`
      }
      setSearchText(text)
      setSubjectGroups(currentSubjectGroups)
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    if (organization) {
      variables.organization = organization.id
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
    })
  }

  useEffect(() => {
    if (initialQueryRun) {
      setCursor(null)
      setLoadingSearch(true)
      const variables = {
        cursor: null,
        searchTerm,
        startDateTimeGte: startDateFilter,
        startDateTimeLte: endDateFilter,
      }
      if (organization) {
        variables.organization = organization.id
      }
      query({
        variables,
      })
    }
  }, [startDateFilter, endDateFilter])

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setCursor(null)
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    setLoadingSearch(true)
    if (orderBy) {
      variables.orderBy = orderBy
    }
    query({ variables })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    if (organization) {
      variables.organization = organization.id
    }
    query({ variables })
  }

  const openSubjectGroupDetail = (cell) => {
    if (contact) {
      history.push(`/subject-group/${cell.row.original.id}/contact`)
    } else {
      history.push(`/subject-group/${cell.row.original.id}`)
    }
  }

  if (queryError) return <>Error loading subjects groups</>

  const titleComponent = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!organization && (
        <h1 className="mb-0" style={{ marginRight: '10px' }}>
          Subject Groups
        </h1>
      )}
      {settingsData?.settings.edges[0].node
        .syncingSubjectGroupNotifications && (
        <span style={{ marginTop: '5px' }}>
          <StatusCircle message="Syncing Notifications" />
        </span>
      )}
    </div>
  )
  return (
    <>
      <SubjectGroupReportModal
        showModal={subjectGroupReport}
        toggleModal={setSubjectGroupReport}
        subjectGroup={subjectGroupReport}
      />
      <CopyTriggerModal
        showModal={showSyncNotificationsModal}
        toggleModal={() => {
          setShowSyncNotificationsModal(false)
        }}
        postSubmit={() => {
          setCheckedSubjectGroupIds([])
        }}
        all={true}
        subjectGroupIds={checkedSubjectGroupIds}
      />
      <Page titleComponent={titleComponent}>
        <Row className="mb-3" style={{ marginTop: '-10px' }}>
          <Col>
            {canModify && (
              <>
                <Button variant="link" onClick={() => setShowModal(true)}>
                  <PlusCircle className="mr-2" />
                  New Subject Group
                </Button>
                <Button
                  variant="link"
                  disabled={downloadingPdf}
                  onClick={() => {
                    setDownloadingPdf(true)
                    downloadSubjects({
                      variables: {
                        input: {
                          subjectGroupIds: checkedSubjectGroupIds,
                          fileType: 'pdf',
                        },
                      },
                    })
                  }}
                >
                  {downloadingPdf && <Loading />}
                  {!downloadingPdf && !downloadingExcel && (
                    <>
                      <CloudArrowDown className="mr-2" />
                      Download PDF
                    </>
                  )}
                </Button>
                <Button
                  variant="link"
                  disabled={downloadingExcel}
                  onClick={() => {
                    setDownloadingExcel(true)
                    downloadSubjects({
                      variables: {
                        input: {
                          subjectGroupIds: checkedSubjectGroupIds,
                          fileType: 'xlsx',
                        },
                      },
                    })
                  }}
                >
                  {downloadingExcel && <Loading />}
                  {!downloadingPdf && !downloadingExcel && (
                    <>
                      <CloudArrowDown className="mr-2" />
                      Download Excel
                    </>
                  )}
                </Button>
                {checkedSubjectGroupIds.length > 0 && (
                  <>
                    <Button
                      variant="link"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      <Trash className="mr-2" />
                      {checkedSubjectGroupIds.length === 1
                        ? 'Delete Subject Group'
                        : 'Delete Subject Groups'}
                    </Button>
                    <Button
                      variant="link"
                      onClick={setShowSyncNotificationsModal}
                    >
                      <ArrowRepeat className="mr-2" />
                      Sync Notifications
                    </Button>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                size="sm"
                type="text"
                name="searchTerm"
                placeholder={searchText}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          <Col style={{ marginTop: '-8px' }}>
            <DateFilter
              startDateFilter={startDateFilter}
              setStartDateFilter={setStartDateFilter}
              endDateFilter={endDateFilter}
              setEndDateFilter={setEndDateFilter}
              placeholderStart="Booking Start From"
              placeholderEnd="Booking End Until"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12} md={12}>
            <SortableInfiniteTable
              tableData={subjectGroups}
              tableColumns={tableColumns}
              fetchMoreTableData={fetchMore}
              loading={loadingSearch}
              hasMoreTableData={hasMoreSubjectGroups}
              loadingMessage="Loading Subject Groups..."
              tableHeight={800}
              onTdClicks={{
                name: (row) => openSubjectGroupDetail(row),
                category: (row) => openSubjectGroupDetail(row),
                organization: (row) => openSubjectGroupDetail(row),
                bookingStartDate: (row) => openSubjectGroupDetail(row),
                bookingEndDate: (row) => openSubjectGroupDetail(row),
                subjectCount: (row) => openSubjectGroupDetail(row),
                sessionPackages: (row) => openSubjectGroupDetail(row),
              }}
              rowPointer
              hideGlobalFilter
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>
      </Page>
      <SubjectGroupModal
        showModal={showModal}
        toggleModal={setShowModal}
        organization={organization}
      />
      <DeleteSubjectGroupModal
        subjectGroupIds={
          checkedSubjectGroupIds.length > 1 ? checkedSubjectGroupIds : null
        }
        subjectGroupId={
          checkedSubjectGroupIds.length === 1 ? checkedSubjectGroupIds[0] : null
        }
        setCheckedSubjectGroupIds={setCheckedSubjectGroupIds}
        showModal={showDeleteModal}
        toggleModal={setShowDeleteModal}
      />
    </>
  )
}
export default SubjectGroups
