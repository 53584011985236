import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { Formik, FieldArray } from 'formik'
import * as Yup from 'yup'
import { gql, useMutation, useQuery } from '@apollo/client'
import { PlusCircle, Trash } from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import Loading from '../../common/Loading'

const GET_SERVICE_ITEM_GROUPS = gql`
  query GetServiceItemGroups {
    defaultServiceItemGroups(orderBy: "name") {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const UPDATE_ORGANIZATION_COLLECTIONS = gql`
  mutation UpdateOrganizationCollections(
    $updateOrganizationInput: UpdateOrganizationInput!
  ) {
    updateOrganization(input: $updateOrganizationInput) {
      organization {
        id
      }
    }
  }
`

const AddCollectionsModal = ({ show, handleClose, organizationId }) => {
  const { data, loading, error } = useQuery(GET_SERVICE_ITEM_GROUPS, {
    fetchPolicy: 'network-only',
  })

  const [updateOrganization, { loading: updating }] = useMutation(
    UPDATE_ORGANIZATION_COLLECTIONS,
    {
      onCompleted: () => {
        toast.success('Tasks Added')
        handleClose()
      },
      onError: (err) => {
        console.error(err)
        toast.error('An Error Occurred')
      },
      refetchQueries: ['OrganizationDetail', 'Tasks'],
    }
  )

  if (loading) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Loading />
        </Modal.Body>
      </Modal>
    )
  }

  if (error) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Error loading collections</Modal.Body>
      </Modal>
    )
  }

  const serviceItemGroups = data.defaultServiceItemGroups.edges.map(
    (edge) => edge.node
  )

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Tasks From Collection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            selectedCollections: [],
          }}
          validationSchema={Yup.object({
            selectedCollections: Yup.array().min(
              1,
              'Please select at least one collection'
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            updateOrganization({
              variables: {
                updateOrganizationInput: {
                  organizationInput: {
                    id: organizationId,
                    defaultServiceItemGroupIds: values.selectedCollections,
                  },
                },
              },
            })
              .then(() => {
                setSubmitting(false)
              })
              .catch(() => {
                setSubmitting(false)
              })
          }}
        >
          {({ values, handleSubmit, errors, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FieldArray
                name="selectedCollections"
                render={(arrayHelpers) => (
                  <>
                    <Form.Group>
                      <Form.Label>Select Collections</Form.Label>
                      <Form.Control
                        as="select"
                        className="mb-3"
                        onChange={(e) => {
                          const selectedId = e.target.value
                          if (
                            selectedId &&
                            !values.selectedCollections.includes(selectedId)
                          ) {
                            arrayHelpers.push(selectedId)
                          }
                        }}
                        value=""
                      >
                        <option value="">---</option>
                        {serviceItemGroups.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <div>
                      {values.selectedCollections.map((id, index) => {
                        const collection = serviceItemGroups.find(
                          (group) => group.id === id
                        )
                        return (
                          <div
                            key={id}
                            className="d-flex align-items-center  p-2 mb-2"
                            style={{
                              border: '1px solid #ddd',
                              borderRadius: '4px',
                            }}
                          >
                            <Button
                              variant="link"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <Trash />
                            </Button>

                            <span>{collection?.name}</span>
                          </div>
                        )
                      })}
                    </div>

                    {errors.selectedCollections && (
                      <div className="text-danger">
                        {errors.selectedCollections}
                      </div>
                    )}

                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        type="submit"
                        variant="outline-primary"
                        block
                        disabled={isSubmitting || updating}
                      >
                        <PlusCircle className="mr-2" /> Save
                      </Button>
                    </div>
                    {isSubmitting && <Loading />}
                  </>
                )}
              />
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddCollectionsModal
