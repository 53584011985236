import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useMutation, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup, Table } from 'react-bootstrap'
import {
  CloudArrowDown,
  Dot,
  Funnel,
  PlusCircle,
  Trash,
} from 'react-bootstrap-icons'
import { DateTime } from 'luxon'
import Loading from '../../common/Loading'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import JobDetailModal from '../../schedule/JobDetailModal'
import DateFilter from '../../common/DateFilter'
import toast from 'react-hot-toast'
import { useDownloadFile } from '../../../libs/downloadFile'
import DeleteTimesheetModal from './DeleteTimesheetModal'
import { loggedInUserVar } from '../../../libs/apollo'
import TimesheetModal from './TimesheetModal'

const Timesheet = () => {
  const { downloadAndDeleteFile } = useDownloadFile()
  const now = new Date()
  let initialStartDateFilter = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
  )

  let initialEndDateFilter = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 7, 23, 59, 59)
  )

  if (
    initialEndDateFilter &&
    initialEndDateFilter.getMonth() !== now.getMonth()
  ) {
    initialEndDateFilter = new Date(
      Date.UTC(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59)
    )
  }

  const dropDownOptions = [
    {
      key: 'All',
      value: 'all',
    },
    {
      key: 'Employee',
      value: 'employee',
    },
    {
      key: 'Employee Job Assignment',
      value: 'employeeJob',
    },
    {
      key: 'Employee Work Event',
      value: 'employeeSchedule',
    },
  ]

  const getUtcDt = (jobDateDt) => {
    return DateTime.utc(
      jobDateDt.getFullYear(),
      jobDateDt.getMonth() + 1,
      jobDateDt.getDate(),
      jobDateDt.hour,
      jobDateDt.minute
    )
  }
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canModify = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [searchText, setSearchText] = useState()
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(true)
  const [showJobModal, setShowJobModal] = useState()
  const [checkedNodeIds, setCheckedNodeIds] = useState([])
  const [startDateFilter, setStartDateFilter] = useState(
    getUtcDt(initialStartDateFilter)
  )
  const [endDateFilter, setEndDateFilter] = useState(
    getUtcDt(initialEndDateFilter)
  )
  const [downloading, setDownloading] = useState(false)
  const [showDeleteForm, setShowDeleteForm] = useState(false)
  const [showTimesheetModal, setShowTimesheetModal] = useState(false)
  const [seletedTimesheet, setSeletedTimesheet] = useState()
  const [seletedAssociateTimesheet, setSeletedAssociateTimesheet] =
    useState('all')

  const toggleNewTimesheetModal = () => {
    setShowTimesheetModal((prevState) => !prevState)
  }

  const [downloadTimesheet] = useMutation(
    gql`
      mutation DownloadTimesheet($input: DownloadTimesheetInput!) {
        downloadTimesheet(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadTimesheet.file.fileName,
          data.downloadTimesheet.file.displayName,
          data.downloadTimesheet.file.id,
          () => {
            toast.success('Downloaded Timesheet')
            setDownloading(false)
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query TimesheetsQuery(
        $cursor: String
        $searchTerm: String
        $associated: String
        $startDateGte: String
        $startDateLte: String
      ) {
        timesheets(
          first: 10
          after: $cursor
          search: $searchTerm
          associated: $associated
          startDateGte: $startDateGte
          startDateLte: $startDateLte
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              minutesClocked
              accruedPay
              hourlyPay
              startDateTime
              endDateTime
              employee {
                id
                gaiaUser {
                  fullName
                }
              }
              employeeJob {
                id
                startDateTime
                endDateTime
                employee {
                  gaiaUser {
                    fullName
                  }
                }
                job {
                  id
                  startDateTime
                  name
                  location {
                    fullAddress
                  }
                }
                role {
                  name
                }
              }
              employeeSchedule {
                id
                startDateTime
                endDateTime
                employee {
                  gaiaUser {
                    fullName
                  }
                }
                location {
                  fullAddress
                }
              }
              timesheetEvents(orderBy: "created") {
                edges {
                  node {
                    id
                    dateTime
                    eventType
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 5000,
    }
  )
  const queryVariables = () => {
    const variables = {
      cursor: queryData?.timesheets?.pageInfo?.endCursor,
      associated: seletedAssociateTimesheet,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (startDateFilter) {
      variables.startDateGte = startDateFilter
    }
    if (endDateFilter) {
      variables.startDateLte = endDateFilter
    }
    return variables
  }

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query()
      query({ variables: queryVariables() })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.timesheets) {
      setLoading(false)
      let text = 'Search 0 Timesheets'
      if (queryData.timesheets.nodeCount > 0) {
        text = `Search ${queryData.timesheets.nodeCount} Timesheets`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    queryFetchMore({
      variables: queryVariables(),
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoading(true)
    const variables = queryVariables()
    variables.cursor = null
    if (currentSearchTerm) {
      variables.searchTerm = currentSearchTerm
    } else {
      variables.searchTerm = null
    }
    query({ variables })
  }

  const handleNodeCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedNodeIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedNodeIds((prevState) =>
        prevState.filter((id) => id !== row.node.id)
      )
    }
  }

  useEffect(() => {
    if (initialQueryRun) {
      const variables = queryVariables()
      variables.cursor = null
      setLoading(true)
      query({
        variables,
      })
    }
  }, [startDateFilter, endDateFilter, seletedAssociateTimesheet])

  const tableColumns = [
    {
      Header: 'Employee',
      id: 'employee',
      accessor: (row) => {
        let employee
        if (row.node.employeeSchedule !== null) {
          employee = row.node.employeeSchedule.employee
        } else if (row.node.employeeJob !== null) {
          employee = row.node.employeeJob.employee
        } else if (row.node.employee !== null) {
          employee = row.node.employee
        }
        if (employee) {
          return employee.gaiaUser.fullName
        } else {
          return 'Unassigned'
        }
      },
    },
    {
      Header: 'Shift',
      id: 'shift',
      accessor: (row) => {
        let startDateTime, endDateTime, date
        if (row.node.employeeSchedule !== null) {
          startDateTime = row.node.employeeSchedule.startDateTime
          endDateTime = row.node.employeeSchedule.endDateTime
          date = DateTime.fromISO(startDateTime)
        } else if (row.node.employeeJob !== null) {
          startDateTime = row.node.employeeJob.startDateTime
          endDateTime = row.node.employeeJob.endDateTime
          date = DateTime.fromISO(row.node.employeeJob?.job?.startDateTime)
        } else if (row.node.employee !== null) {
          startDateTime = row.node.startDateTime
          endDateTime = row.node.endDateTime
          date = DateTime.fromISO(row.node.startDateTime)
        }
        startDateTime = startDateTime
          ? DateTime.fromISO(startDateTime).toFormat('h:mma')
          : ''
        endDateTime = endDateTime
          ? DateTime.fromISO(endDateTime).toFormat('h:mma')
          : ''
        let shift
        if (startDateTime) {
          shift = `${startDateTime}`
        }
        if (endDateTime) {
          if (shift) {
            shift = `${shift} - ${endDateTime}`
          } else {
            shift = `End ${endDateTime}`
          }
        } else if (shift) {
          shift = `Start ${shift}`
        }
        if (date && shift) {
          shift = `${date.toFormat('MMMM dd, yyyy')} ${shift}`
        }
        return shift
      },
    },
    {
      Header: 'Clocked In / Out',
      id: 'clocked',
      accessor: (row) => {
        return (
          <div
            style={{
              maxHeight: '80px',
              overflowY: 'scroll',
              overflowX: 'hidden',
              fontSize: '13px',
            }}
            className="mt-2"
          >
            <Table className="compact-table table-bordered" responsive>
              <thead>
                <tr>
                  <th>Clocked</th>
                  <th>Time</th>
                </tr>
              </thead>
              {row.node.timesheetEvents?.edges?.map((edge, i) => (
                <tr key={i} style={{ borderTop: 'none' }}>
                  <td>
                    {edge.node.eventType === 'CLOCK_OUT' ? <>Out</> : <>In</>}
                  </td>
                  <td>
                    {DateTime.fromISO(edge.node.dateTime).toFormat('h:mma')}
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        )
      },
    },
    {
      Header: 'Minutes Clocked',
      id: 'minutesClocked',
      accessor: (row) => {
        if (row.node.minutesClocked !== null) {
          return row.node.minutesClocked
        } else if (row.node.timesheetEvents.length > 0) {
          return 'No Check Out'
        }
      },
    },
    {
      Header: 'Hourly Pay',
      id: 'hourlyPay',
      accessor: (row) => {
        if (row.node.hourlyPay) {
          return `$${row.node.hourlyPay}`
        }
      },
    },
    {
      Header: 'Accrued Pay',
      id: 'accruedPay',
      accessor: (row) => {
        if (row.node.accruedPay !== null) {
          return `$${row.node.accruedPay}`
        } else if (row.node.timesheetEvents.length > 0) {
          return 'No Check out'
        }
      },
    },
    {
      Header: 'Work Event',
      id: 'workEvent',
      accessor: (row) => {
        if (row.node.employeeSchedule !== null) {
          return 'Yes'
        }
      },
    },
    {
      Header: 'Job',
      id: 'job',
      accessor: (row) => {
        if (row.node.employeeJob !== null) {
          return row.node.employeeJob?.job?.name
        }
      },
    },
    {
      Header: 'Location',
      id: 'location',
      accessor: (row) => {
        let location
        if (row.node.employeeSchedule !== null) {
          location = row.node.employeeSchedule.location
        } else if (row.node.employeeJob !== null) {
          location = row.node.employeeJob.job.location
        }
        if (location) {
          return location.fullAddress
        }
      },
    },
    {
      Header: 'Role',
      id: 'role',
      accessor: (row) => {
        if (row.node.employeeJob?.role) {
          return row.node.employeeJob.role.name
        }
      },
    },
    {
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  queryData?.timesheets?.edges.forEach((edge) => {
                    if (!checkedNodeIds.includes(edge.node.id)) {
                      appendIds.push(edge.node.id)
                    }
                  })
                  setCheckedNodeIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedNodeIds([])
                }
              }}
            />
            {checkedNodeIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedNodeIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        const checked = checkedNodeIds.some((id) => id === row.node.id)
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checked}
                onChange={(e) => handleNodeCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    },
  ]

  const onTdClick = (cell) => {
    if (cell.row.original.node) {
      setSeletedTimesheet(cell.row.original.node)
      setShowTimesheetModal(true)
    }
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Timesheet..." />
        </Col>
      </Row>
    )

  if (queryError) return <>Error loading</>
  return (
    <>
      {showJobModal && (
        <JobDetailModal
          jobId={showJobModal}
          showModal={true}
          toggleModal={setShowJobModal}
        />
      )}
      <Row>
        <Col>
          {canModify && (
            <Button variant="link" onClick={toggleNewTimesheetModal}>
              <PlusCircle className="mr-2" />
              New Timesheet
            </Button>
          )}

          {/* {checkedNodeIds.length > 0 && canModify && (
            <Button variant="link" onClick={() => setShowDeleteForm(true)}>
              <Trash className="mr-2" />
              {checkedNodeIds.length === 1
                ? 'Delete Timesheet'
                : 'Delete Timesheets'}
            </Button>
          )} */}
          {(checkedNodeIds.length > 0 ||
            searchTerm ||
            (startDateFilter && endDateFilter)) && (
            <Button
              variant="link"
              disabled={downloading}
              onClick={() => {
                setDownloading(true)
                downloadTimesheet({
                  variables: {
                    input: {
                      startDate: startDateFilter,
                      endDate: endDateFilter,
                      ids: checkedNodeIds,
                      search: searchTerm,
                      associated: seletedAssociateTimesheet,
                    },
                  },
                })
              }}
            >
              <CloudArrowDown className="mr-2" />
              Download Timesheet
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mt-2">
          <Form.Group>
            <Form.Control
              size="sm"
              type="text"
              name="searchTerm"
              placeholder={searchText}
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <DateFilter
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            placeholderStart="Shift From"
            placeholderEnd={'Shifts Until'}
          />
        </Col>
        <Col md={2} className="mt-2">
          <select
            className="form-control-sm form-select"
            onChange={(e) => setSeletedAssociateTimesheet(e.target.value)}
          >
            {dropDownOptions.map(({ key, value }) => (
              <option key={value} value={value}>
                {key}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col md="12">
          <SortableInfiniteTable
            tableData={
              queryData?.timesheets?.edges ? queryData?.timesheets?.edges : []
            }
            onTdClicks={{
              employee: onTdClick,
              shift: onTdClick,
              clocked: onTdClick,
              minutesClocked: onTdClick,
              hourlyPay: onTdClick,
              accruedPay: onTdClick,
              workEvent: onTdClick,
              job: onTdClick,
              location: onTdClick,
              role: onTdClick,
            }}
            loading={loading}
            tableColumns={tableColumns}
            fetchMoreTableData={fetchMore}
            hasMoreTableData={queryData?.timesheets?.pageInfo?.hasNextPage}
            tableHeight={800}
            rowPointer
            hideGlobalFilter
          />
        </Col>
      </Row>

      {showTimesheetModal && (
        <TimesheetModal
          showTimesheetModal={showTimesheetModal}
          updatingTimesheet={seletedTimesheet ? true : false}
          timesheet={seletedTimesheet}
          toggleModal={() => {
            setSeletedTimesheet()
            setShowTimesheetModal(false)
          }}
        />
      )}

      {showDeleteForm && (
        <DeleteTimesheetModal
          timesheetIds={checkedNodeIds.length > 1 ? checkedNodeIds : null}
          timesheetId={checkedNodeIds.length === 1 ? checkedNodeIds[0] : null}
          setCheckedTimesheetIds={setCheckedNodeIds}
          showModal={showDeleteForm}
          toggleModal={setShowDeleteForm}
        />
      )}
    </>
  )
}
export default Timesheet
