import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useMutation } from '@apollo/client'
import { DateTime } from 'luxon'
import {
  CalendarWeek,
  List,
  Envelope,
  BarChartLine,
  CloudArrowDown,
  Funnel,
  CheckSquare,
  PlusCircle,
} from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import Page from '../layout/Page'
import JobDetailModal from './JobDetailModal'
import JobSearchFilter from './JobsSearchFilter'
import JobsDateFilter from './JobsDateFilter'
import JobsList from './JobsList'
import JobsCalendar from './JobsCalendar'
import SendEmployeeScheduleModal from './SendEmployeeScheduleModal'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import JobReportModal from './job_report/JobReportModal'
import ConfirmEmployeesModal from './ConfirmEmployeesModal'
import { useDownloadFile } from '../../libs/downloadFile'

const Schedule = (props) => {
  const {
    employeeId,
    organizationId,
    subjectGroupId,
    locationId,
    contactId,
    bagId,
    itemId,
    tableHeight,
  } = props
  const pageTitle =
    employeeId ||
    organizationId ||
    contactId ||
    bagId ||
    itemId ||
    subjectGroupId ||
    locationId
      ? null
      : 'Schedule'
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { contact } = useParams()
  let history = useHistory()
  let initialStartDateFilter
  let initialEndDateFilter
  let cachedDateFilters = localStorage.getItem('airstudioJobDateFilters')
  let cachedSeachFilters = localStorage.getItem('airstudioJobSearchFilters')
  const canCreateJob = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const location = useLocation()
  const canCreateSession = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  if (!cachedDateFilters) {
    const now = new Date()
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const endDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 7
    )
    initialStartDateFilter = DateTime.utc(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      startDate.getDate(),
      0,
      0,
      0
    )
    initialEndDateFilter = DateTime.utc(
      endDate.getFullYear(),
      endDate.getMonth() + 1,
      endDate.getDate(),
      23,
      59,
      59
    )
  } else {
    cachedDateFilters = JSON.parse(cachedDateFilters)
    if (cachedDateFilters.start) {
      initialStartDateFilter = DateTime.fromISO(cachedDateFilters.start)
    }
    if (cachedDateFilters.end) {
      initialEndDateFilter = DateTime.fromISO(cachedDateFilters.end)
    }
  }
  const [jobs, setJobs] = useState([])
  const [jobDetailId, setJobDetailId] = useState()
  const [jobsView, setJobsView] = useState('list')
  const [componentMounted, setComponentMounted] = useState()
  const [showJobDetailModal, setShowJobDetailModal] = useState()
  const [jobsCursor, setJobsCursor] = useState()
  const [hasMoreJobs, setHasMoreJobs] = useState()
  const [jobDetailOnlySessions, setJobDetailOnlySessions] = useState(false)
  const [filteredEmployees, setFilteredEmployees] = useState()
  const [filteredJobs, setFilteredJobs] = useState()
  const [filteredLocations, setFilteredLocations] = useState()
  const [filteredSubjectGroups, setFilteredSubjectGroups] = useState()
  const [filteredOrganizations, setFilteredOrganizations] = useState()
  const [filteredSubjects, setFilteredSubjects] = useState()
  const [filteredJobTypes, setFilteredJobTypes] = useState()
  const [filteredStages, setFilteredStages] = useState()
  const [appliedSearchFilters, setAppliedSearchFilters] = useState(
    cachedSeachFilters ? JSON.parse(cachedSeachFilters) : {}
  )
  const [appliedStartDateFilter, setAppliedStartDateFilter] = useState()
  const [appliedEndDateFilter, setAppliedEndDateFilter] = useState()
  const [showEmployeeScheduleModal, setShowEmployeeScheduleModal] =
    useState(false)
  const [showEmployeeReports, setShowEmployeeReportsModal] = useState(false)
  const [showConfirmEmployeeAssignments, setShowConfirmEmployeeAssignments] =
    useState(false)
  const [employeeSearchFiltersApplied, setEmployeeSearchFiltersApplied] =
    useState(false)
  const [startDateFilter, setStartDateFilter] = useState(initialStartDateFilter)
  const [endDateFilter, setEndDateFilter] = useState(initialEndDateFilter)
  const [prevNextDateClick, setPrevNextDateClick] = useState(false)
  const [prevNextStartDateFilter, setPrevNextStartDateFilter] = useState()
  const [prevNextEndDateFilter, setPrevNextEndDateFilter] = useState()
  const [unassignedEmployeeJobsFilter, setUnassignedEmployeeJobsFilter] =
    useState(null)
  const [queryFirst, setQueryFirst] = useState(25)
  const [initialQueryRun, setInitalQueryRun] = useState(false)
  const [calendarStartDateFilter, setCalendarStartDateFilter] = useState()
  const [calendarEndDateFilter, setCalendarEndDateFilter] = useState()
  const [calendarDefaultDate, setCalendarDefaultDate] = useState()
  const [calendarDefaultView, setCalendarDefaultView] = useState('week')
  const [currentEmployeeId, setCurrentEmployeeId] = useState(null)
  const [downloading, setDownloading] = useState(false)
  const { downloadAndDeleteFile } = useDownloadFile()

  const [downloadJobsReport] = useMutation(
    gql`
      mutation DownloadJob($input: DownloadJobInput!) {
        downloadJob(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data?.downloadJob?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadJob.file.fileName,
            data.downloadJob.file.displayName,
            data.downloadJob.file.id,
            () => {
              toast.success(`Employee Assignments Downloaded`)
              setDownloading(false)
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const [
    jobsQuery,
    {
      loading: queryLoading,
      error: jobsQueryError,
      data: jobsQueryData,
      fetchMore: jobsQueryFetchMore,
    },
  ] = useLazyQuery(
    gql`
      query ScheduleJobsQuery(
        $cursor: String
        $jobs: [ID]
        $locations: [ID]
        $employees: [ID]
        $subjectGroups: [ID]
        $organizations: [ID]
        $contacts: [ID]
        $subjects: String
        $jobTypes: String
        $stages: String
        $first: Int
        $startDateGte: DateTime
        $endDateLte: DateTime
        $unassignedEmployeeJobs: Boolean
        $equipmentBags: [ID]
        $equipmentItems: [ID]
      ) {
        jobs(
          orderBy: "start_date_time"
          first: $first
          after: $cursor
          id: $jobs
          location: $locations
          employees: $employees
          jobTypes: $jobTypes
          subjectGroups: $subjectGroups
          organizations: $organizations
          contacts: $contacts
          subjects: $subjects
          stages: $stages
          startDateTime_Gte: $startDateGte
          endDateTime_Lte: $endDateLte
          unassignedEmployeeJobs: $unassignedEmployeeJobs
          employeeJobs_EquipmentBags: $equipmentBags
          employeeJobs_EquipmentItems: $equipmentItems
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              cancelled
              startDateTime
              endDateTime
              name
              utilization
              bookable
              sharedBoxFolderId
              stage {
                id
                name
                color
              }
              packageCategories {
                nodeCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              jobType {
                name
                color
              }
              subjectGroups {
                nodeCount
                edges {
                  node {
                    name
                    organization {
                      id
                    }
                  }
                }
              }
              location {
                name
                archived
                fullAddress
              }
              setups
              scheduledSessionsCount
              employeeJobs(orderBy: "created") {
                edges {
                  node {
                    startDateTime
                    endDateTime
                    equipmentBags {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    equipmentItems {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    employee {
                      gaiaUser {
                        firstName
                        lastName
                        abbreviatedName
                      }
                    }
                    role {
                      name
                      abbreviatedName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      pollInterval: 5000,
    }
  )

  const toggleEmployeeScheduleModal = () => {
    setShowEmployeeScheduleModal((prevState) => !prevState)
  }

  const toggleEmployeeReportsModal = () => {
    setShowEmployeeReportsModal((prevState) => !prevState)
  }

  const toggleConfirmEmployeeAssignmentsModal = () => {
    setShowConfirmEmployeeAssignments((prevState) => !prevState)
  }

  const getUtcDt = (jobDateDt) => {
    return DateTime.utc(
      jobDateDt.getFullYear(),
      jobDateDt.getMonth() + 1,
      jobDateDt.getDate(),
      jobDateDt.hour,
      jobDateDt.minute
    )
  }

  useEffect(() => {
    if (
      !window.location.pathname.includes('/employee') &&
      !window.location.pathname.includes('/organization') &&
      !window.location.pathname.includes('/subject-group-detail')
    ) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [])

  const constructQueryVariables = () => {
    const currentFilteredJobs = []
    const currentFilteredEmployees = []
    const currentFilteredLocations = []
    const currentFilteredSubjectGroups = []
    const currentFilteredOrganizations = []
    const currentFilteredSubjects = []
    const currentFilteredJobTypes = []
    const currentFilteredStages = []
    Object.entries(appliedSearchFilters).forEach((appliedSearchFilter) => {
      const appliedSearchFilterEntries = appliedSearchFilter[1]
      if (appliedSearchFilterEntries.nodeType === 'Job') {
        currentFilteredJobs.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Employee') {
        currentFilteredEmployees.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Location') {
        currentFilteredLocations.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Subject Group') {
        currentFilteredSubjectGroups.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Organization') {
        currentFilteredOrganizations.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Subject') {
        currentFilteredSubjects.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Job Type') {
        currentFilteredJobTypes.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Stage') {
        currentFilteredStages.push(appliedSearchFilterEntries.id)
      }
    })
    if (organizationId) {
      currentFilteredOrganizations.push(organizationId)
    }
    if (subjectGroupId) {
      currentFilteredSubjectGroups.push(subjectGroupId)
    }
    if (locationId) {
      currentFilteredLocations.push(locationId)
    }
    setFilteredEmployees(currentFilteredEmployees)
    setFilteredJobTypes(currentFilteredJobTypes)
    setFilteredJobs(currentFilteredJobs)
    setFilteredLocations(currentFilteredLocations)
    setFilteredSubjectGroups(currentFilteredSubjectGroups)
    setFilteredOrganizations(currentFilteredOrganizations)
    setFilteredSubjects(currentFilteredSubjects)
    setFilteredStages(currentFilteredStages)
    const variables = {
      first: queryFirst,
      jobs: currentFilteredJobs,
      employees: currentFilteredEmployees,
      locations: currentFilteredLocations,
      subjectGroups: currentFilteredSubjectGroups,
      organizations: currentFilteredOrganizations,
      subjects: currentFilteredSubjects.join(','),
      jobTypes: currentFilteredJobTypes.join(','),
      stages: currentFilteredStages.join(','),
      contacts: contactId,
      equipmentBags: bagId,
      equipmentItems: itemId,
      unassignedEmployeeJobs: unassignedEmployeeJobsFilter,
    }
    if (startDateFilter) {
      const startDate = startDateFilter.startOf('day')
      variables.startDateGte = startDate
      setAppliedStartDateFilter(startDate)
    } else if (jobsView === 'calendar' && calendarStartDateFilter) {
      const startDate = getUtcDt(calendarStartDateFilter).endOf('day')
      variables.startDateGte = startDate
      setAppliedStartDateFilter(startDate)
    } else {
      setAppliedStartDateFilter()
    }
    if (endDateFilter) {
      const endDate = endDateFilter.endOf('day')
      variables.endDateLte = endDate
      setAppliedEndDateFilter(endDate)
    } else if (jobsView === 'calendar' && calendarEndDateFilter) {
      const endDate = getUtcDt(calendarEndDateFilter).endOf('day')
      variables.endDateLte = endDate
      setAppliedEndDateFilter(endDate)
    } else {
      setAppliedEndDateFilter()
    }
    return variables
  }

  const formatJobDateTime = (jobNode, getDate = false) => {
    const startTime = DateTime.fromISO(jobNode.startDateTime).toFormat('h:mma')
    const endTime = DateTime.fromISO(jobNode.endDateTime).toFormat('h:mma')
    const jobDate = DateTime.fromISO(jobNode.startDateTime).toFormat(
      'EEEE, MMMM d, yyyy'
    )
    if (getDate) return `${jobDate}`
    else return `${startTime} - ${endTime}`
  }

  useEffect(() => {
    if (jobsQueryData?.jobs) {
      if (!initialQueryRun) {
        setInitalQueryRun(true)
      }
      setHasMoreJobs(jobsQueryData.jobs.pageInfo.hasNextPage)
      if (jobsView === 'list' && jobsQueryData.jobs.pageInfo.endCursor) {
        setJobsCursor(jobsQueryData.jobs.pageInfo.endCursor)
      }
      const currentJobs = []
      let tempDates = []
      jobsQueryData.jobs.edges.forEach((job) => {
        let temp = { on: '', data: [] }
        const jobNode = job.node
        let location = ''
        let locationFullAddress = ''
        if (!jobNode?.location?.archived) {
          locationFullAddress = jobNode.location?.fullAddress
          if (jobNode.location?.name) {
            location = jobNode.location?.name
          } else {
            location = jobNode.location?.fullAddress
          }
        } else {
          locationFullAddress = 'Deleted Location'
        }
        let jobDate = formatJobDateTime(jobNode, true)
        let tableObj = {
          id: jobNode.id,
          cancelled: jobNode.cancelled,
          on: formatJobDateTime(jobNode),
          jobTypeColor: jobNode.jobType ? jobNode.jobType.color : null,
          jobType: jobNode.jobType ? jobNode.jobType.name : null,
          location,
          locationFullAddress: locationFullAddress,
          name: jobNode.name,
          sharedBoxFolderId: jobNode.sharedBoxFolderId,
          bookable: jobNode.bookable,
          totalSessions: jobNode.scheduledSessionsCount,
          sessions: jobNode.scheduledSessionsCount,
          employees: jobNode.employeeJobs.edges,
          setups: jobNode.setups,
          utilization: jobNode.utilization,
          packageCategories: jobNode.packageCategories.edges,
          subjectGroups: jobNode.subjectGroups.edges,
          totalSubjectGroups:
            jobNode.subjectGroups.edges.length > 1
              ? jobNode.subjectGroups.edges.length + ' Groups'
              : jobNode.subjectGroups.edges.length == 1
                ? jobNode.subjectGroups.edges.length + ' Group'
                : ' ',
          totalBookable: loggedInUser.permissions.isOrgContact
            ? ' '
            : jobNode.packageCategories.nodeCount > 1
              ? jobNode.packageCategories.nodeCount + ' Categories'
              : jobNode.packageCategories.nodeCount == 1
                ? jobNode.packageCategories.nodeCount + ' Category'
                : ' ',
          startDateTime: new Date(jobNode.startDateTime),
          endDateTime: new Date(jobNode.endDateTime),
          jobDate: formatJobDateTime(jobNode, true),
          jobTime: formatJobDateTime(jobNode),
          stage: jobNode.stage,
        }
        if (tempDates.indexOf(jobDate) === -1) {
          tempDates.push(jobDate)
          temp['on'] = jobDate
          temp['data'].push(tableObj)
          currentJobs.push(temp)
        } else {
          currentJobs.forEach((each_job) => {
            if (each_job.on == jobDate) each_job.data.push(tableObj)
          })
        }
      })
      setJobs(currentJobs)
    }
  }, [jobsQueryData])

  useEffect(() => {
    const currentFilteredJobs = []
    const currentFilteredEmployees = []
    const currentFilteredLocations = []
    const currentFilteredSubjectGroups = []
    const currentFilteredOrganizations = []
    const currentFilteredSubjects = []
    const currentFilteredJobTypes = []
    const currentFilteredStages = []
    Object.entries(appliedSearchFilters).forEach((appliedSearchFilter) => {
      const appliedSearchFilterEntries = appliedSearchFilter[1]
      if (appliedSearchFilterEntries.nodeType === 'Job') {
        currentFilteredJobs.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Employee') {
        currentFilteredEmployees.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Location') {
        currentFilteredLocations.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Subject Group') {
        currentFilteredSubjectGroups.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Organization') {
        currentFilteredOrganizations.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Subject') {
        currentFilteredSubjects.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Job Type') {
        currentFilteredJobTypes.push(appliedSearchFilterEntries.id)
      } else if (appliedSearchFilterEntries.nodeType === 'Stage') {
        currentFilteredStages.push(appliedSearchFilterEntries.id)
      }
    })
    if (currentFilteredEmployees.length > 0) {
      setEmployeeSearchFiltersApplied(true)
    } else {
      setEmployeeSearchFiltersApplied(false)
    }
    setFilteredEmployees(currentFilteredEmployees)
    setFilteredJobTypes(currentFilteredJobTypes)
    setFilteredStages(currentFilteredStages)
    setFilteredJobs(currentFilteredJobs)
    setFilteredLocations(currentFilteredLocations)
    setFilteredSubjectGroups(currentFilteredSubjectGroups)
    setFilteredOrganizations(currentFilteredOrganizations)
    setFilteredSubjects(currentFilteredSubjects)
    setJobsCursor()
    if (organizationId) {
      currentFilteredOrganizations.push(organizationId)
    }
    if (subjectGroupId) {
      currentFilteredSubjectGroups.push(subjectGroupId)
    }
    if (locationId) {
      currentFilteredLocations.push(locationId)
    }
    const variables = {
      first: queryFirst,
      jobs: currentFilteredJobs,
      employees: currentFilteredEmployees,
      locations: currentFilteredLocations,
      jobTypes: currentFilteredJobTypes.join(','),
      stages: currentFilteredStages.join(','),
      subjectGroups: currentFilteredSubjectGroups,
      organizations: currentFilteredOrganizations,
      subjects: currentFilteredSubjects.join(','),
      contacts: contactId,
      equipmentBags: bagId,
      equipmentItems: itemId,
      unassignedEmployeeJobs: unassignedEmployeeJobsFilter,
    }
    if (startDateFilter) {
      const startDate = startDateFilter.startOf('day')
      variables.startDateGte = startDate
      setAppliedStartDateFilter(startDate)
    } else if (calendarStartDateFilter) {
      const startDate = getUtcDt(calendarStartDateFilter).endOf('day')
      variables.startDateGte = startDate
      setAppliedStartDateFilter(startDate)
    } else {
      setAppliedStartDateFilter()
    }
    if (endDateFilter) {
      const endDate = endDateFilter.endOf('day')
      variables.endDateLte = endDate
      setAppliedEndDateFilter(endDate)
    } else if (calendarEndDateFilter) {
      const endDate = getUtcDt(calendarEndDateFilter).endOf('day')
      variables.endDateLte = endDate
      setAppliedEndDateFilter(endDate)
    } else {
      setAppliedEndDateFilter()
    }
    jobsQuery({
      variables,
    })
  }, [appliedSearchFilters])

  useEffect(() => {
    if (jobsView === 'calendar' && (!startDateFilter || !endDateFilter)) {
      const currentCalendarDateTimes = calendarDateTimes()
      if (!startDateFilter) {
        setCalendarStartDateFilter(
          currentCalendarDateTimes.currentCalendarStartDateFilter
        )
      }
      if (!endDateFilter) {
        setCalendarEndDateFilter(
          currentCalendarDateTimes.currentCalendarEndDateFilter
        )
      }
    }
    const variables = constructQueryVariables()
    jobsQuery({
      variables,
    })
  }, [startDateFilter, endDateFilter])

  useEffect(() => {
    const variables = constructQueryVariables()
    jobsQuery({
      variables,
    })
  }, [calendarEndDateFilter, calendarStartDateFilter])

  useEffect(() => {
    const variables = constructQueryVariables()
    jobsQuery({
      variables,
    })
  }, [unassignedEmployeeJobsFilter])

  const fetchMoreJobs = () => {
    const variables = constructQueryVariables()
    variables.cursor = jobsCursor
    jobsQueryFetchMore({
      variables,
    })
  }

  const toggleJobDetailModal = () => {
    if (showJobDetailModal) {
      setJobDetailId(null)
      setJobDetailOnlySessions(false)
    }
    setShowJobDetailModal((prevState) => !prevState)
  }

  const calendarDateTimes = () => {
    let currentCalendarDefaultDate
    if (!calendarDefaultDate) {
      currentCalendarDefaultDate = new Date()
    } else {
      currentCalendarDefaultDate = calendarDefaultDate
    }
    const month = currentCalendarDefaultDate.getMonth()
    const year = currentCalendarDefaultDate.getFullYear()
    const currentCalendarStartDateFilter = new Date(year, month - 1)
    const currentCalendarEndDateFilter = new Date(year, month + 2)
    return {
      currentCalendarDefaultDate,
      currentCalendarStartDateFilter,
      currentCalendarEndDateFilter,
    }
  }

  const handleJobsViewChange = () => {
    if (jobsView === 'list') {
      const currentCalendarDateTimes = calendarDateTimes()
      if (!startDateFilter || !endDateFilter) {
        setJobsCursor(null)
        setQueryFirst(null)
        if (!startDateFilter) {
          setCalendarStartDateFilter(
            currentCalendarDateTimes.currentCalendarStartDateFilter
          )
        }
        if (!endDateFilter) {
          setCalendarEndDateFilter(
            currentCalendarDateTimes.currentCalendarEndDateFilter
          )
        }
      }
      setJobsView('calendar')
    } else {
      setCalendarStartDateFilter(null)
      setCalendarEndDateFilter(null)
      setQueryFirst(25)
      const variables = constructQueryVariables()
      jobsQuery({
        variables,
      })
      setJobsView('list')
    }
  }

  if (!componentMounted) {
    const currentCalendarDateTimes = calendarDateTimes()
    setCalendarStartDateFilter(
      currentCalendarDateTimes.currentCalendarStartDateFilter
    )
    setCalendarEndDateFilter(
      currentCalendarDateTimes.currentCalendarEndDateFilter
    )
    setCalendarDefaultDate(currentCalendarDateTimes.currentCalendarDefaultDate)
    const variables = constructQueryVariables()
    variables.endDateLte = initialEndDateFilter
    variables.startDateGte = initialStartDateFilter
    jobsQuery({
      variables,
    })
    setComponentMounted(true)
  }

  const handleMySchedule = () => {
    setCurrentEmployeeId(loggedInUser.employee.id)
  }

  if (jobsQueryError) return <p>Error loading jobs</p>

  const actions = []
  actions.push({
    icon:
      jobsView === 'calendar' ? <List size={14} /> : <CalendarWeek size={14} />,
    text: jobsView === 'calendar' ? 'List' : 'Calendar',
    onClick:
      jobsView === 'calendar' ? handleJobsViewChange : handleJobsViewChange,
  })

  if (!currentEmployeeId && !loggedInUser.permissions.isOrgContact) {
    actions.push({
      icon: <Funnel size={14} />,
      text: 'My Schedule',
      onClick: handleMySchedule,
    })
  }

  if (
    canCreateJob &&
    employeeSearchFiltersApplied &&
    startDateFilter &&
    endDateFilter &&
    !bagId &&
    !itemId
  ) {
    actions.push({
      icon: <Envelope size={14} />,
      text: 'Employee Schedule',
      onClick: toggleEmployeeScheduleModal,
    })
  }

  if (canCreateJob && !bagId && !itemId) {
    actions.push({
      icon: <Funnel size={14} />,
      text: unassignedEmployeeJobsFilter ? 'All Jobs' : 'Unassigned Staff',
      onClick: () =>
        unassignedEmployeeJobsFilter
          ? setUnassignedEmployeeJobsFilter(null)
          : setUnassignedEmployeeJobsFilter(true),
    })
    actions.push({
      icon: <BarChartLine size={14} />,
      text: 'Reports',
      onClick: toggleEmployeeReportsModal,
    })
  }

  if (
    canCreateJob &&
    appliedStartDateFilter &&
    appliedEndDateFilter &&
    !bagId &&
    !itemId
  ) {
    actions.push({
      disabled: downloading,
      icon: <CloudArrowDown size={14} />,
      text: 'Employee Assignments',
      onClick: () => {
        setDownloading(true)
        downloadJobsReport({
          variables: {
            input: {
              startDate: appliedStartDateFilter,
              endDate: appliedEndDateFilter,
              employeeIds: filteredEmployees,
              jobTypeIds: filteredJobTypes,
              stageIds: filteredStages,
              jobIds: filteredJobs,
              locationIds: filteredLocations,
              subjectGroupIds: filteredSubjectGroups,
              organizationIds: filteredOrganizations,
              subjectIds: filteredSubjects,
              fileType: 'employeeAssignments',
            },
          },
        })
      },
    })
  }
  if (
    (canCreateJob || location.pathname.includes('/employee/')) &&
    startDateFilter &&
    endDateFilter &&
    !bagId &&
    !itemId
  ) {
    actions.push({
      icon: <CheckSquare size={14} />,
      text: 'Confirm Assignments',
      onClick: toggleConfirmEmployeeAssignmentsModal,
    })
  }

  if (canCreateJob && !contactId && !bagId && !itemId) {
    actions.unshift({
      icon: <PlusCircle size={14} />,
      text: 'New Job',
      onClick: () => {
        history.push({
          pathname: '/job',
          state: {
            jobFormType: 'manual',
            redirectAfterJobSave,
          },
        })
      },
    })
  }

  if (canCreateSession && !contactId && !bagId && !itemId) {
    const bookSessionAction = {
      icon: <PlusCircle size={14} />,
      text: 'Book Session',
      onClick: () => history.push('/book-new-session'),
    }
    if (canCreateJob) {
      actions.splice(1, 0, bookSessionAction)
    } else {
      actions.unshift(bookSessionAction)
    }
  }
  let redirectAfterJobSave
  if (organizationId) {
    redirectAfterJobSave = `/organization/${organizationId}`
  } else if (subjectGroupId) {
    redirectAfterJobSave = `/subject-group/${subjectGroupId}`
  }
  return (
    <Page
      title={pageTitle}
      actions={actions}
      actionsNewRow
      actionSpanStyleLength={{
        '1-8': { fontSize: '14px' },
        '9-10': { fontSize: '12px' },
      }}
    >
      <JobSearchFilter
        setAppliedSearchFilters={setAppliedSearchFilters}
        setCurrentEmployeeId={setCurrentEmployeeId}
        appliedSearchFilters={appliedSearchFilters}
        currentEmployeeId={currentEmployeeId}
        dateFilters={
          <JobsDateFilter
            setStartDateFilter={setStartDateFilter}
            setEndDateFilter={setEndDateFilter}
            endDateFilter={endDateFilter}
            startDateFilter={startDateFilter}
            prevNextDateClick={prevNextDateClick}
            setPrevNextDateClick={setPrevNextDateClick}
            prevNextStartDateFilter={prevNextStartDateFilter}
            prevNextEndDateFilter={prevNextEndDateFilter}
          />
        }
      />

      {jobsView === 'list' ? (
        <JobsList
          jobsQueryData={jobsQueryData}
          contact={contact}
          setPrevNextDateClick={setPrevNextDateClick}
          setPrevNextStartDateFilter={setPrevNextStartDateFilter}
          setPrevNextEndDateFilter={setPrevNextEndDateFilter}
          jobs={jobs}
          jobsView={jobsView}
          hasMoreJobs={hasMoreJobs}
          fetchMoreJobs={fetchMoreJobs}
          setJobDetailId={setJobDetailId}
          jobDetailId={jobDetailId}
          toggleJobDetailModal={toggleJobDetailModal}
          setJobDetailOnlySessions={setJobDetailOnlySessions}
          setStartDateFilter={setStartDateFilter}
          setEndDateFilter={setEndDateFilter}
          endDateFilter={endDateFilter}
          startDateFilter={startDateFilter}
          tableHeight={tableHeight}
        />
      ) : (
        <JobsCalendar
          jobsQueryData={jobsQueryData}
          jobs={jobs}
          startDateFilter={startDateFilter}
          endDateFilter={endDateFilter}
          calendarStartDateFilter={calendarStartDateFilter}
          calendarEndDateFilter={calendarEndDateFilter}
          setCalendarStartDateFilter={setCalendarStartDateFilter}
          setCalendarEndDateFilter={setCalendarEndDateFilter}
          jobsView={jobsView}
          queryLoading={queryLoading}
          setJobDetailId={setJobDetailId}
          toggleJobDetailModal={toggleJobDetailModal}
          setCalendarDefaultDate={setCalendarDefaultDate}
          calendarDefaultDate={calendarDefaultDate}
          calendarDefaultView={calendarDefaultView}
          setCalendarDefaultView={setCalendarDefaultView}
        />
      )}
      <JobDetailModal
        sessionsOnly={jobDetailOnlySessions}
        showModal={showJobDetailModal}
        toggleModal={toggleJobDetailModal}
        jobId={jobDetailId}
      />
      {showEmployeeScheduleModal && (
        <SendEmployeeScheduleModal
          showModal={showEmployeeScheduleModal}
          toggleModal={toggleEmployeeScheduleModal}
          filteredEmployees={filteredEmployees}
          startDate={appliedStartDateFilter}
          endDate={appliedEndDateFilter}
        />
      )}
      <JobReportModal
        showModal={showEmployeeReports}
        toggleModal={toggleEmployeeReportsModal}
        organizationId={organizationId}
        subjectGroupId={subjectGroupId}
      />
      <ConfirmEmployeesModal
        showModal={showConfirmEmployeeAssignments}
        toggleModal={toggleConfirmEmployeeAssignmentsModal}
        startDate={appliedStartDateFilter}
        endDate={appliedEndDateFilter}
        employeeId={
          location.pathname.includes('/employee/')
            ? location.pathname.split('/').pop()
            : null
        }
      />
    </Page>
  )
}

export default Schedule
