import React, { useState, useEffect } from 'react'
import {
  Buildings,
  Check,
  InfoCircle,
  PlusCircle,
  CaretRight,
  CaretDown,
  PersonCircle,
  ArrowReturnLeft,
  ClockHistory,
  ExclamationTriangle,
  ReceiptCutoff,
  CreditCard,
  Camera,
  Folder,
} from 'react-bootstrap-icons'
import { DateTime } from 'luxon'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Trash,
  XCircle,
  ExclamationDiamond,
  ArrowUpRightCircle,
} from 'react-bootstrap-icons'
import { Field, ErrorMessage, FieldArray } from 'formik'
import {
  Form,
  Col,
  OverlayTrigger,
  Tooltip,
  Row,
  Table,
  Badge,
  Button,
} from 'react-bootstrap'
import CurrencyInput from 'react-currency-input-field'
import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import SessionHistoryModal from '../../sessions/SessionHistoryModal'
import Reschedule from './Reschedule'
import NewSubjectModal from '../../subject/NewSubjectModal'
import ReviewSubjectModal from './ReviewSubjectModal'
import { client, loggedInUserVar } from '../../../libs/apollo'
import MetaDataTable from '../../common/MetaData'
import Loading from '../../common/Loading'
import SessionStageSearchInput from '../../common/node_search_input/SessionStageSearchInput'
import SessionStage from '../sessions/SessionStage'
import OrganizationModal from '../../organization/OrganizationModal'

export default function JobSessions(props) {
  const {
    formik,
    showSessionSetupError,
    jobFormType,
    setApplyToAllJobs,
    jobId,
    newSessionValues,
    settings,
    makeManualJob,
    jobDetailSessions,
    jobCompleted,
    displaySessions,
    setDisplaySessions,
    displayedSessionLength,
    bookingFlowPackageCategoryId,
    bookingFlowPackageId,
    bookingFlowSubjectId,
    bookingFlowSubjectGroupId,
    bookingFlowRescheduleSessionId,
    bookingFlowChangePackageSessionId,
    bookingFlowResitSessionId,
    bookingFlowResitReasonId,
    bookingFlowReschedulingNoShow,
    sessionData,
    handleFetchMoreSessions,
    conflictingTimesErrors,
    handleSearchTermChange,
    searchTerm,
    searching,
    singleSessionsOnly,
  } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Scheduling Manager',
    'Administrator',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [displaySubjectIndex, setDisplaySubjectIndex] = useState(0)
  const [displaySubjectResults, setDisplaySubjectResults] = useState(false)
  const [displayOrganizationResults, setDisplayOrganizationResults] =
    useState(false)
  const [displayOrganizationIndex, setDisplayOrganizationIndex] = useState(0)
  const [showSessionHistoryModal, setShowSessionHistoryModal] = useState(false)
  const [sessionHistoryId, setSessionHistoryId] = useState()
  const [showRescheduleModal, setShowRescheduleModal] = useState(false)
  const [displayDetails, setDisplayDetails] = useState([])
  const [updatePolicies, setUpdatePolicies] = useState(true)

  const isMultiSessionJob =
    formik.values.sessions > 1 ||
    makeManualJob ||
    jobFormType === 'manual' ||
    jobDetailSessions
  const [resitClicked, setResitClicked] = useState(false)
  const [rescheduleData, setRescheduleData] = useState({})
  const [uniqueEmployees, setUniqueEmployees] = useState([])
  const [displayNewSubModal, setDisplayNewSubModal] = useState(false)
  const [displayNewOrgModal, setDisplayNewOrgModal] = useState(false)
  const [submittingNewOrg, setSubmittingNewOrg] = useState(false)
  const [subjectCreateIndex, setSubjectCreateIndex] = useState(null)
  const [orgCreateIndex, setOrgCreateIndex] = useState(null)
  const [showReviewSubjectModal, setShowReviewSubjectModal] = useState(false)
  const [initialQuery, setInitialQuery] = useState(false)
  const [showSessionStageModal, setShowSessionStageModal] = useState(false)
  const [reviewSubject, setReviewSubject] = useState({
    index: '',
    subjectId: '',
  })

  useEffect(() => {
    if (sessionData) {
      setUpdatePolicies(true)
    }
  }, [sessionData])

  useEffect(() => {
    const updateSessionsWithPolicies = async () => {
      let updatedSessions = []
      const sessions = formik.values.sessions.filter(
        (session) => session.billSubject === true
      )
      const policies = {}
      for (const session of formik.values.sessions) {
        let schedulingPolicies
        if (session.billSubject) {
          if (session.subjectGroupId) {
            const key = `subjectGroup${session.subjectGroupId}`
            if (key in policies) {
              schedulingPolicies = policies[key]
            } else {
              const { data } = await client.query({
                query: gql`
                  query SubjectGroup($subjectGroupId: ID!) {
                    subjectGroup(id: $subjectGroupId) {
                      id
                      schedulingPolicies {
                        applyPolicyFree
                        applyPolicyPaid
                        applyNoShowPolicyPaid
                        applyNoShowPolicyFree
                        noShowFee
                        refundPolicy
                        timeRefundFee
                      }
                    }
                  }
                `,
                fetchPolicy: 'network-only',
                variables: {
                  subjectGroupId: session.subjectGroupId,
                },
              })
              schedulingPolicies = data.subjectGroup.schedulingPolicies
              policies[key] = schedulingPolicies
            }
          } else if (session.sessionOrgId) {
            const key = `organization${session.sessionOrgId}`
            if (key in policies) {
              schedulingPolicies = policies[key]
            } else {
              const { data } = await client.query({
                query: gql`
                  query OrganizationDetail($organizationId: ID!) {
                    organization(id: $organizationId) {
                      id
                      schedulingPolicies {
                        applyPolicyFree
                        applyPolicyPaid
                        applyNoShowPolicyPaid
                        applyNoShowPolicyFree
                        noShowFee
                        refundPolicy
                        timeRefundFee
                      }
                    }
                  }
                `,
                fetchPolicy: 'network-only',
                variables: {
                  organizationId: session.sessionOrgId,
                },
              })
              schedulingPolicies = data.organization.schedulingPolicies
              policies[key] = schedulingPolicies
            }
          } else {
            if ('settings' in policies) {
              schedulingPolicies = policies['settings']
            } else {
              const { data } = await client.query({
                query: gql`
                  query SettingsQuery {
                    settings {
                      edges {
                        node {
                          id
                          applyPolicyFree
                          applyPolicyPaid
                          applyNoShowPolicyPaid
                          applyNoShowPolicyFree
                          noShowFee
                          refundPolicy
                          timeRefundFee
                        }
                      }
                    }
                  }
                `,
                fetchPolicy: 'network-only',
              })
              schedulingPolicies = data.settings.edges[0].node
              policies['settings'] = schedulingPolicies
            }
          }
        }
        updatedSessions.push({ ...session, schedulingPolicies })
      }
      formik.setFieldValue('sessions', updatedSessions)
      setUpdatePolicies(false)
    }

    if (updatePolicies) {
      updateSessionsWithPolicies()
    }
  }, [updatePolicies])

  useEffect(() => {
    let employees = []
    if (formik.values.employeeJobs.length > 0) {
      formik.values.employeeJobs.map((employee) => {
        if (
          employee.employeeId !== '' &&
          !employees.some((emp) => emp.id === employee.employeeId)
        ) {
          employees.push({
            id: employee.employeeId,
            name: employee.employeeName,
          })
        }
      })
      setUniqueEmployees(employees)
    } else {
      setUniqueEmployees([])
    }
    formik.values.sessions.map((session, index) => {
      if (
        employees.length === 0 ||
        !employees.some((emp) => emp.id === session.employeeId)
      ) {
        formik.setFieldValue(`sessions[${index}].employeeId`, '')
      }
    })
  }, [formik.values.employeeJobs])

  useEffect(() => {
    if (setApplyToAllJobs) {
      setApplyToAllJobs(formik.values.applyToAllJobs)
    }
  }, [formik.values.applyToAllJobs])

  const { data: couponData } = useQuery(
    gql`
      query Coupons {
        coupons {
          edges {
            node {
              id
              code
              usedCount
              dollarSavings
              percentSavings
              expiresOn
              usableCount
              usedCount
              sessionPackages {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
            }
          }
          nodeCount
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [getPackages, { data: sessionPackageData }] = useLazyQuery(
    gql`
      query SessionPackages($packageId: [ID]) {
        sessionPackages(id: $packageId, first: 250, orderBy: "title") {
          edges {
            cursor
            node {
              id
              customPriceAndDuration
              durationHighMinutes
              durationLowMinutes
              price
              title
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [
    searchOrganizations,
    { data: organizationsData, fetchMore: fetchMoreOrganizations },
  ] = useLazyQuery(
    gql`
      query Organizations($after: String, $first: Int, $nameIcontains: String) {
        organizations(
          after: $after
          first: $first
          name_Icontains: $nameIcontains
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              subjectGroups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              contacts {
                edges {
                  node {
                    id
                    email
                    fullName
                    phoneNumber
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [searchSubjects, { data: subjectsData, fetchMore: fetchMoreSubjects }] =
    useLazyQuery(
      gql`
        query Subjects(
          $after: String
          $first: Int
          $gaiaUserIsActive: Boolean
          $subjectId: [ID]
          $gaiaUserFullNameIcontains: String
          $gaiaUserEmployeeIdIsnull: Boolean
        ) {
          subjects(
            after: $after
            first: $first
            id: $subjectId
            gaiaUser_Employee_Id_Isnull: $gaiaUserEmployeeIdIsnull
            gaiaUser_IsActive: $gaiaUserIsActive
            gaiaUser_FullName_Icontains: $gaiaUserFullNameIcontains
          ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              cursor
              node {
                id
                studentId
                organization {
                  id
                  name
                  contacts {
                    edges {
                      node {
                        id
                        email
                        fullName
                        phoneNumber
                      }
                    }
                  }
                }
                gaiaUser {
                  id
                  fullName
                  metadata
                  email
                  dummyUsername
                  stripeCustomer {
                    stripePaymentMethods(primary: true) {
                      edges {
                        node {
                          stripeResource
                          created
                        }
                      }
                    }
                  }
                }
                subjectGroups {
                  edges {
                    node {
                      id
                      name
                      organization {
                        id
                      }
                    }
                  }
                }
                subjectGroupsNotBooked {
                  subjectGroup {
                    id
                    name
                    organization {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  useEffect(() => {
    if (!initialQuery) {
      if (bookingFlowSubjectId) {
        searchSubjects({
          variables: {
            first: 1,
            subjectId: bookingFlowSubjectId,
          },
        })
      }
      getPackages()
      setInitialQuery(true)
    }
  })

  useEffect(() => {
    if (subjectsData && sessionPackageData && bookingFlowSubjectId) {
      const subject = subjectsData.subjects.edges[0].node
      formik.setFieldValue(`sessions[0].subjectName`, subject.gaiaUser.fullName)
      formik.setFieldValue(
        `sessions[0].packageCategoryId`,
        bookingFlowPackageCategoryId
      )
      formik.setFieldValue(
        `sessions[0].bookingFlowRescheduleSessionId`,
        bookingFlowRescheduleSessionId
      )
      formik.setFieldValue(
        `sessions[0].bookingFlowReschedulingNoShow`,
        bookingFlowReschedulingNoShow
      )
      formik.setFieldValue(
        `sessions[0].bookingFlowChangePackageSessionId`,
        bookingFlowChangePackageSessionId
      )
      formik.setFieldValue(
        `sessions[0].bookingFlowResitSessionId`,
        bookingFlowResitSessionId
      )
      formik.setFieldValue(
        `sessions[0].bookingFlowResitReasonId`,
        bookingFlowResitReasonId
      )
      handleSubjectClick(subject, subject.gaiaUser.fullName, 0)
      if (bookingFlowSubjectGroupId) {
        subject.subjectGroups?.edges.forEach((edge) => {
          const { node: subjectGroup } = edge
          if (subjectGroup.id == bookingFlowSubjectGroupId) {
            formik.setFieldValue(
              `sessions[0].bookingFlowSubjectGroupName`,
              subjectGroup.name
            )
            handleSubjectGroupChange(
              {
                id: subjectGroup.id,
                sessionOrgId: subjectGroup.organization.id,
              },
              0
            )
          }
        })
      }
      let sessionPackage
      sessionPackageData.sessionPackages.edges.forEach((edge) => {
        if (edge.node.id === bookingFlowPackageId) {
          sessionPackage = edge
        }
      })
      if (sessionPackage) {
        handleSessionPackageChange(sessionPackage, 0)
      }
    }
  }, [subjectsData, sessionPackageData])

  // subject
  const handleSubjectChange = (e, index) => {
    setDisplaySubjectIndex(index)
    setDisplaySubjectResults(true)
    formik.setFieldValue(`sessions[${index}].subjectName`, e.target.value)
    searchSubjects({
      variables: {
        first: 10,
        gaiaUserIsActive: true,
        gaiaUserFullNameIcontains: e.target.value,
      },
    })
  }

  useEffect(() => {
    if (formik.values.sessions) {
      formik.values.sessions.forEach((session, i) => {
        if (
          !formik.values.sessions[i].customSessionPackage &&
          session.startTime
        ) {
          const startTime = DateTime.fromFormat(session.startTime, 'h:mma')
          if (startTime.isValid) {
            const endTime = startTime.plus({
              minutes: formik.values.sessions[i].sessionPackageDuration,
            })
            const endTimeStr = endTime.toFormat('h:mma')
            formik.setFieldValue(`sessions[${i}].endTime`, endTimeStr)
          }
        }
      })
    }
  }, [formik.values.sessions])

  const handleFetchMoreSubjects = (index) => {
    fetchMoreSubjects({
      variables: {
        gaiaUserEmployeeIsnull: true,
        first: 10,
        after: subjectsData.subjects.pageInfo.endCursor,
        gaiaUserIsActive: true,
        gaiaUserFullNameIcontains: formik.values.sessions[index].subjectName,
      },
    })
  }

  const handleSubModalChange = (index) => {
    if (typeof index === 'number') {
      setSubjectCreateIndex(index)
    }
    setDisplayNewSubModal((prevState) => !prevState)
  }

  const handleOrgModalChange = (index) => {
    if (typeof index === 'number') {
      setOrgCreateIndex(index)
    }
    setDisplayNewOrgModal((prevState) => !prevState)
  }

  const handleReviewSubModalChange = (index) => {
    setShowReviewSubjectModal((prevState) => !prevState)
  }

  const handleOrgCreate = (data) => {
    setDisplayNewOrgModal(false)
    handleOrganizationClick(
      data.createOrganization.organization,
      formik,
      orgCreateIndex
    )
  }

  const handleUserCreate = (data) => {
    const subjectName = data.createSubject.subject.gaiaUser.fullName
    handleSubjectClick(
      data.createSubject.subject,
      subjectName,
      subjectCreateIndex
    )
  }

  const handleUserReview = (data) => {
    const subjectName = data.updateSubject.subject.gaiaUser.fullName
    handleSubjectClick(
      data.updateSubject.subject,
      subjectName,
      reviewSubject.index
    )
  }

  const handleSubjectClick = (node, subjectDisplay, index) => {
    setDisplaySubjectResults(false)
    setDisplayNewSubModal(false)
    setShowReviewSubjectModal(false)

    const organizationId = node.organization?.id ? node.organization.id : ''
    const organizationName = node.organization?.name
      ? node.organization.name
      : ''
    formik.setFieldValue(`sessions[${index}].subjectName`, subjectDisplay)
    formik.setFieldValue(`sessions[${index}].subjectId`, node.id)
    formik.setFieldValue(`sessions[${index}].subjectOrgName`, organizationName)
    formik.setFieldValue(
      `sessions[${index}].subjectOrgContacts`,
      node?.organization?.contacts
    )
    formik.setFieldValue(`sessions[${index}].subjectOrgId`, organizationId)
    formik.setFieldValue(
      `sessions[${index}].subjectGroupsNotBooked`,
      node.subjectGroupsNotBooked
    )

    let stripePaymentMethodResource

    if (node.gaiaUser.stripeCustomer) {
      const stripePaymentMethodNodes =
        node.gaiaUser.stripeCustomer.stripePaymentMethods
      if (stripePaymentMethodNodes.edges.length > 0) {
        stripePaymentMethodResource = JSON.parse(
          stripePaymentMethodNodes.edges[
            stripePaymentMethodNodes.edges.length - 1
          ].node.stripeResource
        )
      }
    }

    formik.setFieldValue(
      `sessions[${index}].stripePaymentMethodResource`,
      stripePaymentMethodResource
    )
    setUpdatePolicies(true)
  }

  const handleSubjectXClick = (formik, index) => {
    formik.setFieldValue(`sessions[${index}].subjectName`, '')
    formik.setFieldValue(`sessions[${index}].subjectId`, '')
    formik.setFieldValue(`sessions[${index}].subjectGroupsNotBooked`, [])
    setUpdatePolicies(true)
    try {
      if (formik.values[index].displayPromo) {
        formik.setFieldValue(`sessions[${index}].couponId`, '')
        formik.setFieldValue(`sessions[${index}].displayPromo`, false)
      }
    } catch {
      //
    }
  }

  const handleOrganizationChange = (e, index) => {
    setDisplayOrganizationIndex(index)
    setDisplayOrganizationResults(true)
    formik.setFieldValue(`sessions[${index}].organizationName`, e.target.value)
    searchOrganizations({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleFetchMoreOrganizations = (index) => {
    fetchMoreOrganizations({
      variables: {
        after: organizationsData.organizations.pageInfo.endCursor,
        first: 10,
        nameIcontains: formik.values.sessions[index].organizationName,
      },
    })
  }

  const handleOrganizationClick = (node, formik, index) => {
    setDisplayOrganizationResults(false)
    formik.setFieldValue(`sessions[${index}].organizationName`, node.name)
    formik.setFieldValue(`sessions[${index}].organizationId`, node.id)
    formik.setFieldValue(
      `sessions[${index}].subjectGroupsNotBooked`,
      node.subjectGroups.edges.map((g) => {
        return { subjectGroup: { id: g.node.id, name: g.node.name } }
      })
    )
    formik.setFieldValue(
      `sessions[${index}].orgContacts`,
      node.contacts.edges.map((contact) => ({
        id: contact.node.id,
        email: contact.node.email,
        fullName: contact.node.fullName,
        phoneNumber: contact.node.phoneNumber ? contact.node.phoneNumber : '',
      }))
    )
    setUpdatePolicies(true)
  }

  const handleOrganizationXClick = (formik, index) => {
    formik.setFieldValue(`sessions[${index}].subjectGroupsNotBooked`, [])
    formik.setFieldValue(`sessions[${index}].organizationName`, '')
    formik.setFieldValue(`sessions[${index}].organizationId`, '')
  }

  const calculateCouponSavings = (couponId, packagePrice) => {
    const coupon = couponData.coupons.edges.find(
      (couponNode) => couponNode.node.id === couponId
    )
    let amount
    if (coupon) {
      if (coupon.node.dollarSavings) {
        amount = coupon.node.dollarSavings
      } else {
        amount = parseFloat(
          Math.round(packagePrice * (coupon.node.percentSavings / 100))
        ).toFixed(2)
      }
    }
    return amount
  }

  const calculateFinalDollar = (couponId, packagePrice) => {
    const savings = calculateCouponSavings(couponId, packagePrice)
    return packagePrice - savings
  }

  const handleSessionPackageChange = (e, index) => {
    let newPackageId
    let newPackage
    if (bookingFlowSubjectId && e?.node?.id) {
      newPackageId = e.node.id
      newPackage = e
    } else {
      newPackageId = e.target.value
      newPackage = sessionPackageData.sessionPackages.edges.find(
        (el) => el.node.id === newPackageId
      )
    }
    if (newPackage?.node) {
      formik.setFieldValue(`sessions[${index}].sessionPackageId`, newPackageId)
      formik.setFieldValue(
        `sessions[${index}].customSessionPackage`,
        newPackage?.node.customPriceAndDuration
      )
      formik.setFieldValue(
        `sessions[${index}].sessionPackagePrice`,
        newPackage?.node.customPriceAndDuration ? 0 : newPackage.node.price
      )
      const ogPackageId = formik.values.sessions[index].ogSeshPackageId
      const fields = formik.values.sessions[index].fieldsChanged
      const couponId = formik.values.sessions[index].couponId
      if (newPackage?.node.durationHighMinutes) {
        formik.setFieldValue(
          `sessions[${index}].sessionPackageDuration`,
          newPackage?.node.durationHighMinutes
        )
        if (formik.values.sessions[index].startTime) {
          const startTime = DateTime.fromFormat(
            formik.values.sessions[index].startTime,
            'h:mma'
          )
          if (startTime.isValid) {
            const endTime = startTime.plus({
              minutes: newPackage?.node.durationHighMinutes,
            })
            const endTimeStr = endTime.toFormat('h:mma')
            formik.setFieldValue(`sessions[${index}].endTime`, endTimeStr)
          } else {
            console.error(
              'Invalid start time:',
              formik.values.sessions[index].startTime
            )
          }
        }
      } else if (formik.values.sessions[index].sessionPackageDuration) {
        formik.setFieldValue(`sessions[${index}].sessionPackageDuration`, null)
      }
      if (couponId) {
        const amount = calculateFinalDollar(couponId, newPackage.node.price)
        const couponSavings = calculateCouponSavings(
          couponId,
          newPackage.node.price
        )
        formik.setFieldValue(`sessions[${index}].couponSavings`, couponSavings)
        let salesTax = 0
        if (
          settings.chargeStateSalesTax &&
          formik.values.locationStateSalexTax != null
        ) {
          salesTax = amount * formik.values.locationStateSalexTax
          formik.setFieldValue(
            `sessions[${index}].salesTax`,
            parseFloat(salesTax).toFixed(2)
          )
        }
        formik.setFieldValue(
          `sessions[${index}].finalDollarAmount`,
          parseFloat(amount + salesTax).toFixed(2)
        )
      } else {
        let salesTax = 0
        if (
          settings.chargeStateSalesTax &&
          formik.values.locationStateSalexTax != null
        ) {
          salesTax = newPackage.node.price * formik.values.locationStateSalexTax
          formik.setFieldValue(
            `sessions[${index}].salesTax`,
            parseFloat(salesTax).toFixed(2)
          )
        }
        formik.setFieldValue(
          `sessions[${index}].finalDollarAmount`,
          parseFloat(newPackage.node.price + salesTax).toFixed(2)
        )
      }
      // usableCodes(index);
      if (ogPackageId !== newPackageId) {
        formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
          ...fields,
          package: true,
        })
      } else {
        formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
          ...fields,
          package: false,
        })
      }
    } else {
      formik.setFieldValue(`sessions[${index}].sessionPackageId`, null)
      formik.setFieldValue(`sessions[${index}].sessionPackagePrice`, null)
      formik.setFieldValue(`sessions[${index}].finalDollarAmount`, null)
      formik.setFieldValue(`sessions[${index}].salesTax`, null)
      formik.setFieldValue(`sessions[${index}].couponSavings`, null)
    }
    setUpdatePolicies(true)
  }

  const calculateFinalDollarAmount = (session, index, price) => {
    const couponId = session.couponId
    let _price
    if (couponId) {
      let amount = calculateFinalDollar(couponId, price)
      const couponSavings = calculateCouponSavings(couponId, price)
      formik.setFieldValue(`sessions[${index}].couponSavings`, couponSavings)
      let salesTax = 0
      if (
        settings.chargeStateSalesTax &&
        formik.values.locationStateSalexTax != null
      ) {
        salesTax = amount * formik.values.locationStateSalexTax
        salesTax = parseFloat(salesTax)
        if (typeof salesTax !== 'number') {
          salesTax = 0
        }
        formik.setFieldValue(`sessions[${index}].salesTax`, salesTax.toFixed(2))
      }
      amount = parseFloat(amount)
      if (typeof amount !== 'number') {
        amount = 0
      }
      formik.setFieldValue(
        `sessions[${index}].finalDollarAmount`,
        (amount + parseFloat(salesTax)).toFixed(2)
      )
    } else {
      let salesTax = 0
      if (
        settings.chargeStateSalesTax &&
        formik.values.locationStateSalexTax != null
      ) {
        salesTax = price * formik.values.locationStateSalexTax
        salesTax = parseFloat(salesTax)
        if (typeof salesTax !== 'number') {
          salesTax = 0
        }
        formik.setFieldValue(`sessions[${index}].salesTax`, salesTax.toFixed(2))
      }
      _price = parseFloat(price)
      if (typeof _price !== 'number') {
        _price = 0
      }
      formik.setFieldValue(
        `sessions[${index}].finalDollarAmount`,
        (_price + parseFloat(salesTax)).toFixed(2)
      )
    }
  }
  const handleSubjectOrgChange = (e, incomming, index) => {
    if (incomming === 'subjectClick') {
      formik.setFieldValue(
        `sessions[${index}].subjectSession`,
        e.target.checked
      )
      formik.setFieldValue(`sessions[${index}].organizationName`, '')
      formik.setFieldValue(`sessions[${index}].organizationId`, '')
      formik.setFieldTouched(`sessions[${index}].organizationId`, false)
      formik.setFieldValue(`sessions[${index}].billSubject`, true)
    } else {
      formik.setFieldValue(
        `sessions[${index}].subjectSession`,
        !e.target.checked
      )
      formik.setFieldValue(`sessions[${index}].billSubject`, false)
      formik.setFieldValue(`sessions[${index}].subjectName`, '')
      formik.setFieldValue(`sessions[${index}].subjectId`, '')
      formik.setFieldValue(`sessions[${index}].subjectGroupsNotBooked`, [])
      formik.setFieldTouched(`sessions[${index}].subjectId`, false)
    }
    setUpdatePolicies(true)
  }

  const usableCodes = (index) => {
    // query codes that are not expired and limit has not been reached
    // checking here to see if sessions in job form will put coupon over limit

    if (couponData.nodeCount === 0) {
      return []
    }
    const codesToDisplay = couponData.coupons.edges.filter((coupon) => {
      const { node } = coupon
      let count = 0
      formik.values.sessions.forEach((session) => {
        if (session.couponId === node.id) {
          count += 1
        }
      })

      if (node.usableCount && node.usedCount + count >= node.usableCount) {
        return false
      }

      const couponSessionPackageIds =
        node.sessionPackages.edges.length > 0
          ? node.sessionPackages.edges.map((p) => p.node.id)
          : []

      if (
        couponSessionPackageIds.length > 0 &&
        !couponSessionPackageIds.includes(
          formik.values.sessions[index].sessionPackageId
        )
      ) {
        return false
      }
      return true
    })
    formik.setFieldValue(`sessions[${index}].codesToDisplay`, codesToDisplay)
  }

  useEffect(() => {
    formik.values.sessions.forEach((session, index) => {
      if (session.sessionPackageId) {
        const packagePrice = formik.values.sessions[index].sessionPackagePrice
        let savings = 0
        if (formik.values.sessions[index].couponId) {
          const coupon = couponData.coupons.edges.find(
            (couponNode) =>
              couponNode.id === formik.values.sessions[index].couponId
          )
          if (coupon) {
            if (coupon.dollarSavings) {
              savings = coupon.dollarSavings
            } else {
              savings = parseFloat(
                Math.round(packagePrice * (coupon.percentSavings / 100))
              ).toFixed(2)
            }
            formik.setFieldValue(`sessions[${index}].couponSavings`, savings)
          } else {
            let amount = packagePrice - savings
            let salesTax = 0
            if (
              settings.chargeStateSalesTax &&
              formik.values.locationStateSalexTax != null
            ) {
              salesTax = amount * formik.values.locationStateSalexTax
              formik.setFieldValue(
                `sessions[${index}].salesTax`,
                parseFloat(salesTax).toFixed(2)
              )
            }
            formik.setFieldValue(
              `sessions[${index}].finalDollarAmount`,
              parseFloat(amount + salesTax).toFixed(2)
            )
          }
        }
        let amount = packagePrice - savings
        let salesTax = 0
        if (
          settings.chargeStateSalesTax &&
          formik.values.locationStateSalexTax != null
        ) {
          salesTax = amount * formik.values.locationStateSalexTax
          formik.setFieldValue(
            `sessions[${index}].salesTax`,
            parseFloat(salesTax).toFixed(2)
          )
        }
        formik.setFieldValue(
          `sessions[${index}].finalDollarAmount`,
          parseFloat(amount + salesTax).toFixed(2)
        )
      }
    })
  }, [
    formik.values.locationId,
    formik.values.locationStateSalexTax,
    formik.values.manualLocation,
  ])

  const handlePromoClick = (formik, index, btnType) => {
    if (btnType === 'promo') {
      usableCodes(index)
      formik.setFieldValue(`sessions[${index}].displayPromo`, true)
    } else {
      formik.setFieldValue(`sessions[${index}].displayPromo`, false)
      formik.setFieldValue(`sessions[${index}].couponId`, '')
      let salesTax = 0
      if (
        settings.chargeStateSalesTax &&
        formik.values.locationStateSalexTax != null
      ) {
        salesTax =
          formik.values.sessions[index].sessionPackagePrice *
          formik.values.locationStateSalexTax
        formik.setFieldValue(
          `sessions[${index}].salesTax`,
          parseFloat(salesTax).toFixed(2)
        )
      }
      formik.setFieldValue(
        `sessions[${index}].finalDollarAmount`,
        parseFloat(
          formik.values.sessions[index].sessionPackagePrice + salesTax
        ).toFixed(2)
      )
      formik.setFieldValue(`sessions[${index}].couponSavings`, null)
    }
  }

  const handlePromoCodeChange = (e, formik, index, couponNode) => {
    let coupon
    if (couponNode?.id) {
      coupon = couponNode
    } else {
      const selectedCouponId = e.target.value
      if (selectedCouponId !== '- - -') {
        coupon = formik.values.sessions[index].codesToDisplay.filter(
          (c) => c.node.id === selectedCouponId
        )[0].node
      }
      const fields = formik.values.sessions[index].fieldsChanged
      if (selectedCouponId !== formik.values.sessions[index].ogCouponId) {
        formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
          ...fields,
          promo: true,
        })
      } else {
        formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
          ...fields,
          promo: false,
        })
      }
    }
    if (coupon) {
      formik.setFieldValue(`sessions[${index}].couponId`, coupon.id)
    } else {
      formik.setFieldValue(`sessions[${index}].couponId`, null)
    }
    const packagePrice = formik.values.sessions[index].sessionPackagePrice
    let amount = packagePrice
    let savings
    if (coupon?.dollarSavings) {
      savings = coupon.dollarSavings
      amount -= savings
    } else if (coupon?.percentSavings) {
      savings = parseFloat(
        Math.round(packagePrice * (coupon.percentSavings / 100))
      ).toFixed(2)
      amount -= savings
    }
    formik.setFieldValue(`sessions[${index}].couponSavings`, savings)
    let salesTax = 0
    if (
      settings.chargeStateSalesTax &&
      formik.values.locationStateSalexTax != null
    ) {
      salesTax = amount * formik.values.locationStateSalexTax
      formik.setFieldValue(
        `sessions[${index}].salesTax`,
        parseFloat(salesTax).toFixed(2)
      )
    }
    formik.setFieldValue(
      `sessions[${index}].finalDollarAmount`,
      parseFloat(amount + salesTax).toFixed(2)
    )
  }

  const handleSubjectBlur = (index) => {
    setDisplaySubjectResults(false)
    formik.setFieldTouched(`sessions[${index}].subjectId`, true)
  }

  const handleOrganizationBlur = (index) => {
    setDisplayOrganizationResults(false)
    formik.setFieldTouched(`sessions[${index}].organizationId`, true)
  }

  const handleWaiveBookingFee = (e, formik, index) => {
    if (e.target.checked) {
      formik.setFieldValue(`sessions[${index}].displayPromo`, false)
      formik.setFieldValue(`sessions[${index}].couponId`, '')
    }
    formik.setFieldValue(`sessions[${index}].waiveBookingFee`, e.target.checked)
    const fields = formik.values.sessions[index].fieldsChanged
    if (e.target.checked !== formik.values.sessions[index].ogWaiveBookingFee) {
      formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
        ...fields,
        waiveBooking: true,
      })
    } else {
      formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
        ...fields,
        waiveBooking: false,
      })
    }
  }

  const formatCard = (stripeResource) => {
    return (
      <>
        <CreditCard size={16} className="mr-2" />
        <span className="text-uppercase mr-2 font-weight-bold">
          {stripeResource.card.brand.charAt(0).toUpperCase() +
            stripeResource.card.brand.slice(1)}
        </span>
        <span className="mr-2">ending in {stripeResource.card.last4}</span>
      </>
    )
  }

  const handleCancelNoShowClick = (formik, index, isCancel) => {
    let nextState
    if (isCancel) {
      nextState = !formik.values.sessions[index].cancellingSession
      formik.setFieldValue(`sessions[${index}].cancellingSession`, nextState)
    } else {
      nextState = !formik.values.sessions[index].noShowingSession
      formik.setFieldValue(`sessions[${index}].noShowingSession`, nextState)
    }
    if (nextState) {
      // undoPackageChange(formik, index);
      const display = formik.values.sessions[index].ogSeshPackageName
      const id = formik.values.sessions[index].ogSeshPackageId
      const price = formik.values.sessions[index].ogSeshPackagePrice
      const billingSubject = formik.values.sessions[index].ogBillSubject
      const waivingBookingFee = formik.values.sessions[index].ogWaiveBookingFee
      const ogCouponId = formik.values.sessions[index].ogCouponId
      const ogCouponDisplay = formik.values.sessions[index].ogCouponDisplay

      formik.setFieldValue(`sessions[${index}].sessionPackageName`, display)
      formik.setFieldValue(`sessions[${index}].sessionPackageId`, id)
      formik.setFieldValue(`sessions[${index}].sessionPackagePrice`, price)
      formik.setFieldValue(`sessions[${index}].billSubject`, billingSubject)
      formik.setFieldValue(
        `sessions[${index}].waiveBookingFee`,
        waivingBookingFee
      )
      if (ogCouponId) {
        formik.setFieldValue(`sessions[${index}].displayPromo`, true)
        formik.setFieldValue(`sessions[${index}].couponId`, ogCouponId)
        formik.setFieldValue(
          `sessions[${index}].ogCouponDisplay`,
          ogCouponDisplay
        )
      }

      const subGroupId = formik.values.sessions[index].ogSubjectGroupId
      const subGroupName = formik.values.sessions[index].ogSubjectGroupName
      const ogStartTime = formik.values.sessions[index].ogStartTime
      displayOgCoupon(index)
      formik.setFieldValue(`sessions[${index}].startTime`, ogStartTime)
      formik.setFieldValue(`sessions[${index}].subjectGroupId`, subGroupId)
      formik.setFieldValue(`sessions[${index}].subjectGroupName`, subGroupName)

      if (isCancel) {
        formik.setFieldValue(`sessions[${index}].noShowingSession`, false)
        formik.setFieldValue(`sessions[${index}].reschedulingSession`, false)
      } else {
        formik.setFieldValue(`sessions[${index}].reschedulingSession`, false)
        formik.setFieldValue(`sessions[${index}].cancellingSession`, false)
      }
    }
  }

  const toggleSessionHistoryModal = (sessionId) => {
    setSessionHistoryId(sessionId)
    setShowSessionHistoryModal((prevState) => !prevState)
  }

  const displayOgCoupon = (index) => {
    const ogCouponId = formik.values.sessions[index].ogCouponId
    if (ogCouponId) {
      formik.setFieldValue(`sessions[${index}].displayPromo`, true)
      formik.setFieldValue(`sessions[${index}].couponId`, ogCouponId)
    } else {
      formik.setFieldValue(`sessions[${index}].displayPromo`, false)
      formik.setFieldValue(`sessions[${index}].couponId`, '')
    }
  }

  const toggleRescheduleModal = (
    index,
    actionType,
    sessionPackageId,
    subjectGroupId
  ) => {
    const ogStart = formik.values.sessions[index].ogStartTime
    if (actionType === 'reschedule') {
      if (formik.values.sessions[index].reschedulingSession) {
        formik.setFieldValue(`sessions[${index}].reschedulingSession`, false)
        displayOgCoupon(index)
        formik.setFieldValue(`sessions[${index}].startTime`, ogStart)
      } else {
        setResitClicked(false)
        setRescheduleData({
          index,
          sessionPackageId,
          subjectGroupId,
        })
        setShowRescheduleModal((prevState) => !prevState)
      }
    } else {
      if (formik.values.sessions[index].resittingSession) {
        formik.setFieldValue(`sessions[${index}].resittingSession`, false)
        displayOgCoupon(index)
        formik.setFieldValue(`sessions[${index}].startTime`, ogStart)
      } else {
        setResitClicked(true)
        setShowRescheduleModal((prevState) => !prevState)
      }
    }
  }

  useEffect(() => {
    if (
      formik.values &&
      displayedSessionLength &&
      jobFormType === 'organization'
    ) {
      formik.setFieldValue(`sessions[0].subjectSession`, false)
      formik.setFieldValue(`sessions[0].billSubject`, false)
    }
  }, [formik.values.sessions])

  const handleSessionTimeClick = (
    jobId,
    startTime,
    jobDate,
    location,
    sessionIndex
  ) => {
    setShowRescheduleModal(false)
    const formatLocation = `${location.name}
    ${location.address},
    ${location.city}
    ${location.zipCode}`
    const newStartTime = startTime.replace(/\s/g, '')
    formik.setFieldValue(`sessions[${sessionIndex}].noShowingSession`, false)
    formik.setFieldValue(`sessions[${sessionIndex}].cancellingSession`, false)
    formik.setFieldValue(`sessions[${sessionIndex}].jobId`, jobId)
    formik.setFieldValue(`sessions[${sessionIndex}].startTime`, newStartTime)
    formik.setFieldValue(`sessions[${sessionIndex}].displayPromo`, false)
    formik.setFieldValue(`sessions[${sessionIndex}].couponId`, '')
    const rescheduleDetails = `${newStartTime}, ${jobDate}, ${formatLocation}`
    formik.setFieldValue(
      `sessions[${sessionIndex}].rescheduleDetails`,
      rescheduleDetails
    )

    if (resitClicked) {
      formik.setFieldValue(
        `sessions[${sessionIndex}].reschedulingSession`,
        false
      )
      formik.setFieldValue(`sessions[${sessionIndex}].rescheduleDetails`, '')
      formik.setFieldValue(`sessions[${sessionIndex}].resittingSession`, true)
    } else {
      formik.setFieldValue(
        `sessions[${sessionIndex}].reschedulingSession`,
        true
      )
      formik.setFieldValue(`sessions[${sessionIndex}].resittingSession`, false)
    }
  }

  const handleSessionTimeChange = (e, index, time) => {
    const timeValue = e.target.value
    if (time === 'start') {
      formik.setFieldValue(`sessions[${index}].startTime`, timeValue)
    } else if (time === 'end') {
      formik.setFieldValue(`sessions[${index}].endTime`, timeValue)
    }
    if (
      timeValue.match(/^(?:1[0-2]|0?[0-9]):[0-5][0-9]\s?((?:A|P)\.?M\.?)$/i)
    ) {
      const fields = formik.values.sessions[index].fieldsChanged
      if (time === 'start') {
        if (timeValue !== formik.values.sessions[index].ogStartTime) {
          formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
            ...fields,
            startTime: true,
          })
        } else {
          formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
            ...fields,
            startTime: false,
          })
        }
      } else if (time === 'end') {
        if (timeValue !== formik.values.sessions[index].ogEndTime) {
          formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
            ...fields,
            endTime: true,
          })
        } else {
          formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
            ...fields,
            endTime: false,
          })
        }
      }
    }
  }

  const handleSubjectGroupChange = (e, index, session) => {
    let id
    let sessionOrgId
    if (bookingFlowSubjectId) {
      id = e.id
      sessionOrgId = e.sessionOrgId
    } else {
      id = e.target.value
      sessionOrgId = session.subjectOrgId
    }
    formik.setFieldValue(`sessions[${index}].subjectGroupId`, id)
    formik.setFieldValue(`sessions[${index}].sessionOrgId`, sessionOrgId)
    const fields = formik.values.sessions[index].fieldsChanged
    if (id !== formik.values.sessions[index].ogSubjectGroupId) {
      formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
        ...fields,
        group: true,
      })
    } else {
      formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
        ...fields,
        group: false,
      })
    }
    setUpdatePolicies(true)
  }

  const handleSessionOrgChange = (e, index, orgContacts) => {
    const id = e.target.value
    formik.setFieldValue(`sessions[${index}].sessionOrgId`, id)
    if (orgContacts) {
      formik.setFieldValue(
        `sessions[${index}].orgContacts`,
        orgContacts.edges.map((contact) => ({
          id: contact.node.id,
          email: contact.node.email,
          fullName: contact.node.fullName,
          phoneNumber: contact.node.phoneNumber ? contact.node.phoneNumber : '',
        }))
      )
    } else {
      formik.setFieldValue(`sessions[${index}].orgContacts`, null)
    }
    setUpdatePolicies(true)
  }

  const handleBillSubjectChange = (e, index, subOrOrg) => {
    const billValue = e.target.value
    if (subOrOrg === 'subject') {
      formik.setFieldValue(`sessions[${index}].billSubject`, billValue)
    } else {
      formik.setFieldValue(`sessions[${index}].billSubject`, !billValue)
    }
    const fields = formik.values.sessions[index].fieldsChanged
    if (billValue !== formik.values.sessions[index].ogBillSubject) {
      formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
        ...fields,
        billSubject: true,
      })
    } else {
      formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
        ...fields,
        billSubject: false,
      })
    }
  }

  const handleWaiveNoShowFee = (e, index) => {
    formik.setFieldValue(`sessions[${index}].waiveNoShowFee`, e.target.checked)
    const fields = formik.values.sessions[index].fieldsChanged
    if (e.target.checked !== formik.values.sessions[index].ogWaiveNoShowFee) {
      formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
        ...fields,
        waiveNoShowFee: true,
      })
    } else {
      formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
        ...fields,
        waiveNoShowFee: false,
      })
    }
  }

  const handleWaiveRescheduleCancel = (e, index) => {
    formik.setFieldValue(
      `sessions[${index}].waiveRescheduleCancelFee`,
      e.target.checked
    )
    const fields = formik.values.sessions[index].fieldsChanged
    if (e.target.checked !== formik.values.sessions[index].ogWaiveNoShowFee) {
      formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
        ...fields,
        wavieRescheduleCancel: true,
      })
    } else {
      formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
        ...fields,
        wavieRescheduleCancel: false,
      })
    }
  }

  const handleResetClick = (index) => {
    const subGroupId = formik.values.sessions[index].ogSubjectGroupId
    const subGroupName = formik.values.sessions[index].ogSubjectGroupName
    const ogStartTime = formik.values.sessions[index].ogStartTime
    const ogWaiveBookFee = formik.values.sessions[index].ogWaiveBookingFee
    const ogWaiveNoShowFee = formik.values.sessions[index].ogWaiveNoShowFee
    const ogWaiveRescheduleCancelFee =
      formik.values.sessions[index].ogWaiveRescheduleCancelFee
    const display = formik.values.sessions[index].ogSeshPackageName
    const id = formik.values.sessions[index].ogSeshPackageId
    const price = formik.values.sessions[index].ogSeshPackagePrice
    const billingSubject = formik.values.sessions[index].ogBillSubject
    formik.setFieldValue(`sessions[${index}].startTime`, ogStartTime)
    formik.setFieldValue(`sessions[${index}].subjectGroupId`, subGroupId)
    formik.setFieldValue(`sessions[${index}].subjectGroupName`, subGroupName)
    formik.setFieldValue(`sessions[${index}].waiveBookingFee`, ogWaiveBookFee)
    formik.setFieldValue(`sessions[${index}].waiveNoShowFee`, ogWaiveNoShowFee)
    formik.setFieldValue(
      `sessions[${index}].waiveRescheduleCancelFee`,
      ogWaiveRescheduleCancelFee
    )
    formik.setFieldValue(`sessions[${index}].sessionPackageName`, display)
    formik.setFieldValue(`sessions[${index}].sessionPackageId`, id)
    formik.setFieldValue(`sessions[${index}].sessionPackagePrice`, price)
    formik.setFieldValue(`sessions[${index}].billSubject`, billingSubject)
    formik.setFieldValue(`sessions[${index}].fieldsChanged`, {
      package: false,
      time: false,
      group: false,
      promo: false,
      billSubject: false,
      waiveBooking: false,
      waiveNoShow: false,
      waiveRescheduleCancel: false,
    })
    displayOgCoupon(index)
  }

  const handleReviewSubjectClick = (index, subjectId) => {
    setReviewSubject({
      index,
      subjectId,
    })
    setShowReviewSubjectModal(true)
  }
  let tableHeight
  if (formik.values.sessions.length === 0) {
    tableHeight = 5
  } else if (formik.values.sessions.length === 1) {
    tableHeight = 630
  } else {
    tableHeight = 730
  }
  return (
    <>
      {(searching || formik.values.sessions.length > 0) &&
        !jobDetailSessions && (
          <Form.Row className="mt-2" style={{ marginBottom: '-10px' }}>
            <Form.Group as={Col} md={8}>
              <h5>Sessions</h5>
              {showSessionSetupError && (
                <small style={{ color: 'red' }} className="mb-2">
                  <ExclamationTriangle className="mr-2" />
                  Sessions Must Occur Between The Job Start And End Time, Must
                  Not Occur During A Break, Or Cause More Sessions Than Setups
                  Allow
                </small>
              )}
            </Form.Group>
          </Form.Row>
        )}
      {!singleSessionsOnly && displaySessions && (
        <Form.Row>
          {jobId && (
            <Form.Group as={Col} md={4}>
              <Form.Control
                size="sm"
                type="text"
                name="searchTerm"
                placeholder="Search Sessions"
                value={searchTerm}
                onChange={(event) => handleSearchTermChange(event, formik)}
              />
            </Form.Group>
          )}
          {settings.sessionStages && (
            <Form.Group as={Col} md={4}>
              <Form.Label>
                All Session Stages
                <span
                  type="button"
                  className="p-0 ml-2 btn-link"
                  onClick={() => {
                    setShowSessionStageModal(true)
                  }}
                >
                  <Folder />
                </span>
              </Form.Label>
              <SessionStageSearchInput
                formik={formik}
                dropdown
                formikValue={`sessionStage`}
                error={formik.errors.sessionStageId}
              />
            </Form.Group>
          )}
        </Form.Row>
      )}
      {searching && <Loading />}
      <FieldArray
        name="sessions"
        render={(arrayHelpers) => (
          <Row>
            <Col md={singleSessionsOnly ? '12' : '9'}>
              <div>
                {displaySessions && (
                  <div>
                    <InfiniteScroll
                      className={
                        formik.values.sessions.length > 1 ? 'px-3 pt-1' : 'px-3'
                      }
                      style={{ overflowX: 'hidden' }}
                      height={tableHeight}
                      dataLength={formik.values.sessions.length}
                      next={sessionData ? handleFetchMoreSessions : () => {}}
                      hasMore={
                        sessionData
                          ? sessionData.sessions.pageInfo.hasNextPage
                          : false
                      }
                      loader={<Loading message="Loading Sessions..." />}
                    >
                      {formik.values.sessions.map((session, index) => {
                        const setMetaData = (metaData) => {
                          formik.setFieldValue(
                            `sessions[${index}].metadata`,
                            metaData
                          )
                        }
                        const displayBillPromo =
                          session.newSession ||
                          session.ogSeshPackageId !== session.sessionPackageId
                        return (
                          <Row key={index}>
                            <Col md={displayedSessionLength > 1 ? 12 : 12}>
                              <div
                                className={
                                  isMultiSessionJob
                                    ? 'border border-secondary bg-white rounded my-3 px-3 pb-3'
                                    : ''
                                }
                              >
                                <div
                                  className={
                                    isMultiSessionJob
                                      ? 'd-block border-bottom d-flex align-items-center justify-content-between py-3 mb-3'
                                      : 'd-none'
                                  }
                                >
                                  <div>
                                    {!session.newSession ? (
                                      <div>
                                        {session.subjectSession ? (
                                          <div className="d-flex">
                                            <span className="d-flex align-items-center text-secondary  mx-2">
                                              <PersonCircle className="mr-1" />
                                              Subject Session
                                            </span>
                                            {session.completed && (
                                              <Badge
                                                className="mr-1 text-white d-flex justify-content-center align-items-center"
                                                style={{
                                                  backgroundColor: 'green',
                                                }}
                                              >
                                                {' '}
                                                completed
                                              </Badge>
                                            )}
                                            {session.rescheduled && (
                                              <Badge
                                                className="mr-1 text-white d-flex justify-content-center align-items-center"
                                                style={{
                                                  backgroundColor: 'green',
                                                }}
                                              >
                                                {' '}
                                                rescheduled
                                              </Badge>
                                            )}

                                            {session.resitScheduled && (
                                              <Badge
                                                className="mr-1 text-white d-flex justify-content-center align-items-center"
                                                style={{
                                                  backgroundColor: 'green',
                                                }}
                                              >
                                                {' '}
                                                resit scheduled
                                              </Badge>
                                            )}
                                            {session.noShow && (
                                              <Badge
                                                className="mr-1 text-alert d-flex justify-content-center align-items-center"
                                                style={{
                                                  backgroundColor: 'red',
                                                }}
                                              >
                                                {' '}
                                                no show
                                              </Badge>
                                            )}
                                            {session.cancelled && (
                                              <Badge
                                                className="mr-1 text-alert d-flex justify-content-center align-items-center"
                                                style={{
                                                  backgroundColor: 'orange',
                                                }}
                                              >
                                                {' '}
                                                cancelled
                                              </Badge>
                                            )}
                                          </div>
                                        ) : (
                                          <>
                                            <span className="d-flex align-items-center text-secondary">
                                              <Buildings className="mr-1" />
                                              Organization Session
                                            </span>
                                            {session.completed && (
                                              <Badge
                                                className="text-white d-flex justify-content-center align-items-center"
                                                style={{
                                                  backgroundColor: 'green',
                                                }}
                                              >
                                                {' '}
                                                completed
                                              </Badge>
                                            )}
                                            {session.noShow && (
                                              <Badge
                                                className="text-alert d-flex justify-content-center align-items-center"
                                                style={{
                                                  backgroundColor: 'red',
                                                }}
                                              >
                                                {' '}
                                                no show
                                              </Badge>
                                            )}
                                            {session.cancelled && (
                                              <Badge
                                                className="text-alert d-flex justify-content-center align-items-center"
                                                style={{
                                                  backgroundColor: 'orange',
                                                }}
                                              >
                                                {' '}
                                                cancelled
                                              </Badge>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <>
                                        <Form.Check
                                          inline
                                          disabled={
                                            (jobId && !session.newSession) ||
                                            !canMutate
                                          }
                                          label="Subject"
                                          checked={session.subjectSession}
                                          onChange={(e) =>
                                            handleSubjectOrgChange(
                                              e,
                                              'subjectClick',
                                              index
                                            )
                                          }
                                        />

                                        <Form.Check
                                          inline
                                          disabled={
                                            (jobId && !session.newSession) ||
                                            !canMutate
                                          }
                                          label="Organization"
                                          checked={!session.subjectSession}
                                          onChange={(e) =>
                                            handleSubjectOrgChange(
                                              e,
                                              'orgClick',
                                              index
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </div>

                                  <div>
                                    <>
                                      {session.id && (
                                        <button
                                          type="button"
                                          className="p-0 btn-link"
                                          onClick={() =>
                                            toggleSessionHistoryModal(
                                              session.id
                                            )
                                          }
                                        >
                                          <ClockHistory
                                            size={14}
                                            className="mr-1"
                                          />{' '}
                                          History
                                        </button>
                                      )}
                                    </>
                                    {!session.newSession &&
                                    formik.values.sessions[index] &&
                                    formik.values.sessions[index]
                                      .fieldsChanged &&
                                    Object.values(
                                      formik.values.sessions[index]
                                        .fieldsChanged
                                    ).some(Boolean) ? (
                                      <button
                                        type="button"
                                        className="ml-2 btn-link"
                                        onClick={() => handleResetClick(index)}
                                      >
                                        <ArrowReturnLeft className="mr-2" />
                                        <span className="font-italic">
                                          Reset
                                        </span>
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                                {index in conflictingTimesErrors && (
                                  <Form.Row
                                    className="mb-2"
                                    style={{ marginLeft: '1px' }}
                                  >
                                    <small
                                      style={{ color: 'red' }}
                                      className="mb-2"
                                    >
                                      <ExclamationTriangle className="mr-2" />
                                      Sessions Must Occur Between The Job Start
                                      And End Time, Must Not Occur During A
                                      Break, Or Cause More Sessions Than Setups
                                      Allow
                                    </small>
                                  </Form.Row>
                                )}
                                <Form.Row>
                                  <Form.Group
                                    as={Col}
                                    xs={session.subjectId ? 10 : 12}
                                    md={5}
                                  >
                                    {session.subjectSession ? (
                                      <>
                                        <Form.Label>
                                          Subject
                                          {session.newSession && (
                                            <button
                                              type="button"
                                              className="btn-link ml-2"
                                              onClick={() =>
                                                handleSubModalChange(index)
                                              }
                                            >
                                              <span
                                                style={{ fontSize: '12px' }}
                                              >
                                                <PlusCircle className="mr-1" />
                                                New Subject
                                              </span>
                                            </button>
                                          )}
                                        </Form.Label>
                                        <Form.Control
                                          placeholder="Search Subjects"
                                          value={session.subjectName}
                                          onBlur={() =>
                                            handleSubjectBlur(index)
                                          }
                                          onChange={(e) =>
                                            handleSubjectChange(
                                              e,
                                              index,
                                              formik
                                            )
                                          }
                                          readOnly={Boolean(session.subjectId)}
                                          className={
                                            session.subjectId
                                              ? ' border border-success form-control form-control-sm'
                                              : 'form-control form-control-sm'
                                          }
                                        />

                                        <ErrorMessage
                                          name={`sessions[${index}].subjectId`}
                                        >
                                          {(msg) => (
                                            <span className="text-danger">
                                              {msg}
                                            </span>
                                          )}
                                        </ErrorMessage>

                                        {subjectsData &&
                                        displaySubjectResults &&
                                        displaySubjectIndex === index ? (
                                          <InfiniteScroll
                                            height={100}
                                            dataLength={
                                              subjectsData.subjects.edges.length
                                            } //This is important field to render the next data
                                            next={() =>
                                              handleFetchMoreSubjects(index)
                                            }
                                            hasMore={
                                              subjectsData?.subjects.pageInfo
                                                .hasNextPage
                                            }
                                            loader={<Loading />}
                                          >
                                            <Table size="sm" hover>
                                              <tbody>
                                                {subjectsData.subjects.edges.map(
                                                  (subject) => {
                                                    const { node } = subject
                                                    let subjectDisplay = ''
                                                    if (
                                                      node.gaiaUser.fullName
                                                    ) {
                                                      subjectDisplay =
                                                        node.gaiaUser.fullName
                                                    }
                                                    if (
                                                      !node.gaiaUser
                                                        ?.dummyUsername &&
                                                      node.gaiaUser.email
                                                    ) {
                                                      if (subjectDisplay) {
                                                        subjectDisplay = `${subjectDisplay}, ${node.gaiaUser.email}`
                                                      } else {
                                                        subjectDisplay =
                                                          node.gaiaUser.email
                                                      }
                                                    }
                                                    if (node.studentId) {
                                                      if (subjectDisplay) {
                                                        subjectDisplay = `${subjectDisplay}, ${node.studentId}`
                                                      } else {
                                                        subjectDisplay =
                                                          node.studentId
                                                      }
                                                    }
                                                    if (node.organization) {
                                                      if (subjectDisplay) {
                                                        subjectDisplay = `${subjectDisplay}, ${node.organization.name}`
                                                      } else {
                                                        subjectDisplay =
                                                          node.organization
                                                      }
                                                    }
                                                    return (
                                                      <tr
                                                        onMouseDown={() =>
                                                          handleSubjectClick(
                                                            node,
                                                            subjectDisplay,
                                                            index
                                                          )
                                                        }
                                                        key={node.id}
                                                        className="hover text-decoration-none"
                                                      >
                                                        <td>
                                                          <small>
                                                            {subjectDisplay}
                                                          </small>
                                                        </td>
                                                      </tr>
                                                    )
                                                  }
                                                )}
                                              </tbody>
                                            </Table>
                                          </InfiniteScroll>
                                        ) : null}
                                      </>
                                    ) : (
                                      <>
                                        <Form.Label>
                                          Organization
                                          {session.newSession && (
                                            <button
                                              type="button"
                                              className="btn-link ml-2"
                                              onClick={() =>
                                                handleOrgModalChange(index)
                                              }
                                            >
                                              <span
                                                style={{ fontSize: '12px' }}
                                              >
                                                <PlusCircle className="mr-1" />
                                                New Organization
                                              </span>
                                            </button>
                                          )}
                                        </Form.Label>
                                        <Form.Control
                                          placeholder="Search Organizations"
                                          value={session.organizationName}
                                          onBlur={() =>
                                            handleOrganizationBlur(index)
                                          }
                                          onChange={(e) =>
                                            handleOrganizationChange(
                                              e,
                                              index,
                                              formik
                                            )
                                          }
                                          readOnly={Boolean(
                                            session.organizationId
                                          )}
                                          className={
                                            session.organizationId
                                              ? ' border border-success form-control form-control-sm'
                                              : 'form-control form-control-sm'
                                          }
                                        />

                                        <ErrorMessage
                                          name={`sessions[${index}].organizationId`}
                                        >
                                          {(msg) => (
                                            <span className="text-danger">
                                              {msg}
                                            </span>
                                          )}
                                        </ErrorMessage>

                                        {organizationsData &&
                                        displayOrganizationResults &&
                                        displayOrganizationIndex === index ? (
                                          <InfiniteScroll
                                            height={100}
                                            dataLength={
                                              organizationsData.organizations
                                                .edges.length
                                            } //This is important field to render the next data
                                            next={() =>
                                              handleFetchMoreOrganizations(
                                                index
                                              )
                                            }
                                            hasMore={
                                              organizationsData?.organizations
                                                .pageInfo.hasNextPage
                                            }
                                            loader={<Loading />}
                                          >
                                            <Table size="sm" hover>
                                              <tbody>
                                                {organizationsData.organizations.edges.map(
                                                  (org) => {
                                                    const { node } = org
                                                    return (
                                                      <tr
                                                        onMouseDown={() =>
                                                          handleOrganizationClick(
                                                            node,
                                                            formik,
                                                            index
                                                          )
                                                        }
                                                        key={node.id}
                                                        className="hover text-decoration-none"
                                                      >
                                                        <td>
                                                          <small>
                                                            {node.name}
                                                          </small>
                                                        </td>
                                                      </tr>
                                                    )
                                                  }
                                                )}
                                              </tbody>
                                            </Table>
                                          </InfiniteScroll>
                                        ) : null}
                                      </>
                                    )}
                                  </Form.Group>
                                  {session.organizationId &&
                                  !session.subjectSession ? (
                                    <Form.Group
                                      as={Col}
                                      xs="1"
                                      className="d-flex align-items-end pb-1"
                                    >
                                      <>
                                        <button
                                          type="button"
                                          disabled={
                                            jobId && !session.newSession
                                          }
                                          className="p-0 mr-1 btn-link"
                                          onClick={() =>
                                            handleOrganizationXClick(
                                              formik,
                                              index
                                            )
                                          }
                                        >
                                          <XCircle />
                                        </button>
                                      </>
                                    </Form.Group>
                                  ) : null}

                                  {session.subjectId ? (
                                    <Form.Group
                                      as={Col}
                                      xs="1"
                                      className={
                                        formik.errors.sessionPackageId
                                          ? 'd-flex align-items-center pt-1'
                                          : 'd-flex align-items-end pb-1'
                                      }
                                    >
                                      <>
                                        <button
                                          type="button"
                                          disabled={
                                            jobId && !session.newSession
                                          }
                                          className="p-0 mr-1 btn-link"
                                          onClick={() =>
                                            handleSubjectXClick(formik, index)
                                          }
                                        >
                                          <XCircle />
                                        </button>
                                        <button
                                          type="button"
                                          disabled={false}
                                          className="p-0 mr-1 btn-link"
                                          onClick={() =>
                                            handleReviewSubjectClick(
                                              index,
                                              session.subjectId
                                            )
                                          }
                                        >
                                          <PersonCircle />
                                        </button>

                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="btn-link p-0"
                                          href={`/subject/${formik.values.sessions[index].subjectId}`}
                                        >
                                          <ArrowUpRightCircle />
                                        </a>
                                      </>
                                    </Form.Group>
                                  ) : null}

                                  <Form.Group
                                    as={Col}
                                    xs={
                                      session.subjectId || session.subjectId
                                        ? 10
                                        : 12
                                    }
                                    md={5}
                                  >
                                    <>
                                      <Form.Label>Session Package</Form.Label>

                                      <Form.Control
                                        as="select"
                                        disabled={
                                          session.rescheduled ||
                                          session.cancelled
                                        }
                                        // readOnly={Boolean(session.sessionPackageId)}
                                        onChange={(e) =>
                                          handleSessionPackageChange(e, index)
                                        }
                                        value={
                                          formik.values.sessions[index]
                                            .sessionPackageId
                                        }
                                        className="form-control-sm form-select"
                                      >
                                        <option key="">- - -</option>
                                        {sessionPackageData &&
                                          sessionPackageData.sessionPackages.edges.map(
                                            (packageNode) => {
                                              const { node } = packageNode
                                              let description
                                              if (node.customPriceAndDuration) {
                                                description = `${node.title}, Custom Price and Duration`
                                              } else {
                                                description = `${node.title}, $${node.price}, ${node.durationLowMinutes}-${node.durationHighMinutes} Minutes`
                                              }
                                              return (
                                                <option
                                                  value={node.id}
                                                  key={node.id}
                                                >
                                                  {description}
                                                </option>
                                              )
                                            }
                                          )}
                                      </Form.Control>

                                      <ErrorMessage
                                        name={`sessions[${index}].sessionPackageId`}
                                      >
                                        {(msg) => (
                                          <span className="text-danger">
                                            {msg}
                                          </span>
                                        )}
                                      </ErrorMessage>
                                    </>
                                  </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                  <Form.Group as={Col} md={5} className="mb-0">
                                    <Form.Group>
                                      <Form.Label>Start Time</Form.Label>
                                      {formik.errors?.sessions?.length >
                                        index &&
                                        formik.errors.sessions[index] &&
                                        formik.errors.sessions[index]
                                          .startTime && (
                                          <span className="text-danger ml-2">
                                            {
                                              formik.errors?.sessions[index]
                                                ?.startTime
                                            }
                                          </span>
                                        )}
                                      <Form.Control
                                        style={{
                                          width: '100px',
                                        }}
                                        disabled={
                                          session.cancelled ||
                                          session.noShow ||
                                          session.cancellingSession ||
                                          session.noShowingSession ||
                                          session.reschedulingSession ||
                                          session.rescheduled ||
                                          session.resittingSession
                                        }
                                        name={`formik.values.sessions[${index}].startTime`}
                                        value={
                                          formik.values.sessions[index]
                                            .startTime
                                        }
                                        onChange={(e) =>
                                          handleSessionTimeChange(
                                            e,
                                            index,
                                            'start'
                                          )
                                        }
                                        className="form-control form-control-sm"
                                      />
                                      <>
                                        <Form.Label>End Time</Form.Label>
                                        {formik.errors?.sessions?.length >
                                          index &&
                                          formik.errors.sessions[index] &&
                                          formik.errors.sessions[index]
                                            .endTime && (
                                            <span className="text-danger ml-2">
                                              {
                                                formik.errors?.sessions[index]
                                                  ?.endTime
                                              }
                                            </span>
                                          )}
                                        <Form.Control
                                          style={{
                                            width: '100px',
                                          }}
                                          disabled={
                                            session.cancelled ||
                                            session.noShow ||
                                            session.cancellingSession ||
                                            session.noShowingSession ||
                                            session.reschedulingSession ||
                                            session.rescheduled ||
                                            session.resittingSession ||
                                            !session.customSessionPackage
                                          }
                                          name={`formik.values.sessions[${index}].endTime`}
                                          value={
                                            formik.values.sessions[index]
                                              .endTime
                                          }
                                          onChange={(e) =>
                                            handleSessionTimeChange(
                                              e,
                                              index,
                                              'end'
                                            )
                                          }
                                          className="form-control form-control-sm"
                                        />
                                      </>
                                    </Form.Group>
                                    <Form.Group>
                                      {session.displayPromo && (
                                        <>
                                          <Form.Label>
                                            <button
                                              type="button"
                                              disabled={!displayBillPromo}
                                              className={
                                                displayBillPromo
                                                  ? 'pr-1 py-0 special btn-link'
                                                  : 'd-none'
                                              }
                                              onMouseDown={() =>
                                                handlePromoClick(
                                                  formik,
                                                  index,
                                                  'remove'
                                                )
                                              }
                                            >
                                              <small className="mr-1">
                                                Remove Coupon
                                              </small>
                                            </button>
                                          </Form.Label>
                                        </>
                                      )}
                                      {!session.displayPromo &&
                                      !session.waiveBookingFee &&
                                      displayBillPromo &&
                                      session.sessionPackagePrice &&
                                      session.sessionPackagePrice > 0 ? (
                                        <Form.Label>
                                          <button
                                            type="button"
                                            className="pr-1 py-0 btn-link"
                                            onMouseDown={() =>
                                              handlePromoClick(
                                                formik,
                                                index,
                                                'promo'
                                              )
                                            }
                                          >
                                            <small className="mr-1">
                                              Add Coupon
                                            </small>
                                          </button>
                                        </Form.Label>
                                      ) : (
                                        <></>
                                      )}
                                      {session.displayPromo ? (
                                        <Row className="mt-2 mb-3">
                                          <Col
                                            xs={7}
                                            className="d-flex align-items-center"
                                          >
                                            <>
                                              {session.codesToDisplay &&
                                              session.codesToDisplay.length >
                                                0 ? (
                                                <Form.Control
                                                  as="select"
                                                  onChange={(e) =>
                                                    handlePromoCodeChange(
                                                      e,
                                                      formik,
                                                      index,
                                                      null
                                                    )
                                                  }
                                                  className="form-control-sm form-select"
                                                >
                                                  <option value="- - -">
                                                    - - -
                                                  </option>
                                                  {formik.values.sessions[
                                                    index
                                                  ].codesToDisplay.map(
                                                    (couponNode) => {
                                                      const { node } =
                                                        couponNode
                                                      return (
                                                        <option
                                                          key={node.id}
                                                          value={node.id}
                                                        >
                                                          {node.code + ', '}{' '}
                                                          {node.dollarSavings
                                                            ? `$${node.dollarSavings}`
                                                            : `${node.percentSavings}%`}
                                                        </option>
                                                      )
                                                    }
                                                  )}
                                                </Form.Control>
                                              ) : (
                                                <small className="text-secondary">
                                                  No Coupons Available
                                                </small>
                                              )}
                                            </>
                                          </Col>
                                        </Row>
                                      ) : null}
                                      <Table
                                        size="sm"
                                        style={{ width: '250px' }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td style={{ width: '100px' }}>
                                              <small>Session Package</small>
                                            </td>
                                            <td
                                              className="text-end"
                                              style={{ width: '100px' }}
                                            >
                                              {session.sessionPackageId &&
                                                !session.customSessionPackage && (
                                                  <>
                                                    <small>
                                                      $
                                                      {session.sessionPackagePrice &&
                                                      !session.waiveBookingFee ? (
                                                        <>
                                                          {session.sessionPackagePrice +
                                                            '.00'}
                                                        </>
                                                      ) : (
                                                        <>0.00</>
                                                      )}
                                                    </small>
                                                  </>
                                                )}
                                              {session.sessionPackageId &&
                                                session.customSessionPackage && (
                                                  <>
                                                    <CurrencyInput
                                                      name={`sessions[${index}].sessionPackagePrice`}
                                                      placeholder="price"
                                                      value={
                                                        formik.values.sessions[
                                                          index
                                                        ].sessionPackagePrice
                                                      }
                                                      onValueChange={(
                                                        value,
                                                        name
                                                      ) => {
                                                        formik.setFieldValue(
                                                          name,
                                                          value
                                                        )
                                                        if (
                                                          value !== undefined
                                                        ) {
                                                          calculateFinalDollarAmount(
                                                            formik.values
                                                              .sessions[index],
                                                            index,
                                                            value
                                                          )
                                                        } else {
                                                          calculateFinalDollarAmount(
                                                            formik.values
                                                              .sessions[index],
                                                            index,
                                                            0
                                                          )
                                                        }
                                                      }}
                                                      className="form-control form-control-sm"
                                                      decimalsLimit={2}
                                                      prefix="$"
                                                    />
                                                    {formik.errors?.sessions
                                                      ?.length >= index &&
                                                      formik.errors.sessions[
                                                        index
                                                      ]
                                                        ?.sessionPackagePrice && (
                                                        <span className="text-danger">
                                                          {
                                                            formik.errors
                                                              ?.sessions[index]
                                                              .sessionPackagePrice
                                                          }
                                                        </span>
                                                      )}
                                                  </>
                                                )}
                                            </td>
                                          </tr>
                                          {session.sessionPackageId &&
                                            session.couponSavings && (
                                              <tr>
                                                <td>
                                                  <small>Coupon</small>
                                                </td>
                                                <td className="text-end">
                                                  <>
                                                    <small>
                                                      - ${session.couponSavings}
                                                    </small>
                                                  </>
                                                </td>
                                              </tr>
                                            )}
                                          {settings.chargeStateSalesTax && (
                                            <tr>
                                              <td>
                                                <small>Tax</small>
                                              </td>
                                              <td className="text-end">
                                                {session.sessionPackageId &&
                                                  typeof formik.values
                                                    .locationStateSalexTax ===
                                                    'number' && (
                                                    <>
                                                      <small>
                                                        ${session.salesTax}
                                                      </small>
                                                    </>
                                                  )}
                                                {session.sessionPackageId &&
                                                  !formik.values
                                                    .locationStateSalexTax && (
                                                    <>
                                                      <small>
                                                        Add Location
                                                      </small>
                                                    </>
                                                  )}
                                              </td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td className="table-success">
                                              <small>Total</small>
                                            </td>
                                            <td className="text-end">
                                              {session.sessionPackageId && (
                                                <>
                                                  <small>
                                                    ${session.finalDollarAmount}
                                                  </small>
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </Form.Group>
                                    <Form.Group md={5} className="ml-1">
                                      <Form.Label>Fees</Form.Label>

                                      {!session.newSession &&
                                      session.ogWaiveBookingFee &&
                                      session.ogSeshPackageId ===
                                        session.sessionPackageId &&
                                      session.sessionPackagePrice > 0 ? (
                                        <small className="d-block mb-1">
                                          <Check size={18} className="ml-n1" />
                                          Booking Fee Waived
                                        </small>
                                      ) : (
                                        <Form.Check
                                          className="small"
                                          disabled={
                                            session.finalDollarAmount <= 0 ||
                                            session.cancelled ||
                                            session.noShow ||
                                            session.futureSession?.id ||
                                            session.completed ||
                                            (!session.newSession &&
                                              session.ogSeshPackageId ===
                                                session.sessionPackageId)
                                          }
                                          type="checkbox"
                                          label="Waive Booking Fee"
                                          checked={session.waiveBookingFee}
                                          onChange={(e) =>
                                            handleWaiveBookingFee(
                                              e,
                                              formik,
                                              index
                                            )
                                          }
                                        />
                                      )}

                                      <Form.Check
                                        className="small"
                                        type="checkbox"
                                        disabled={
                                          session.cancelled ||
                                          session.noShow ||
                                          session.futureSession?.id ||
                                          session.completed
                                        }
                                        onChange={(e) =>
                                          handleWaiveNoShowFee(e, index)
                                        }
                                        label={`Waive No Show Fee`}
                                        checked={session.waiveNoShowFee}
                                      />

                                      <Form.Check
                                        className="small"
                                        type="checkbox"
                                        label={`Waive Reschedule Cancel Fee`}
                                        checked={
                                          session.waiveRescheduleCancelFee
                                        }
                                        disabled={
                                          session.cancelled ||
                                          session.noShow ||
                                          session.futureSession?.id ||
                                          session.completed
                                        }
                                        onChange={(e) =>
                                          handleWaiveRescheduleCancel(e, index)
                                        }
                                      />
                                    </Form.Group>
                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    md={
                                      session.organizationId ||
                                      session.subjectId
                                        ? {
                                            span: 5,
                                            offset:
                                              (formik.values &&
                                                displayedSessionLength > 0) ||
                                              formik.values.subjectGroups
                                                .length > 0 ||
                                              formik.values.packageCategories
                                                .length > 0
                                                ? 1
                                                : 0,
                                          }
                                        : {
                                            span: 5,
                                            offset:
                                              (formik.values &&
                                                displayedSessionLength > 0) ||
                                              formik.values.subjectGroups
                                                .length > 0 ||
                                              formik.values.packageCategories
                                                .length > 0
                                                ? 0
                                                : 0,
                                          }
                                    }
                                  >
                                    {session.subjectSession && (
                                      <>
                                        <Form.Label className="d-flex align-items-center">
                                          <span className="mr-2">
                                            Organization
                                          </span>
                                        </Form.Label>
                                        <Form.Control
                                          as="select"
                                          disabled={
                                            session.cancelled ||
                                            session.noShow ||
                                            session.cancellingSession ||
                                            session.noShowingSession ||
                                            session.reschedulingSession ||
                                            session.rescheduled ||
                                            session.resittingSession ||
                                            formik.values.sessions[index]
                                              .subjectGroupId
                                          }
                                          className="form-control-sm form-select mb-3"
                                          value={
                                            formik.values.sessions[index]
                                              .sessionOrgId
                                          }
                                          onChange={(e) =>
                                            handleSessionOrgChange(
                                              e,
                                              index,
                                              session.subjectOrgContacts
                                            )
                                          }
                                        >
                                          <option value="">- - -</option>
                                          {session.subjectOrgId && (
                                            <option
                                              key={session.subjectOrgId}
                                              value={session.subjectOrgId}
                                            >
                                              {session.subjectOrgName}
                                            </option>
                                          )}
                                        </Form.Control>
                                      </>
                                    )}
                                    <Form.Group>
                                      <Form.Label className="d-flex align-items-center">
                                        <span className="mr-2">
                                          Subject Group
                                        </span>
                                      </Form.Label>
                                      <Form.Control
                                        as="select"
                                        disabled={
                                          session.cancelled ||
                                          session.noShow ||
                                          session.cancellingSession ||
                                          session.noShowingSession ||
                                          session.reschedulingSession ||
                                          session.rescheduled ||
                                          session.resittingSession
                                        }
                                        className="form-control-sm form-select"
                                        value={
                                          formik.values.sessions[index]
                                            .subjectGroupId
                                        }
                                        onChange={(e) =>
                                          handleSubjectGroupChange(
                                            e,
                                            index,
                                            session
                                          )
                                        }
                                      >
                                        <option value="">- - -</option>
                                        {session.bookingFlowSubjectGroupName && (
                                          <option
                                            key={bookingFlowSubjectGroupId}
                                            value={bookingFlowSubjectGroupId}
                                          >
                                            {
                                              session.bookingFlowSubjectGroupName
                                            }
                                          </option>
                                        )}
                                        {formik.values.sessions[index]
                                          .subjectGroupsNotBooked &&
                                          formik.values.sessions[
                                            index
                                          ].subjectGroupsNotBooked.map(
                                            (subGroup) => {
                                              if (
                                                !bookingFlowSubjectGroupId ||
                                                subGroup.subjectGroup.id !==
                                                  bookingFlowSubjectGroupId
                                              ) {
                                                return (
                                                  <>
                                                    <option
                                                      key={
                                                        subGroup.subjectGroup.id
                                                      }
                                                      value={
                                                        subGroup.subjectGroup.id
                                                      }
                                                    >
                                                      {
                                                        subGroup.subjectGroup
                                                          .name
                                                      }
                                                    </option>
                                                  </>
                                                )
                                              }
                                            }
                                          )}
                                      </Form.Control>
                                    </Form.Group>
                                    <Form.Group
                                      // as={Col}
                                      md={
                                        session.subjectId ||
                                        session.organizationId
                                          ? {
                                              span: 5,
                                              offset: 0,
                                            }
                                          : 5
                                      }
                                    >
                                      <Form.Label>
                                        <Row>
                                          <Col>Payment Method</Col>
                                        </Row>
                                        {!session.subjectSession && (
                                          <Row>
                                            <Col>
                                              <ReceiptCutoff className="mr-2" />{' '}
                                              Invoice
                                            </Col>
                                          </Row>
                                        )}
                                        {session.subjectId &&
                                        session.sessionOrgId ? (
                                          <>
                                            <Row>
                                              <Col>
                                                <Form.Check
                                                  inline
                                                  disabled={
                                                    session.futureSession ||
                                                    session.cancelled ||
                                                    session.noShow
                                                  }
                                                  label="Charge Subject"
                                                  checked={session.billSubject}
                                                  onChange={(e) =>
                                                    handleBillSubjectChange(
                                                      e,
                                                      index,
                                                      'subject'
                                                    )
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <Form.Check
                                                  inline
                                                  disabled={
                                                    session.futureSession ||
                                                    session.cancelled ||
                                                    session.noShow
                                                  }
                                                  label={`Invoice ${session.subjectOrgName}`}
                                                  checked={!session.billSubject}
                                                  onChange={(e) =>
                                                    handleBillSubjectChange(
                                                      e,
                                                      index,
                                                      'org'
                                                    )
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          </>
                                        ) : null}
                                      </Form.Label>
                                      <div
                                        className={
                                          session.subjectId ||
                                          session.organizationId
                                            ? ''
                                            : 'd-none'
                                        }
                                      >
                                        <>
                                          {session.billSubject ? (
                                            <>
                                              {session
                                                .stripePaymentMethodResource
                                                ?.id ? (
                                                <div className="d-block mt-1">
                                                  {formatCard(
                                                    session.stripePaymentMethodResource
                                                  )}
                                                </div>
                                              ) : (
                                                <>
                                                  <small className="text-secondary mb-1 d-block">
                                                    No Card on File
                                                  </small>
                                                  <>
                                                    {!session.waiveBookingFee && (
                                                      <>
                                                        {!session.waiveBookingFee &&
                                                        session.finalDollarAmount >
                                                          0 ? (
                                                          <div
                                                            className={
                                                              session.waivebookingFee
                                                                ? 'd-flex align-items-center text-secondary small'
                                                                : 'd-flex align-items-center text-danger small'
                                                            }
                                                          >
                                                            <>
                                                              <ExclamationDiamond className="mr-2" />
                                                              <small>
                                                                Session Package
                                                                Fee Requires
                                                                Card
                                                              </small>
                                                            </>
                                                          </div>
                                                        ) : null}
                                                      </>
                                                    )}
                                                    {!session.waiveNoShowFee && (
                                                      <>
                                                        {(session.sessionPackagePrice ===
                                                          0 &&
                                                          session
                                                            .schedulingPolicies
                                                            ?.applyNoShowPolicyFree &&
                                                          session
                                                            .schedulingPolicies
                                                            ?.noShowFee > 0) ||
                                                        (session.sessionPackagePrice >
                                                          0 &&
                                                          session
                                                            .schedulingPolicies
                                                            ?.applyNoShowPolicyPaid &&
                                                          session
                                                            .schedulingPolicies
                                                            ?.noShowFee > 0) ? (
                                                          <div
                                                            className={
                                                              session.waiveNoShowFee
                                                                ? 'd-flex align-items-center text-secondary small'
                                                                : 'd-flex align-items-center text-danger small'
                                                            }
                                                          >
                                                            <ExclamationDiamond className="mr-2" />
                                                            <small>
                                                              No Show Policy
                                                              Requires Card
                                                            </small>
                                                          </div>
                                                        ) : null}
                                                      </>
                                                    )}
                                                    {!session.waiveRescheduleCancelFee && (
                                                      <>
                                                        {(session.sessionPackagePrice ===
                                                          0 &&
                                                          session
                                                            .schedulingPolicies
                                                            ?.applyPolicyFree &&
                                                          session
                                                            .schedulingPolicies
                                                            ?.refundPolicy !==
                                                            'ALWAYS' &&
                                                          session
                                                            .schedulingPolicies
                                                            ?.timeRefundFee >
                                                            0) ||
                                                        (session.sessionPackagePrice >
                                                          0 &&
                                                          session
                                                            .schedulingPolicies
                                                            ?.applyPolicyPaid &&
                                                          session
                                                            .schedulingPolicies
                                                            ?.refundPolicy !==
                                                            'ALWAYS' &&
                                                          session
                                                            .schedulingPolicies
                                                            ?.timeRefundFee >
                                                            0) ? (
                                                          <div
                                                            className={
                                                              session.waiveRescheduleCancelFee
                                                                ? 'd-flex align-items-center text-secondary small'
                                                                : 'd-flex align-items-center text-danger small'
                                                            }
                                                          >
                                                            <ExclamationDiamond className="mr-2" />
                                                            <small>
                                                              Reschedule
                                                              Cancellation
                                                              Policy Requires
                                                              Card
                                                            </small>
                                                          </div>
                                                        ) : null}
                                                      </>
                                                    )}
                                                  </>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      </div>
                                    </Form.Group>
                                  </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                  {jobId &&
                                  !session.newSession &&
                                  !session.completed ? (
                                    <Form.Group as={Col} md={5}>
                                      <div className="bg-light p-3 border-rounded action-bg">
                                        <Form.Label className="font-weight-bold text-secondary mb-1">
                                          Actions
                                        </Form.Label>
                                        {session.reschedulingSession ? (
                                          <div className="d-block d-flex align-items-center">
                                            <button
                                              type="button"
                                              className="small p-0 text-warning btn-link"
                                              onClick={() =>
                                                toggleRescheduleModal(
                                                  index,
                                                  'reschedule'
                                                )
                                              }
                                            >
                                              RESCHEDULING
                                            </button>
                                            <OverlayTrigger
                                              placement="right"
                                              overlay={
                                                <Tooltip className="text-align-left">
                                                  <p>
                                                    {session.rescheduleDetails}
                                                  </p>
                                                </Tooltip>
                                              }
                                            >
                                              <InfoCircle className="ml-1" />
                                            </OverlayTrigger>
                                          </div>
                                        ) : (
                                          <button
                                            type="button"
                                            className="d-block small p-0 btn-link"
                                            disabled={
                                              session.cancelled ||
                                              session.noShow ||
                                              session.futureSession?.id
                                            }
                                            onClick={() =>
                                              toggleRescheduleModal(
                                                index,
                                                'reschedule',
                                                session.sessionPackageId,
                                                session.subjectGroupId
                                              )
                                            }
                                          >
                                            Reschedule to Other Job
                                          </button>
                                        )}

                                        {jobCompleted ? (
                                          <div>
                                            {session.resittingSession ? (
                                              <button
                                                type="button"
                                                className="d-block small p-0 text-warning title-space btn-link"
                                                disabled={
                                                  session.cancelled ||
                                                  session.noShow ||
                                                  session.futureSession?.id
                                                }
                                                onClick={() =>
                                                  toggleRescheduleModal(
                                                    index,
                                                    'resit'
                                                  )
                                                }
                                              >
                                                RESIT SCHEDULED
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                className="d-block small p-0 btn-link"
                                                disabled={
                                                  session.cancelled ||
                                                  session.noShow ||
                                                  session.futureSession?.id
                                                }
                                                onClick={() =>
                                                  toggleRescheduleModal(
                                                    index,
                                                    'resit'
                                                  )
                                                }
                                              >
                                                Resit to Other Job
                                              </button>
                                            )}
                                          </div>
                                        ) : null}
                                        {formik.values.sessions[index]
                                          .cancellingSession ? (
                                          <button
                                            type="button"
                                            disabled={
                                              session.cancelled ||
                                              session.noShow ||
                                              session.futureSession?.id
                                            }
                                            className="d-block small p-0 text-danger title-space btn-link"
                                            onClick={() =>
                                              handleCancelNoShowClick(
                                                formik,
                                                index,
                                                true
                                              )
                                            }
                                          >
                                            CANCELLING
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            disabled={
                                              session.cancelled ||
                                              session.noShow ||
                                              session.futureSession?.id
                                            }
                                            className="d-block small p-0 btn-link"
                                            onClick={() =>
                                              handleCancelNoShowClick(
                                                formik,
                                                index,
                                                true
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                        )}

                                        {formik.values.sessions[index]
                                          .noShowingSession ? (
                                          <button
                                            type="button"
                                            className="d-block small p-0 text-danger title-space btn-link"
                                            onClick={() =>
                                              handleCancelNoShowClick(
                                                formik,
                                                index,
                                                false
                                              )
                                            }
                                          >
                                            NO SHOWING
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            disabled={
                                              session.cancelled ||
                                              session.noShow ||
                                              session.futureSession?.id
                                            }
                                            className="d-block small p-0 btn-link"
                                            onClick={() =>
                                              handleCancelNoShowClick(
                                                formik,
                                                index,
                                                false
                                              )
                                            }
                                          >
                                            No Show
                                          </button>
                                        )}
                                      </div>
                                    </Form.Group>
                                  ) : null}
                                </Form.Row>
                                <Row
                                  className={
                                    displayDetails.includes(index)
                                      ? 'mb-2'
                                      : 'mb-4'
                                  }
                                >
                                  <Col>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        if (displayDetails.includes(index)) {
                                          setDisplayDetails((prevState) => {
                                            const newState = [...prevState]
                                            return newState.filter(
                                              (item) => item != index
                                            )
                                          })
                                        } else {
                                          setDisplayDetails((prevState) => [
                                            ...prevState,
                                            index,
                                          ])
                                        }
                                      }}
                                      className="px-0 btn-link mr-1"
                                    >
                                      <>
                                        {displayDetails.includes(index) ? (
                                          <>
                                            <CaretDown size={17} />
                                          </>
                                        ) : (
                                          <>
                                            <CaretRight size={17} />
                                          </>
                                        )}
                                      </>
                                    </button>
                                    <Form.Label className="mb-0">
                                      Additional Details
                                    </Form.Label>
                                  </Col>
                                </Row>
                                {displayDetails.includes(index) && (
                                  <>
                                    <Form.Row className="mt-2">
                                      {settings.sessionStages && (
                                        <Form.Group as={Col} md={4}>
                                          <Form.Label>
                                            Stage
                                            <span
                                              type="button"
                                              className="p-0 ml-2 btn-link"
                                              onClick={() => {
                                                setShowSessionStageModal(true)
                                              }}
                                            >
                                              <Folder />
                                            </span>
                                          </Form.Label>
                                          <SessionStageSearchInput
                                            formik={formik}
                                            dropdown
                                            formikValue={`sessions[${index}].stage`}
                                            error={formik.errors.stageId}
                                          />
                                        </Form.Group>
                                      )}
                                      <Form.Group
                                        as={Col}
                                        md={settings.sessionStages ? 4 : 6}
                                        className="mb-0"
                                      >
                                        <Form.Label>Photographer</Form.Label>
                                        <Form.Control
                                          as="select"
                                          disabled={uniqueEmployees.length < 1}
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `sessions[${index}].employeeId`,
                                              e.target.value
                                            )
                                          }}
                                          value={
                                            formik.values.sessions[index]
                                              .employeeId
                                          }
                                          className="form-control-sm form-select"
                                        >
                                          <option key="" value="">
                                            - - -
                                          </option>
                                          {uniqueEmployees.map((employee) => {
                                            return (
                                              <option
                                                value={employee.id}
                                                key={employee.id}
                                              >
                                                {employee.name}
                                              </option>
                                            )
                                          })}
                                        </Form.Control>
                                      </Form.Group>
                                      <Form.Group
                                        as={Col}
                                        md={settings.sessionStages ? 4 : 6}
                                        className="mb-0"
                                      >
                                        <Form.Label>Frame Number</Form.Label>
                                        <Field
                                          name={`sessions[${index}].frameNumber`}
                                          type="text"
                                          className="form-control form-control-sm"
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                      <Form.Group
                                        as={Col}
                                        md={12}
                                        className="mb-0"
                                      >
                                        <Form.Label>
                                          Subject Description
                                        </Form.Label>
                                        <Field
                                          name={`sessions[${index}].subjectDescription`}
                                          as="textarea"
                                          rows={1}
                                          className="form-control form-control-sm"
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                      <Form.Group
                                        as={Col}
                                        md={12}
                                        className="mb-0"
                                      >
                                        <Form.Label>Session Notes</Form.Label>
                                        <Field
                                          name={`sessions[${index}].notes`}
                                          as="textarea"
                                          rows={1}
                                          className="form-control form-control-sm"
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-3">
                                      <Form.Group as={Col}>
                                        <Form.Label>Files</Form.Label>
                                      </Form.Group>
                                    </Form.Row>
                                    <Row style={{ marginLeft: '-10px' }}>
                                      <Col md={3}>
                                        <Form.Group>
                                          <Form.Check
                                            name="sharedCanSeeFiles"
                                            type="checkbox"
                                            label="Subjects Can See Files"
                                            disabled={!canMutate}
                                            checked={
                                              formik.values.sessions[index]
                                                .sharedCanSeeFiles
                                            }
                                            onChange={(e) => {
                                              formik?.setFieldValue(
                                                `sessions[${index}].sharedCanSeeFiles`,
                                                e.target.checked
                                              )
                                            }}
                                          />
                                        </Form.Group>
                                      </Col>
                                      {formik.values.sessions[index]
                                        .sharedCanSeeFiles && (
                                        <>
                                          <Col md={3}>
                                            <Form.Group>
                                              <Form.Check
                                                name="sharedCanCreateFiles"
                                                type="checkbox"
                                                label="Subjects Can Create Files"
                                                disabled={!canMutate}
                                                checked={
                                                  formik.values.sessions[index]
                                                    .sharedCanCreateFiles
                                                }
                                                onChange={(e) => {
                                                  formik?.setFieldValue(
                                                    `sessions[${index}].sharedCanCreateFiles`,
                                                    e.target.checked
                                                  )
                                                }}
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col md={4}>
                                            <Form.Group>
                                              <Form.Check
                                                name="sharedCanCreateFolders"
                                                type="checkbox"
                                                label="Subjects Can Create Folders"
                                                disabled={!canMutate}
                                                checked={
                                                  formik.values.sessions[index]
                                                    .sharedCanCreateFolders
                                                }
                                                onChange={(e) => {
                                                  formik?.setFieldValue(
                                                    `sessions[${index}].sharedCanCreateFolders`,
                                                    e.target.checked
                                                  )
                                                }}
                                              />
                                            </Form.Group>
                                          </Col>
                                        </>
                                      )}
                                    </Row>
                                    <Form.Row>
                                      <Form.Group className="mb-3">
                                        <MetaDataTable
                                          btnStyle={{
                                            fontSize: '0.8rem',
                                          }}
                                          metaData={
                                            formik.values.sessions[index]
                                              .metadata
                                          }
                                          setMetaData={setMetaData}
                                          noMessage
                                          edit={canMutate}
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                  </>
                                )}

                                <div
                                  className={
                                    isMultiSessionJob && session.newSession
                                      ? 'd-flex align-items-center hover btn-link'
                                      : 'd-none'
                                  }
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <Trash className="mr-2" />
                                  <small>Remove</small>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )
                      })}
                    </InfiniteScroll>
                  </div>
                )}
              </div>
              {!singleSessionsOnly && (
                <Button
                  variant="link"
                  style={{ marginTop: '-10px' }}
                  className={
                    isMultiSessionJob
                      ? displayedSessionLength > 0
                        ? 'p-0 btn-link mt-2 mb-1'
                        : 'p-0 btn-link mb-1'
                      : 'd-none'
                  }
                  onClick={() => {
                    if (!displaySessions) {
                      setDisplaySessions(true)
                    }
                    newSessionValues.sharedCanSeeFiles =
                      formik.values.sessionSharedCanSeeFiles
                    newSessionValues.sharedCanCreateFiles =
                      formik.values.sessionSharedCanCreateFiles
                    newSessionValues.sharedCanCreateFolders =
                      formik.values.sessionSharedCanCreateFolders
                    arrayHelpers.push(newSessionValues)
                  }}
                >
                  <PlusCircle className="mr-2" />
                  Add Session
                </Button>
              )}
            </Col>
          </Row>
        )}
      />

      {showSessionHistoryModal ? (
        <SessionHistoryModal
          showSessionHistoryModal={showSessionHistoryModal}
          toggleSessionHistoryModal={toggleSessionHistoryModal}
          sessionId={sessionHistoryId}
        />
      ) : null}

      {showRescheduleModal ? (
        <Reschedule
          sessionIndex={rescheduleData.index}
          jobId={jobId}
          sessionPackageId={rescheduleData.sessionPackageId}
          subjectGroupId={rescheduleData.subjectGroupId}
          toggleRescheduleModal={toggleRescheduleModal}
          showRescheduleModal={showRescheduleModal}
          handleSessionTimeClick={handleSessionTimeClick}
          resitClicked={resitClicked}
        />
      ) : null}

      {displayNewOrgModal && (
        <OrganizationModal
          showOrgModal={true}
          orgModalShowChange={setDisplayNewOrgModal}
          isSubmitting={submittingNewOrg}
          setIsSubmitting={setSubmittingNewOrg}
          onComplete={handleOrgCreate}
        />
      )}
      {showReviewSubjectModal ? null : (
        <NewSubjectModal
          displayBilling
          showSubjectModal={displayNewSubModal}
          subjectModalShowChange={handleSubModalChange}
          onCompleted={handleUserCreate}
          updateSubject={false}
        />
      )}

      {reviewSubject.subjectId ? (
        <ReviewSubjectModal
          key={reviewSubject.subjectId}
          subjectId={reviewSubject.subjectId}
          showReviewSubjectModal={showReviewSubjectModal}
          reviewSubjectModalShowChange={handleReviewSubModalChange}
          onCompleted={handleUserReview}
        />
      ) : null}

      {settings.sessionStages && (
        <SessionStage
          showModal={showSessionStageModal}
          toggleMogal={setShowSessionStageModal}
        />
      )}
    </>
  )
}
