import React, { useEffect, useState } from 'react'
import { gql, useMutation, useLazyQuery, useReactiveVar } from '@apollo/client'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import {
  CaretDown,
  CaretRight,
  Download,
  Trash,
  PlusCircle,
  Briefcase,
  CloudArrowDown,
} from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../common/Loading'
import * as Yup from 'yup'
import QrReader from 'react-qr-reader'
import EquipmentJobTable from '../EquipmentJobTable'
import AuditLog from '../../audit_log/AuditLog'
import { loggedInUserVar, settingsVar } from '../../../libs/apollo'
import { useDownloadFile } from '../../../libs/downloadFile'

const CHECK_BAG_NAME_QUERY = gql`
  query CheckBagName($nameIexact: String) {
    equipmentBags(name_Iexact: $nameIexact) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const BagForm = (props) => {
  const { bag, afterSubmit, employeeId, employeeName } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = [
    'Administrator',
    'Scheduling Manager',
    'Scheduling Analyst',
  ].includes(loggedInUser?.permissions?.group)
  const [submitting, setSubmitting] = useState(false)
  const [displayItemsResults, setDisplayItemsResults] = useState(false)
  const [displayBagTypeResults, setDisplayBagTypeResults] = useState(false)
  const [displayEmployeeResults, setDisplayEmployeeResults] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const [showAssignedJobs, setShowAssignedJobs] = useState(false)
  const [showItemsScan, setShowItemsScan] = useState(false)
  const [loading, setLoading] = useState()
  const [itemTypesTableData, setItemTypesTableData] = useState([])
  const [itemTypes, setItemTypes] = useState([])
  const [showQrCode, setShowQrCode] = useState(false)
  const { downloadAndDeleteFile, downloadFile } = useDownloadFile()

  const [
    searchItemTypes,
    { data: itemTypesData, fetchMore: fetchMoreItemTypes },
  ] = useLazyQuery(gql`
    query EquipmentItemTypes(
      $after: String
      $first: Int
      $nameIcontains: String
    ) {
      equipmentItemTypes(
        after: $after
        first: $first
        name_Icontains: $nameIcontains
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
            miscellaneous
          }
        }
      }
    }
  `)

  const [deleteEquipmentBag] = useMutation(
    gql`
      mutation DeleteEquipmentBag(
        $deleteEquipmentBagInput: DeleteEquipmentBagInput!
      ) {
        deleteEquipmentBag(input: $deleteEquipmentBagInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Bag Deleted`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [createEquipmentBag] = useMutation(
    gql`
      mutation CreateEquipmentBag($input: CreateEquipmentBagInput!) {
        createEquipmentBag(input: $input) {
          equipmentBags {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        if (formik.values.count == 1) {
          toast.success(`Bag Saved`)
        } else {
          toast.success(`Bags Saved`)
        }
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [updateEquipmentBag] = useMutation(
    gql`
      mutation UpdateEquipmentBag($input: UpdateEquipmentBagInput!) {
        updateEquipmentBag(input: $input) {
          equipmentBag {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setSubmitting(false)
        toast.success(`Equipment Bag Saved`)
        if (afterSubmit) {
          afterSubmit()
        }
      },
      refetchQueries: [
        'ScanItems',
        'ScanBags',
        'EquipmentBags',
        'EquipmentBagTypes',
        'EquipmentCategories',
        'EquipmentItems',
        'EquipmentItemTypes',
      ],
    }
  )

  const [
    searchEmployees,
    { data: employeesData, fetchMore: fetchMoreEmployees },
  ] = useLazyQuery(gql`
    query EquipmentBagEmployees(
      $after: String
      $first: Int
      $nameIcontains: String
    ) {
      employees(
        after: $after
        first: $first
        gaiaUser_FullName_Icontains: $nameIcontains
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            gaiaUser {
              fullName
            }
          }
        }
      }
    }
  `)

  const [searchBagTypes, { data: bagTypesData, fetchMore: fetchMoreBagTypes }] =
    useLazyQuery(
      gql`
        query EquipmentBagTypes(
          $after: String
          $first: Int
          $nameIcontains: String
        ) {
          equipmentBagTypes(
            after: $after
            first: $first
            name_Icontains: $nameIcontains
          ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                id
                name
                equipmentBagCount
                equipmentItemTypesList {
                  id
                  name
                  miscellaneous
                }
                equipmentItemTypes {
                  edges {
                    node {
                      id
                      name
                      miscellaneous
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      }
    )

  const [searchItems, { data: itemsData, fetchMore: fetchMoreItems }] =
    useLazyQuery(
      gql`
        query BagItemTypeSearch(
          $idsNotIn: String
          $equipmentItemsAfter: String
          $equipmentItemsFirst: Int
          $search: String
        ) {
          equipmentItems(
            idsNotIn: $idsNotIn
            after: $equipmentItemsAfter
            first: $equipmentItemsFirst
            search_Icontains: $search
            retire: false
            employee_Isnull: true
            equipmentBag_Isnull: true
            underRepair: false
          ) {
            edges {
              node {
                id
                name
                serialNumber
                equipmentBag {
                  id
                  name
                }
                equipmentItemType {
                  name
                  id
                  miscellaneous
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  const [searchBagTypeAvailableItems] = useLazyQuery(
    gql`
      query EquipmentBagTypeAvailableItems($equipmentBagTypeId: ID!) {
        equipmentBagTypeAvailableItems(
          equipmentBagTypeId: $equipmentBagTypeId
          onlyRequired: true
        ) {
          edges {
            node {
              id
              name
              serialNumber
              equipmentBag {
                id
                name
              }
              equipmentItemType {
                name
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        let newItemTypes = [...itemTypes]
        const newItems = []
        data.equipmentBagTypeAvailableItems.edges.forEach((edge) => {
          const itemTypeIndex = newItemTypes.findIndex(
            (it) => it.id === edge.node.equipmentItemType?.id
          )
          if (
            itemTypeIndex !== -1 &&
            newItemTypes[itemTypeIndex].required >
              newItemTypes[itemTypeIndex].includes
          ) {
            newItemTypes[itemTypeIndex].includes += 1
            const node = edge.node
            if (
              newItemTypes[itemTypeIndex].includes ==
              newItemTypes[itemTypeIndex].required
            ) {
              newItemTypes[itemTypeIndex].missingItems = false
            }
            newItems.push({
              id: node.id,
              name: node.name,
              serialNumber: node.serialNumber,
              equipmentItemTypeName: node.equipmentItemType?.name,
              equipmentItemTypeId: node.equipmentItemType?.id,
              equipmentItemTypeMiscellaneous:
                node.equipmentItemType?.miscellaneous,
            })
          }
        })
        if (newItems.length > 0) {
          formik.setFieldValue('items', [...formik.values.items, ...newItems])
        }
        setItemTypes(newItemTypes)
        toast.success('Bag Autofilled')
      },
    }
  )

  const [checkBagName] = useLazyQuery(CHECK_BAG_NAME_QUERY, {
    fetchPolicy: 'no-cache',
  })

  const formik = useFormik({
    initialValues: bag
      ? {
          name: bag.name,
          notes: bag.notes,
          categoryName: bag.equipmentCategory ? bag.equipmentCategory.name : '',
          categoryId: bag.equipmentCategory ? bag.equipmentCategory.id : null,
          bagTypeName: bag.equipmentBagType ? bag.equipmentBagType.name : '',
          bagTypeId: bag.equipmentBagType ? bag.equipmentBagType.id : null,
          returned: bag.returned,
          returnedAndUnassign: false,
          employeeName: bag.employee ? bag.employee.gaiaUser.fullName : '',
          employeeId: bag.employee ? bag.employee.id : null,
          bagTypeItems: bag.equipmentBagType?.equipmentItemTypesList
            ? bag.equipmentBagType.equipmentItemTypesList.map((edge) => {
                return {
                  id: edge.id,
                  name: edge.name,
                  miscellaneous: edge.miscellaneous,
                }
              })
            : [],
          includedBagTypeItems: [],
          newEquipmentItems: [],
          items: bag.equipmentItems.edges
            ? bag.equipmentItems.edges.map((edge) => {
                const { node } = edge
                return {
                  id: node.id,
                  name: node.name,
                  serialNumber: node.serialNumber,
                  // TODO: get these values from backend for invoice , price and vendor
                  itemType: node.equipmentItemType?.name,
                  itemTypeId: node.equipmentItemType?.id,
                  equipmentItemTypeName: node.equipmentItemType?.name,
                  equipmentItemTypeId: node.equipmentItemType?.id,
                  category: node.equipmentCategory?.name,
                }
              })
            : [],
        }
      : {
          name: '',
          notes: '',
          categoryName: '',
          categoryId: null,
          returned: true,
          returnedAndUnassign: false,
          bagTypeName: '',
          bagTypeId: null,
          employeeName: employeeName ? employeeName : '',
          employeeId: employeeId ? employeeId : null,
          items: [],
          bagTypeItems: [],
          includedBagTypeItems: [],
          newEquipmentItems: [],
          count: 1,
        },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('required')
        .test('unique', 'Bag Name Must Be Unique', async function (value) {
          if (!value) return true

          const { data } = await checkBagName({
            variables: {
              nameIexact: value,
            },
          })

          const existingBags = data?.equipmentBags.edges
          if (existingBags && existingBags.length > 0) {
            if (bag) {
              if (
                existingBags.length === 1 &&
                existingBags[0].node.id === bag.id
              ) {
                return true
              }
            }
            return false
          }

          return true
        }),
      newEquipmentItems: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('required'),
          serialNumber: Yup.string().nullable(),
          vendor: Yup.string().nullable(),
          invoiceNumber: Yup.string().nullable(),
          price: Yup.string().nullable(),
          notes: Yup.string().nullable(),
          itemTypeName: Yup.string().nullable(),
          returned: Yup.boolean().nullable(),
          returnedAndUnassign: Yup.boolean().nullable(),
          itemTypeId: Yup.string().nullable(),
          displayItemTypeResults: Yup.boolean().nullable(),
        })
      ),
      notes: Yup.string().nullable(),
      categoryName: Yup.string().nullable(),
      categoryId: Yup.string().nullable(),
      employeeName: Yup.string().nullable(),
      employeeId: Yup.string().nullable(),
      bagTypeName: Yup.string().nullable(),
      returned: Yup.boolean().nullable(),
      count: Yup.number()
        .nullable()
        .min(1, 'Count must be at least 1')
        .max(1000, 'Count cannot exceed 100'),
      bagTypeId: Yup.string().nullable(),
      items: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          name: Yup.string().nullable(),
          serialNumber: Yup.string().nullable(),
          itemType: Yup.string().nullable(),
          equipmentItemTypeName: Yup.string().nullable(),
          equipmentItemTypeId: Yup.string().nullable(),
          itemTypeId: Yup.string().nullable(),
          category: Yup.string().nullable(),
        })
      ),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setSubmitting(true)
      if (bag) {
        updateEquipmentBag({
          variables: {
            input: {
              equipmentBagInput: {
                id: bag.id,
                name: values.name,
                notes: values.notes,
                equipmentCategoryId: values.categoryId,
                equipmentItemIds: values.items.map((item) => item.id),
                equipmentBagTypeId: values.bagTypeId,
                returned: values.returned,
                returnedAndUnassign: values.returnedAndUnassign,
                employeeId: values.employeeId,
                newEquipmentItems: values.newEquipmentItems.map(
                  (newEquipmentItem) => ({
                    equipmentItemTypeId: newEquipmentItem.itemTypeId,
                    serialNumber: newEquipmentItem.serialNumber,
                    name: newEquipmentItem.name,
                    price: Number(newEquipmentItem.price),
                    invoiceNumber: newEquipmentItem.invoiceNumber,
                    vendor: newEquipmentItem.vendor,
                  })
                ),
              },
            },
          },
        })
      } else {
        createEquipmentBag({
          variables: {
            input: {
              equipmentBagInput: {
                name: values.name,
                equipmentCategoryId: values.categoryId,
                equipmentItemIds: values.items.map((item) => item.id),
                equipmentBagTypeId: values.bagTypeId,
                returned: values.returned,
                employeeId: values.employeeId,
                notes: values.notes,
                count: values.count,
                newEquipmentItems: values.newEquipmentItems.map(
                  (newEquipmentItem) => ({
                    equipmentItemTypeId: newEquipmentItem.itemTypeId,
                    serialNumber: newEquipmentItem.serialNumber,
                    name: newEquipmentItem.name,
                    price: Number(newEquipmentItem.price),
                    invoiceNumber: newEquipmentItem.invoiceNumber,
                    vendor: newEquipmentItem.vendor,
                  })
                ),
              },
            },
          },
        })
      }
    },
  })

  const [searchScanedItem] = useLazyQuery(
    gql`
      query SearchScannedEquipmentItem($equipmentItemId: [ID]) {
        equipmentItems(
          id: $equipmentItemId
          retire: false
          employee_Isnull: true
          equipmentBag_Isnull: true
          underRepair: false
        ) {
          edges {
            node {
              id
              name
              serialNumber
              equipmentBag {
                name
                id
              }
              equipmentCategory {
                name
                id
              }
              equipmentItemType {
                name
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        if (data.equipmentItems.edges) {
          const node = data?.equipmentItems.edges[0].node
          if (
            formik.values.items?.filter((item) => item.id === node?.id)
              .length === 0
          ) {
            formik.setFieldValue('items', [
              ...formik.values.items,
              {
                id: node.id,
                name: node.name,
                serialNumber: node.serialNumber,
                category: node.equipmentCategory?.name,
                itemType: node.equipmentItemType?.name,
                itemTypeId: node.equipmentItemType?.id,
                equipmentItemTypeName: node.equipmentItemType?.name,
                equipmentItemTypeId: node.equipmentItemType?.id,
                node: node,
              },
            ])
          }
        }
      },
    }
  )

  const handleDelete = () => {
    setSubmitting(true)
    deleteEquipmentBag({
      variables: {
        deleteEquipmentBagInput: {
          equipmentBagIds: bag.id,
        },
      },
    })
  }

  const [downloadPdf] = useMutation(
    gql`
      mutation DownloadEquipmentBag($input: DownloadEquipmentBagInput!) {
        downloadEquipmentBag(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        setLoading(false)
        if (data?.downloadEquipmentBag?.file?.fileName) {
          downloadAndDeleteFile(
            data.downloadEquipmentBag.file.fileName,
            data.downloadEquipmentBag.file.displayName,
            data.downloadEquipmentBag.file.id,
            () => {
              toast.success(`Bag Downloaded`)
            }
          )
        }
      },
      errorPolicy: 'all',
    }
  )

  const handleEmployeeBlur = () => {
    setDisplayEmployeeResults(false)
    formik.setFieldTouched(`employeeId`, true)
  }

  const handleEmployeeChange = (e) => {
    if (!e.target.value) {
      setDisplayEmployeeResults(false)
    } else {
      setDisplayEmployeeResults(true)
    }
    formik.setFieldValue(`employeeName`, e.target.value)
    searchEmployees({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleEmployeeXClick = () => {
    formik.setFieldValue(`employeeName`, '')
    formik.setFieldValue(`employeeId`, '')
  }

  const handleEmployeeClick = (node) => {
    setDisplayEmployeeResults(false)
    formik.setFieldValue(`employeeName`, node.gaiaUser.fullName)
    formik.setFieldValue(`employeeId`, node.id)
  }

  const handleBagTypeBlur = () => {
    setDisplayBagTypeResults(false)
    formik.setFieldTouched(`bagTypeId`, true)
  }

  const handleBagTypeChange = (e) => {
    if (!e.target.value) {
      setDisplayBagTypeResults(false)
    } else {
      setDisplayBagTypeResults(true)
    }
    formik.setFieldValue(`bagTypeName`, e.target.value)
    searchBagTypes({
      variables: {
        after: null,
        first: 250,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleBagTypeXClick = () => {
    formik.setFieldValue(`bagTypeName`, '')
    formik.setFieldValue(`bagTypeId`, '')
  }

  const handleBagTypeClick = (node) => {
    setDisplayBagTypeResults(false)
    formik.setFieldValue(
      'bagTypeItems',
      node.equipmentItemTypesList.map((edge) => edge)
    )
    formik.setFieldValue('name', `${node.name} ${node.equipmentBagCount + 1}`)
    formik.setFieldValue(`bagTypeName`, node.name)
    formik.setFieldValue(`bagTypeId`, node.id)
  }

  const handleItemChange = (e) => {
    if (!e.target.value) {
      setDisplayItemsResults(false)
    } else {
      setDisplayItemsResults(true)
    }
    formik.setFieldValue(`itemName`, e.target.value)
    searchItems({
      variables: {
        after: null,
        first: 250,
        idsNotIn: formik.values.items.map((item) => item.id).join(','),
        search: e.target.value,
      },
    })
  }

  const handleItemClick = (node) => {
    setDisplayItemsResults(false)
    formik.setFieldValue('items', [
      ...formik.values.items,
      {
        id: node.id,
        name: node.name,
        serialNumber: node.serialNumber,
        equipmentItemTypeName: node.equipmentItemType?.name,
        equipmentItemTypeMiscellaneous: node.equipmentItemType?.miscellaneous,
        equipmentItemTypeId: node.equipmentItemType?.id,
      },
    ])
  }

  const deleteItemClick = (id) => {
    if (formik.values.items.find((item) => item.id === id)) {
      formik.setFieldValue(
        'items',
        formik.values.items.filter((item) => item.id !== id)
      )
    }
  }

  const handleScan = (value) => {
    if (value) {
      searchScanedItem({
        variables: {
          equipmentItemId: value,
        },
      })
    }
  }

  useEffect(() => {
    const updatedItemTypes = formik.values.bagTypeItems.map((item) => ({
      ...item,
      included: formik.values.items.find(
        (formItem) => formItem.equipmentItemTypeId === item.id
      )
        ? 'Yes'
        : 'No',
    }))

    if (formik.values.bagTypeId && formik.values.bagTypeItems) {
      setItemTypesTableData(updatedItemTypes)
    }
  }, [formik.values])

  useEffect(() => {
    const allItems = formik.values.bagTypeItems.map((edge) => {
      return {
        id: edge.id,
        name: edge.name,
        miscellaneous: edge.miscellaneous,
      }
    })

    let itemsCount = formik.values.items.reduce((counts, item) => {
      counts[item.equipmentItemTypeId] =
        (counts[item.equipmentItemTypeId] || 0) + 1
      return counts
    }, {})

    setItemTypes(() => {
      const newItemTypes = allItems.reduce((unique, item) => {
        const index = unique.findIndex(
          (uniqueItem) => uniqueItem.id === item.id
        )
        let includes = item.miscellaneous ? '' : itemsCount[item.id] || 0
        if (!item.miscellaneous) {
          formik.values.newEquipmentItems.forEach((newItem) => {
            if (newItem.itemTypeId == item.id) {
              includes += 1
            }
          })
        }
        let missingItems = true
        if (index !== -1) {
          missingItems = unique[index].required + 1 > includes
          if (item.miscellaneous) {
            missingItems = false
          }
          unique[index] = {
            ...item,
            required: unique[index].required + 1,
            includes,
            missingItems,
          }
          return unique
        } else {
          missingItems = 1 > includes
          if (item.miscellaneous) {
            missingItems = false
          }
          return [...unique, { ...item, required: 1, includes, missingItems }]
        }
      }, [])
      return newItemTypes
    })
  }, [
    formik.values.bagTypeItems,
    formik.values.items,
    formik.values.newEquipmentItems,
  ])

  const addNewEquipmentItem = () => {
    formik.setFieldValue('newEquipmentItems', [
      ...formik.values.newEquipmentItems,
      {
        name: '',
        serialNumber: '',
        vendor: '',
        invoiceNumber: '',
        price: '',
        notes: '',
        itemTypeName: '',
        itemTypeMiscellaneous: null,
        returned: true,
        displayItemTypeResults: false,
        itemTypeId: '',
      },
    ])
  }

  const removeNewEquipmentItem = (index) => {
    const newArray = [...formik.values.newEquipmentItems]
    newArray.splice(index, 1)
    formik.setFieldValue('newEquipmentItems', newArray)
  }
  const handleItemTypeChange = (index, e) => {
    if (!e.target.value) {
      formik.setFieldValue(
        `newEquipmentItems[${index}].displayItemTypeResults`,
        false
      )
    } else {
      formik.setFieldValue(
        `newEquipmentItems[${index}].displayItemTypeResults`,
        true
      )
    }
    formik.setFieldValue(
      `newEquipmentItems[${index}].itemTypeName`,
      e.target.value
    )
    searchItemTypes({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleItemTypeBlur = (index) => {
    formik.setFieldTouched(
      `newEquipmentItems[${index}].displayItemTypeResults`,
      false
    )
    formik.setFieldTouched(`newEquipmentItems[${index}].itemTypeId`, true)
  }

  const handleItemTypeClick = (index, node) => {
    formik.setFieldValue(
      `newEquipmentItems[${index}].displayItemTypeResults`,
      false
    )
    formik.setFieldValue(`newEquipmentItems[${index}].itemTypeId`, node.id)
    formik.setFieldValue(`newEquipmentItems[${index}].itemTypeName`, node.name)
    formik.setFieldValue(
      `newEquipmentItems[${index}].itemTypeMiscellaneous`,
      node.miscellaneous
    )
  }

  const handleItemTypeXClick = (index) => {
    formik.setFieldValue(`newEquipmentItems[${index}].itemTypeName`, '')
    formik.setFieldValue(`newEquipmentItems[${index}].itemTypeId`, '')
    formik.setFieldValue(
      `newEquipmentItems[${index}].itemTypeMiscellaneous`,
      null
    )
  }

  let addNewItemClass = 'p-0 btn-link'
  if (formik.values.newEquipmentItems?.length > 0) {
    addNewItemClass = 'mt-2 p-0 btn-link'
  }

  const handleAutofillClick = () => {
    searchBagTypeAvailableItems({
      variables: {
        equipmentBagTypeId: formik.values.bagTypeId,
      },
    })
  }

  return (
    <>
      <div id="equipmentBagTypeForm">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Name
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                name="name"
                disabled={!canMutate}
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {!bag && !employeeId && (
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Number of Bags to Create
                </Form.Label>
              </Col>
              <Col sm="12" md={6}>
                <Form.Control
                  name="count"
                  type="number"
                  value={formik.values.count}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.count}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.count}
                </Form.Control.Feedback>
                {formik.values.count && formik.values.count > 1 && (
                  <small className="mt-2">
                    Items Will Only Be Associated To The First Bag
                  </small>
                )}
              </Col>
            </Form.Group>
          )}
          {canMutate && (
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Bag Type
                </Form.Label>
              </Col>
              <Col sm="12" md={6}>
                <Form.Control
                  placeholder="search"
                  value={formik.values.bagTypeName}
                  onBlur={handleBagTypeBlur}
                  onChange={(e) => handleBagTypeChange(e)}
                  readOnly={Boolean(formik.values.bagTypeId)}
                  className={
                    formik.values.bagTypeId
                      ? ' border border-success form-control form-control-sm'
                      : 'form-control form-control-sm'
                  }
                />
                {bagTypesData && displayBagTypeResults && (
                  <InfiniteScroll
                    height={100}
                    dataLength={bagTypesData.equipmentBagTypes.edges.length}
                    next={() => {
                      fetchMoreBagTypes({
                        variables: {
                          after:
                            bagTypesData.equipmentBagTypes.pageInfo.endCursor,
                          first: 250,
                          nameIcontains: formik.values.bagTypeName,
                        },
                      })
                    }}
                    hasMore={
                      bagTypesData?.equipmentBagTypes.pageInfo.hasNextPage
                    }
                    loader={<Loading />}
                  >
                    <Table size="sm" hover>
                      <tbody>
                        {bagTypesData.equipmentBagTypes.edges.map((bagType) => {
                          const { node } = bagType
                          return (
                            <tr
                              onMouseDown={() => handleBagTypeClick(node)}
                              key={node.id}
                              className="hover text-decoration-none"
                            >
                              <td>
                                <small>{node.name}</small>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                )}
              </Col>
              {formik.values.bagTypeId && (
                <Col>
                  <button
                    type="button"
                    className="p-0 mr-1 btn-link"
                    onClick={handleBagTypeXClick}
                  >
                    <Trash />
                  </button>
                </Col>
              )}
            </Form.Group>
          )}

          {formik.values.bagTypeId &&
            formik.values.bagTypeItems &&
            canMutate &&
            itemTypesTableData && (
              <>
                <Form.Group as={Row}>
                  <Col md={3}>
                    <Form.Label column sm="12" md="auto">
                      Item Types
                    </Form.Label>
                  </Col>
                  <Col sm="12" md={6}>
                    <SortableInfiniteTable
                      tableData={itemTypes}
                      trStyleGenerator={(row) => {
                        let style = {
                          backgroundColor: 'rgba(192,255,192,0.5)',
                          cursor: 'pointer',
                        }
                        if (row.original.missingItems) {
                          style.backgroundColor = 'rgb(255,192,192)'
                        }
                        return style
                      }}
                      tableColumns={[
                        {
                          Header: 'Item Type',
                          id: 'name',
                          accessor: (row) => (
                            <>
                              {row.name}
                              {row.miscellaneous && (
                                <>
                                  <span className="ml-1">(misc)</span>
                                </>
                              )}
                            </>
                          ),
                        },
                        {
                          Header: 'Requires',
                          id: 'required',
                          accessor: 'required',
                        },
                        {
                          Header: 'Includes',
                          id: 'includes',
                          accessor: 'includes',
                        },
                      ]}
                      hasMoreTableData={false}
                      tableHeight={itemTypes?.length < 10 ? 200 : 300}
                      hideGlobalFilter={false}
                      hideGlobalFilterRecordCount={true}
                    />
                  </Col>
                  {formik.values.bagTypeId &&
                    itemTypes.some((item) => item.missingItems === true) && (
                      <Col>
                        <button
                          type="button"
                          className="p-0 mr-1 btn-link"
                          onClick={handleAutofillClick}
                          style={{ marginLeft: ' -1px' }}
                        >
                          <Briefcase size={18} />
                          <small className="ml-1">Autofill</small>
                        </button>
                      </Col>
                    )}
                </Form.Group>
              </>
            )}

          {canMutate && (
            <>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Search Existing Items
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    placeholder="search"
                    value={formik.values.itemName}
                    onChange={(e) => handleItemChange(e)}
                    className={'form-control form-control-sm'}
                  />
                  {itemsData && displayItemsResults && (
                    <InfiniteScroll
                      height={100}
                      dataLength={itemsData.equipmentItems.edges.length}
                      next={() => {
                        fetchMoreItems({
                          variables: {
                            after: itemsData.equipmentItems.pageInfo.endCursor,
                            first: 250,
                            search: formik.values.itemName,
                            idsNotIn: formik.values.items
                              .map((item) => item.id)
                              .join(','),
                          },
                        })
                      }}
                      hasMore={itemsData?.equipmentItems.pageInfo.hasNextPage}
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {itemsData.equipmentItems.edges.map((org) => {
                            const { node } = org
                            return (
                              <tr
                                onMouseDown={() => handleItemClick(node)}
                                key={node.id}
                                className="hover text-decoration-none"
                              >
                                <td>
                                  <small>
                                    {node.name}{' '}
                                    {node.serialNumber && (
                                      <> #{node.serialNumber}</>
                                    )}{' '}
                                    {node.equipmentItemType && (
                                      <>| {node.equipmentItemType.name}</>
                                    )}{' '}
                                    {node.equipmentCategory && (
                                      <>
                                        | Category {node.equipmentCategory.name}
                                      </>
                                    )}
                                  </small>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col md={3} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setShowItemsScan(!showItemsScan)}
                    className="px-0 btn-link"
                  >
                    <>
                      {showItemsScan ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    Scan Existing Items
                  </Form.Label>
                </Col>
              </Form.Group>
            </>
          )}
          {showItemsScan && (
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <QrReader
                  delay={300}
                  onScan={handleScan}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          )}

          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                {canMutate ? <>Existing Items in Bag</> : <>Items</>}
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              {formik.values.items.length > 0 && (
                <>
                  {formik.values.items?.map((item, i) => (
                    <span
                      style={{
                        padding: '5px',
                        border: '.5px solid #6c757d',
                        borderRadius: '0.25rem',
                        marginLeft: i > 0 ? '0.5rem' : '0',
                      }}
                      key={item.id}
                    >
                      {canMutate && (
                        <Trash
                          className="btn-link mr-2"
                          onClick={() => {
                            deleteItemClick(item.id)
                          }}
                        />
                      )}

                      <span style={{ fontSize: '14px' }}>
                        {item.name}{' '}
                        {item.serialNumber && <> #{item.serialNumber}</>}
                        {item.equipmentItemTypeName && (
                          <>
                            {' '}
                            | {item.equipmentItemTypeName}{' '}
                            {item.equipmentItemTypeMiscellaneous && (
                              <span className="ml-1">(misc)</span>
                            )}
                          </>
                        )}
                      </span>
                    </span>
                  ))}
                </>
              )}
              {formik.values.items.length === 0 && <>- - -</>}
            </Col>
          </Form.Group>
          {canMutate && (
            <>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Create New Items
                  </Form.Label>
                </Col>
                <Col md={6}>
                  {formik.values.newEquipmentItems.length > 0 && (
                    <>
                      {formik.values.newEquipmentItems.map(
                        (newEquipmentItem, index) => {
                          return (
                            <>
                              <div className="border border-secondary rounded p-2">
                                <Form.Group as={Row} className="mt-2">
                                  <Col md={3}>
                                    <Form.Label column sm="12" md="auto">
                                      Name
                                    </Form.Label>
                                  </Col>
                                  <Col sm="12" md={9}>
                                    <Form.Control
                                      name={`newEquipmentItems[${index}].name`}
                                      value={newEquipmentItem.name}
                                      onChange={formik.handleChange}
                                      isInvalid={
                                        formik.errors?.newEquipmentItems &&
                                        formik.errors.newEquipmentItems[index]
                                          ?.name
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.errors?.newEquipmentItems &&
                                        formik.errors.newEquipmentItems[index]
                                          ?.name && (
                                          <>
                                            {
                                              formik.errors.newEquipmentItems[
                                                index
                                              ].name
                                            }
                                          </>
                                        )}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                  <Col md={3}>
                                    <Form.Label column sm="12" md="auto">
                                      Serial Number
                                    </Form.Label>
                                  </Col>
                                  <Col sm="12" md={9}>
                                    <Form.Control
                                      name={`newEquipmentItems[${index}].serialNumber`}
                                      value={newEquipmentItem.serialNumber}
                                      onChange={formik.handleChange}
                                      isInvalid={
                                        formik.errors?.newEquipmentItems &&
                                        formik.errors.newEquipmentItems[index]
                                          ?.serialNumber
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.errors?.newEquipmentItems &&
                                        formik.errors.newEquipmentItems[index]
                                          ?.serialNumber && (
                                          <>
                                            {
                                              formik.errors.newEquipmentItems[
                                                index
                                              ].serialNumber
                                            }
                                          </>
                                        )}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                  <Col md={3}>
                                    <Form.Label column sm="12" md="auto">
                                      Vendor
                                    </Form.Label>
                                  </Col>
                                  <Col sm="12" md={9}>
                                    <Form.Control
                                      name={`newEquipmentItems[${index}].vendor`}
                                      value={newEquipmentItem.vendor}
                                      onChange={formik.handleChange}
                                      isInvalid={
                                        formik.errors?.newEquipmentItems &&
                                        formik.errors.newEquipmentItems[index]
                                          ?.vendor
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.errors?.newEquipmentItems &&
                                        formik.errors.newEquipmentItems[index]
                                          ?.vendor && (
                                          <>
                                            {
                                              formik.errors.newEquipmentItems[
                                                index
                                              ].vendor
                                            }
                                          </>
                                        )}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                  <Col md={3}>
                                    <Form.Label column sm="12" md="auto">
                                      Invoice Number
                                    </Form.Label>
                                  </Col>
                                  <Col sm="12" md={9}>
                                    <Form.Control
                                      name={`newEquipmentItems[${index}].invoiceNumber`}
                                      value={newEquipmentItem.invoiceNumber}
                                      onChange={formik.handleChange}
                                      isInvalid={
                                        formik.errors?.newEquipmentItems &&
                                        formik.errors.newEquipmentItems[index]
                                          ?.invoiceNumber
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.errors?.newEquipmentItems &&
                                        formik.errors.newEquipmentItems[index]
                                          ?.invoiceNumber && (
                                          <>
                                            {
                                              formik.errors.newEquipmentItems[
                                                index
                                              ].invoiceNumber
                                            }
                                          </>
                                        )}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                  <Col md={3}>
                                    <Form.Label column sm="12" md="auto">
                                      Price
                                    </Form.Label>
                                  </Col>
                                  <Col sm="12" md={9}>
                                    <Form.Control
                                      name={`newEquipmentItems[${index}].price`}
                                      value={newEquipmentItem.price}
                                      onChange={formik.handleChange}
                                      isInvalid={
                                        formik.errors?.newEquipmentItems &&
                                        formik.errors.newEquipmentItems[index]
                                          ?.price
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.errors?.newEquipmentItems &&
                                        formik.errors.newEquipmentItems[index]
                                          ?.price && (
                                          <>
                                            {
                                              formik.errors.newEquipmentItems[
                                                index
                                              ].price
                                            }
                                          </>
                                        )}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                  <Col md={3}>
                                    <Form.Label column sm="12" md="auto">
                                      Item Type
                                    </Form.Label>
                                  </Col>
                                  <Col sm="12" md={7}>
                                    <Form.Control
                                      placeholder="search"
                                      value={
                                        formik.values.newEquipmentItems[index]
                                          .itemTypeName
                                      }
                                      onBlur={() => handleItemTypeBlur(index)}
                                      onChange={(e) =>
                                        handleItemTypeChange(index, e)
                                      }
                                      readOnly={Boolean(
                                        formik.values.newEquipmentItems[index]
                                          .itemTypeId
                                      )}
                                      className={
                                        formik.values.newEquipmentItems[index]
                                          .itemTypeId
                                          ? ' border border-success form-control form-control-sm'
                                          : 'form-control form-control-sm'
                                      }
                                    />
                                    {itemTypesData &&
                                      formik.values.newEquipmentItems[index]
                                        .displayItemTypeResults && (
                                        <InfiniteScroll
                                          height={100}
                                          dataLength={
                                            itemTypesData.equipmentItemTypes
                                              .edges.length
                                          }
                                          next={() => {
                                            fetchMoreItemTypes({
                                              variables: {
                                                after:
                                                  itemTypesData
                                                    .equipmentItemTypes.pageInfo
                                                    .endCursor,
                                                first: 10,
                                                nameIcontains:
                                                  formik.values
                                                    .newEquipmentItems[index]
                                                    .itemTypeName,
                                              },
                                            })
                                          }}
                                          hasMore={
                                            itemTypesData?.equipmentItemTypes
                                              .pageInfo.hasNextPage
                                          }
                                          loader={<Loading />}
                                        >
                                          <Table size="sm" hover>
                                            <tbody>
                                              {itemTypesData.equipmentItemTypes.edges.map(
                                                (itemType) => {
                                                  const { node } = itemType
                                                  return (
                                                    <tr
                                                      onMouseDown={() =>
                                                        handleItemTypeClick(
                                                          index,
                                                          node
                                                        )
                                                      }
                                                      key={node.id}
                                                      className="hover text-decoration-none"
                                                    >
                                                      <td>
                                                        <small>
                                                          {node.name}{' '}
                                                          {node.miscellaneous && (
                                                            <span className="ml-1">
                                                              (misc)
                                                            </span>
                                                          )}
                                                        </small>
                                                      </td>
                                                    </tr>
                                                  )
                                                }
                                              )}
                                            </tbody>
                                          </Table>
                                        </InfiniteScroll>
                                      )}
                                  </Col>
                                  <Col>
                                    {formik.values.newEquipmentItems[index]
                                      .itemTypeId && (
                                      <button
                                        type="button"
                                        className="p-0 mr-1 btn-link"
                                        onClick={() =>
                                          handleItemTypeXClick(index)
                                        }
                                      >
                                        <Trash />
                                      </button>
                                    )}
                                  </Col>
                                </Form.Group>
                                <div
                                  className="d-inline-flex align-items-center btn-link hover ml-3"
                                  onClick={() => removeNewEquipmentItem(index)}
                                  role="presentation"
                                  onKeyDown={() =>
                                    removeNewEquipmentItem(index)
                                  }
                                >
                                  <Trash className="mr-1" />
                                  <small>Remove Item</small>
                                </div>
                              </div>
                            </>
                          )
                        }
                      )}
                    </>
                  )}
                  {formik.values.newEquipmentItems.length == 0 && (
                    <button
                      type="button"
                      className={addNewItemClass}
                      onClick={addNewEquipmentItem}
                    >
                      <PlusCircle className="mr-2" />
                      Create Item
                    </button>
                  )}
                </Col>
              </Form.Group>
              {formik.values.newEquipmentItems.length > 0 && (
                <Row className="mb-3">
                  <Col md={{ span: 6, offset: 3 }}>
                    <button
                      type="button"
                      className={addNewItemClass}
                      onClick={addNewEquipmentItem}
                    >
                      <PlusCircle className="mr-2" />
                      Create Item
                    </button>
                  </Col>
                </Row>
              )}
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Long Term Employee Assignment
                  </Form.Label>
                </Col>
                <Col sm="12" md={6}>
                  <Form.Control
                    placeholder="search"
                    value={formik.values.employeeName}
                    onBlur={handleEmployeeBlur}
                    onChange={(e) => handleEmployeeChange(e)}
                    readOnly={Boolean(formik.values.employeeId)}
                    className={
                      formik.values.employeeId
                        ? ' border border-success form-control form-control-sm'
                        : 'form-control form-control-sm'
                    }
                  />
                  {employeesData && displayEmployeeResults && (
                    <InfiniteScroll
                      height={100}
                      dataLength={employeesData.employees.edges.length}
                      next={() => {
                        fetchMoreEmployees({
                          variables: {
                            after: employeesData.employees.pageInfo.endCursor,
                            first: 10,
                            nameIcontains: formik.values.employeeName,
                          },
                        })
                      }}
                      hasMore={employeesData?.employees.pageInfo.hasNextPage}
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {employeesData.employees.edges.map((org) => {
                            const { node } = org
                            return (
                              <tr
                                onMouseDown={() => handleEmployeeClick(node)}
                                key={node.id}
                                className="hover text-decoration-none"
                              >
                                <td>
                                  <small>{node.gaiaUser.fullName}</small>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  )}
                </Col>
                {!employeeId && canMutate && (
                  <Col>
                    {formik.values.employeeId && (
                      <button
                        type="button"
                        className="p-0 mr-1 btn-link btn-link"
                        onClick={handleEmployeeXClick}
                      >
                        <Trash />
                      </button>
                    )}
                  </Col>
                )}
              </Form.Group>
            </>
          )}

          {(!bag || !formik.values.returnedAndUnassign) && (
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Checked In
                </Form.Label>
              </Col>
              <Col sm="12" md={6}>
                <Form.Check
                  type="checkbox"
                  name="returned"
                  disabled={!canMutate}
                  checked={formik.values.returned}
                  onChange={() => {
                    formik.setFieldValue('returned', !formik.values.returned)
                  }}
                  isInvalid={formik.errors.returned}
                  feedback={formik.errors.returned}
                />
              </Col>
            </Form.Group>
          )}
          {bag && !bag.returned && (
            <Form.Group as={Row}>
              <Col md={3}>
                <Form.Label column sm="12" md="auto">
                  Checked In and Unassigned
                </Form.Label>
              </Col>
              <Col sm="12" md={6}>
                <Form.Check
                  type="checkbox"
                  name="returnedAndUnassign"
                  disabled={!canMutate}
                  checked={formik.values.returnedAndUnassign}
                  onChange={() => {
                    formik.setFieldValue(
                      'returnedAndUnassign',
                      !formik.values.returnedAndUnassign
                    )
                  }}
                  isInvalid={formik.errors.returnedAndUnassign}
                  feedback={formik.errors.returnedAndUnassign}
                />
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Col md={3}>
              <Form.Label column sm="12" md="auto">
                Notes
              </Form.Label>
            </Col>
            <Col sm="12" md={6}>
              <Form.Control
                as="textarea"
                name="notes"
                disabled={!canMutate}
                value={formik.values.notes}
                onChange={formik.handleChange}
                isInvalid={formik.errors.notes}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.notes}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {bag && (
            <>
              {canMutate && (
                <Form.Group as={Row}>
                  <Col md={3} className="d-flex align-items-center">
                    <button
                      type="button"
                      onClick={() => setShowQrCode(!showQrCode)}
                      className="px-0 btn-link mr-1"
                    >
                      <>
                        {showQrCode ? (
                          <CaretDown size={17} />
                        ) : (
                          <CaretRight size={17} />
                        )}
                      </>
                    </button>
                    <Form.Label column sm={12} md="auto">
                      QR Code
                    </Form.Label>
                  </Col>
                </Form.Group>
              )}
              {showQrCode && (
                <>
                  <Row>
                    <Col>
                      <div className="mt-2">
                        <button
                          type="button"
                          onClick={() =>
                            downloadFile(
                              bag.qrCodeFile.fileName,
                              bag.qrCodeFile.displayName,
                              () => {
                                toast.success(`QR Code Downloaded`)
                              }
                            )
                          }
                          className="btn btn-link btn-sm"
                        >
                          Download{' '}
                          <CloudArrowDown className="text-primary ml-2" />
                        </button>
                      </div>
                    </Col>
                    <Col md={9} style={{ width: '50%', marginLeft: '25%' }}>
                      <div style={{ position: 'relative' }}>
                        <img
                          src={bag.qrCodeFile.fileUrl}
                          alt="QR Code"
                          style={{
                            width: '50%',
                            transform: 'scale(0.6)',
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              <Form.Group as={Row}>
                <Col md={3} className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => setShowAssignedJobs(!showAssignedJobs)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {showAssignedJobs ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label column sm="12" md="auto">
                    Assigned Jobs
                  </Form.Label>
                </Col>
              </Form.Group>
              {showAssignedJobs && (
                <Form.Group as={Row}>
                  <Col>
                    <EquipmentJobTable bagId={bag.id} tableHeight={300} />
                  </Col>
                </Form.Group>
              )}
              {canMutate && (
                <>
                  <Form.Group as={Row} className="">
                    <Col md={3} className="d-flex align-items-center">
                      <button
                        type="button"
                        onClick={() => setDisplayAuditLog(!displayAuditLog)}
                        className="px-0 btn-link mr-1"
                      >
                        <>
                          {displayAuditLog ? (
                            <>
                              <CaretDown size={17} />
                            </>
                          ) : (
                            <>
                              <CaretRight size={17} />
                            </>
                          )}
                        </>
                      </button>
                      <Form.Label column sm="12" md="auto">
                        History
                      </Form.Label>
                    </Col>
                  </Form.Group>
                  {displayAuditLog && (
                    <Form.Group as={Row} className="">
                      <Col md={12}>
                        <AuditLog
                          contentType={bag.contentType.model}
                          id={bag.id}
                        />
                      </Col>
                    </Form.Group>
                  )}
                </>
              )}
            </>
          )}
          {canMutate && (
            <>
              <Row>
                <Col md={2}>
                  <Button
                    type="submit"
                    block
                    variant="outline-primary"
                    disabled={submitting}
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>

                {bag && (
                  <>
                    <Col md={2}>
                      <Button
                        block
                        disabled={submitting}
                        onClick={() => {
                          setSubmitting(true)
                          setLoading(true)
                          downloadPdf({
                            variables: {
                              input: {
                                equipmentBagIds: [bag.id],
                                type: 'pdf',
                              },
                            },
                          })
                        }}
                        variant="outline-primary"
                      >
                        <Download className="mr-2" />
                        Download
                      </Button>
                    </Col>
                    <Col md={2}>
                      <Button
                        block
                        variant="outline-danger"
                        onClick={handleDelete}
                        disabled={submitting}
                      >
                        <Trash className="mr-2" />
                        Delete
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
              {submitting && (
                <Form.Row className="mt-3">
                  <Col>
                    <Loading message="Saving Bag..." />
                  </Col>
                </Form.Row>
              )}
            </>
          )}
        </Form>
      </div>
    </>
  )
}

export default BagForm
