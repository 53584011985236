import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Form, Button } from 'react-bootstrap'
import SortableInfiniteTable from '../../common/SortableInfiniteTable'
import { Dot, Funnel, PlusCircle } from 'react-bootstrap-icons'
import Loading from '../../common/Loading'
import ServiceItemModal from './ServiceItemModal'
import { loggedInUserVar } from '../../../libs/apollo'
import AddCollectionsModal from './AddCollectionsModal'

const Tasks = (props) => {
  const { organizationId, employeeId } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const defaultOrderBy = 'description'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [searchText, setSearchText] = useState()
  const [archive, setArchive] = useState(false)
  const [fetchMoreData, setFetchMoreData] = useState(false)
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(false)
  const [showServiceModal, setShowServiceModal] = useState(false)
  const [showCollectionsModal, setShowCollectionsModal] = useState(false)
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState(organizationId)
  const [organizationName, setOrganizationName] = useState()
  const [serviceData, setServiceData] = useState('')
  const [tasks, setTasks] = useState([])

  const toggleModalShow = () => {
    setShowServiceModal(!showServiceModal)
    if (serviceData) {
      setSelectedOrganizationId()
      setOrganizationName()
      setServiceData({})
    }
  }
  const tableColumns = [
    {
      Header: 'Organization',
      accessor: 'organization.name',
      serverSort: true,
      orderBy: 'organization__name',
    },
    {
      Header: 'Status',
      accessor: 'status',
      serverSort: true,
    },
    {
      Header: employeeId ? 'Group' : 'Collection',
      accessor: 'serviceItemGroup.name',
      filter: 'includes',
      serverSort: true,
      orderBy: 'service_item_group__name',
    },
    {
      Header: organizationId ? 'Name' : 'Description',
      accessor: 'description',
      serverSort: true,
    },
    {
      Header: 'Event Date',
      accessor: 'eventDate',
      serverSort: true,
      orderBy: 'event_date',
    },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
      serverSort: true,
      orderBy: 'due_date',
    },
    {
      Header: 'Finished Date',
      accessor: 'finishedDate',
      serverSort: true,
      orderBy: 'finished_date',
    },
    {
      Header: 'Completed By',
      accessor: (row) => {
        if (row.completedByEmployee) {
          return row.completedByEmployee.gaiaUser.fullName
        }
      },
      serverSort: true,
      orderBy: 'completed_by_employee__gaia_user__full_name',
    },
    {
      Header: 'Employees',
      accessor: (row) => (
        <>
          {row.employees.edges.map((employee) => (
            <div key={employee.node.id}>
              <p>
                <Dot className="mr-1" />
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {employee.node.gaiaUser.fullName},{' '}
                  {employee.node.gaiaUser.email}
                </span>
              </p>
            </div>
          ))}
        </>
      ),
    },
    {
      Header: 'Contacts',
      accessor: (row) => (
        <>
          {row.contacts.edges.map((contact) => (
            <div key={contact.node.id}>
              <p>
                <Dot className="mr-1" />
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {contact.node.fullName}, {contact.node.email}
                </span>
              </p>
            </div>
          ))}
        </>
      ),
    },
  ]

  if (organizationId) {
    tableColumns.splice(0, 1)
  }
  if (employeeId) {
    tableColumns.splice(7, 1)
  }

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Tasks(
        $organizationId: ID
        $employeeId: ID
        $cursor: String
        $searchTerm: String
        $orderBy: String
        $archive: Boolean
      ) {
        serviceItems(
          first: 50
          after: $cursor
          orderBy: $orderBy
          search: $searchTerm
          organization_Id: $organizationId
          employees_Id: $employeeId
          archive: $archive
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodeCount
          edges {
            node {
              id
              description
              notes
              dueDate
              archive
              completedByEmployee {
                id
                gaiaUser {
                  fullName
                }
              }
              eventDate
              finishedDate
              status
              organization {
                id
                name
              }
              serviceItemGroup {
                name
                id
              }
              employees {
                edges {
                  node {
                    id
                    gaiaUser {
                      id
                      fullName
                      email
                    }
                  }
                }
              }
              contacts {
                edges {
                  node {
                    id
                    fullName
                    email
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      // pollInterval: 30000,
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = {
        orderBy: defaultOrderBy,
      }
      if (!archive) {
        variables.archive = archive
      }
      if (organizationId) {
        variables.organizationId = organizationId
      }
      if (employeeId) {
        variables.employeeId = employeeId
      }
      query({ variables })
    }
  }, [initialQueryRun, setInitialQueryRun])

  const handleQueryData = (queryData) => {
    if (queryData?.serviceItems) {
      setHasMoreData(queryData?.serviceItems?.pageInfo?.hasNextPage)
      const currentTasks = []
      queryData.serviceItems.edges.map((task) => {
        const taskNode = task?.node
        if (
          !fetchMoreData ||
          !tasks.some((tasks) => tasks.id === taskNode.id)
        ) {
          currentTasks.push(taskNode)
        }
      })
      if (fetchMoreData) {
        setTasks((prevState) => {
          const currentIds = new Set(currentTasks.map((item) => item.id))
          const filteredPrevState = prevState.filter(
            (item) => !currentIds.has(item.id)
          )
          return [...filteredPrevState, ...currentTasks]
        })
        setFetchMoreData(false)
      } else {
        setTasks(currentTasks)
      }
    }
  }
  useEffect(() => {
    if (queryData?.serviceItems) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      handleQueryData(queryData)
      let text = 'Search 0 Tasks'
      if (queryData.serviceItems.nodeCount > 0) {
        text = `Search ${queryData.serviceItems.nodeCount} Tasks`
      }
      setSearchText(text)
    }
  }, [queryData])

  const fetchMore = () => {
    setFetchMoreData(true)
    const variables = {
      cursor: queryData?.serviceItems?.pageInfo?.endCursor,
    }
    if (!archive) {
      variables.archive = archive
    }
    if (organizationId) {
      variables.organizationId = organizationId
    }
    if (employeeId) {
      variables.employeeId = employeeId
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        handleQueryData(fetchMoreResult)
        return fetchMoreResult
      },
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (!archive) {
      variables.archive = archive
    }
    if (organizationId) {
      variables.organizationId = organizationId
    }
    if (employeeId) {
      variables.employeeId = employeeId
    }
    query({ variables })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoadingSearch(true)
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (!archive) {
      variables.archive = archive
    }
    if (organizationId) {
      variables.organizationId = organizationId
    }
    if (employeeId) {
      variables.employeeId = employeeId
    }
    query({ variables })
  }

  const handleIncludeDiscludesArchiveTask = () => {
    const currentArchive = !archive
    setArchive(currentArchive)
    const variables = {}
    if (orderBy) {
      variables.orderBy = orderBy
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (!currentArchive) {
      variables.archive = currentArchive
    }
    if (organizationId) {
      variables.organizationId = organizationId
    }
    if (employeeId) {
      variables.employeeId = employeeId
    }
    query({ variables })
  }

  if (!initialQueryRun)
    return (
      <Row>
        <Col>
          <Loading message="Loading Tasks..." />
        </Col>
      </Row>
    )
  if (queryError) return <>Error loading</>
  return (
    <>
      <div className="mt-4">
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={searchText}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            {['Administrator', 'Scheduling Manager'].includes(
              loggedInUser?.permissions?.group
            ) &&
              organizationId && (
                <>
                  <Button
                    variant="link"
                    onClick={() => setShowCollectionsModal(true)}
                  >
                    <PlusCircle className="mr-2" /> Add Collection
                  </Button>
                  <Button variant="link" onClick={toggleModalShow}>
                    <PlusCircle className="mr-2" /> New Task
                  </Button>
                </>
              )}
            <Button variant="link" onClick={handleIncludeDiscludesArchiveTask}>
              <Funnel size={14} className="mr-2" />
              {archive ? 'All ' : 'Active '}
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="12">
            <SortableInfiniteTable
              rowPointer
              loading={loadingSearch}
              hideGlobalFilter
              fetchMoreTableData={fetchMore}
              loadingMessage="Loading Tasks..."
              trStyleGenerator={(row) => {
                let style = {}
                if (row.original.status === 'Done') {
                  style = {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(0, 128, 0, 0.2)',
                  }
                } else if (row.original.status === 'In Progress') {
                  style = {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(255, 255, 0, 0.2)',
                  }
                } else if (row.original.status === 'To Do') {
                  style = {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(255, 0, 0, 0.2)',
                  }
                }
                return style
              }}
              onRowClick={(row) => {
                toggleModalShow()
                setSelectedOrganizationId(row.original.organization.id)
                setOrganizationName(row.original.organization.name)
                setServiceData(row.original)
              }}
              tableData={tasks}
              tableColumns={tableColumns}
              hasMoreTableData={hasMoreData}
              tableHeight={600}
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>
      </div>
      <ServiceItemModal
        employeeDetail={employeeId ? true : false}
        showModal={showServiceModal}
        toggleModal={toggleModalShow}
        updateServiceItem={serviceData}
        organizationId={selectedOrganizationId}
        organizationName={organizationId ? '' : organizationName}
      />
      {organizationId && (
        <AddCollectionsModal
          show={showCollectionsModal}
          organizationId={organizationId}
          handleClose={() => setShowCollectionsModal(false)}
        />
      )}
    </>
  )
}
export default Tasks
