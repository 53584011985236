import React, { useState } from 'react'
import { useQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Tab } from 'react-bootstrap'
import {
  CalendarDate,
  CalendarWeek,
  FileEarmarkText,
  PersonWorkspace,
  Briefcase,
  CardChecklist,
  Bell,
  ClockHistory,
} from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'
import EditEmployeeModal from './EditEmployeeModal'
import Notifications from '../notifications/Notifications'
import JobDetailModal from '../schedule/JobDetailModal'
import EmployeeSchedule from './employee_schedule/EmployeeSchedule'
import Schedule from '../schedule/Schedule'
import Loading from '../common/Loading'
import Page from '../layout/Page'
import AuditLog from '../audit_log/AuditLog'
import Tabs from '../common/Tabs'
import { loggedInUserVar } from '../../libs/apollo'
import EmployeeDetailEquipment from './EmployeeDetailEquipment'
import { useBoxEnabled } from '../../libs/box'
import BoxFolder from '../files/box/BoxFolder'
import FilesTable from '../files/FilesTable'
import Tasks from '../organization/service_items/Tasks'

const EmployeeDetail = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { employeeId } = useParams()
  const boxEnabled = useBoxEnabled()
  const [jobDetailId, setJobDetailId] = useState()
  const [showJobDetailModal, setShowJobDetailModal] = useState()
  const [activeTab, setActiveTab] = useState('Schedule')
  const EMPLOYEE_QUERY = gql`
    query EmployeeQuery($employeeId: ID!) {
      employee(id: $employeeId) {
        id
        hourlyPay
        boxFolderId
        contentType {
          model
        }
        kanban {
          id
          board
        }
        roles {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
        folder {
          id
        }
        gaiaUser {
          id
          contentType {
            model
          }
          isActive
          lastLogin
          loginBrowser
          loginLocation
          email
          firstName
          lastName
          fullName
          abbreviatedName
          phoneNumber
          emailNotificationsEnabled
          smsNotificationsEnabled
          secondaryPhoneNumber
          secondaryEmail
          addressLineOne
          addressLineTwo
          state
          city
          zipCode
          groups {
            edges {
              node {
                id
                name
              }
            }
          }
        }
        defaultRole {
          id
          name
        }
        salesPercent
        photoPercent
        payCommission
        rainPercent
        taskPercent
        servicePercent
        internalEmployeeId
        smtpHost
        smtpTls
        smtpPort
        smtpUser
        smtpSsl
        smtpPassword
        smtpSendgridSender
        smtpValid
        smtpGmail
      }
    }
  `
  const { error: employeeQueryError, data: employeeQueryData } = useQuery(
    EMPLOYEE_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        employeeId,
      },
    }
  )
  const toggleJobDetailModal = () => {
    if (showJobDetailModal) {
      setJobDetailId(null)
    }
    setShowJobDetailModal(!showJobDetailModal)
  }

  if (!employeeQueryData)
    return (
      <div className="mt-3">
        <Loading message="Loading Employee..." />
      </div>
    )
  if (employeeQueryError) return <>Error loading employee</>
  const { employee } = employeeQueryData
  let roles = '- - -'
  if (employee?.roles) {
    roles = ''
    employee?.roles?.edges.forEach((role) => {
      roles = `${roles} ${role.node.name},`
    })
    roles = roles.slice(0, -1)
  }
  return (
    <>
      <div
        style={
          loggedInUser?.permissions?.group === 'General Staff'
            ? {
                marginTop: '-10px',
              }
            : {}
        }
      >
        <Page
          title={
            loggedInUser?.permissions?.group !== 'General Staff' &&
            `${employee.gaiaUser.firstName} ${employee.gaiaUser.lastName}`
          }
        >
          <div>
            <Tabs
              activeKey={activeTab}
              onSelect={(tab) => {
                setActiveTab(tab)
              }}
            >
              <Tab
                key="Schedule"
                eventKey="Schedule"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <CalendarDate className="mr-2" />
                    Schedule
                  </b>
                }
              >
                <Schedule employeeId={employee.id} />
              </Tab>
              <Tab
                key="Work Events"
                eventKey="Work Events"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <CalendarWeek className="mr-2" />
                    Work Events
                  </b>
                }
              >
                <EmployeeSchedule employeeNode={employee} />
              </Tab>
              <Tab
                key="Profile"
                eventKey="Profile"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <PersonWorkspace className="mr-2" />
                    Profile
                  </b>
                }
              >
                <EditEmployeeModal employee={employee} />
              </Tab>
              <Tab
                key="Equipment"
                eventKey="Equipment"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <Briefcase className="mr-2" />
                    Equipment
                  </b>
                }
              >
                <EmployeeDetailEquipment
                  employeeId={employee.id}
                  employeeName={employee.gaiaUser.fullName}
                />
              </Tab>
              <Tab
                key="Tasks"
                eventKey="Tasks"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <CardChecklist className="mr-2" />
                    Tasks
                  </b>
                }
              >
                <Tasks employeeId={employee.id} />
              </Tab>
              <Tab
                key="Files"
                eventKey="Files"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <FileEarmarkText className="mr-2" />
                    Files
                  </b>
                }
              >
                {activeTab === 'Files' && (
                  <>
                    {boxEnabled && (
                      <BoxFolder rootBoxFolderId={employee.boxFolderId} />
                    )}
                    {!boxEnabled && (
                      <div className="mt-4">
                        <FilesTable
                          employeeId={employee.id}
                          employeeDescription={employee.gaiaUser.fullName}
                          folderId={employee.folder.id}
                        />
                      </div>
                    )}
                  </>
                )}
              </Tab>

              {['Administrator', 'Scheduling Manager'].includes(
                loggedInUser?.permissions?.group
              ) && (
                <Tab
                  key="Notifications"
                  eventKey="Notifications"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <Bell className="mr-2" />
                      Notifications
                    </b>
                  }
                >
                  {activeTab === 'Notifications' && (
                    <Notifications employee={employee} />
                  )}
                </Tab>
              )}
              {['Administrator', 'Scheduling Manager'].includes(
                loggedInUser?.permissions?.group
              ) && (
                <Tab
                  key="History"
                  eventKey="History"
                  style={{ marginTop: '10px' }}
                  title={
                    <b>
                      <ClockHistory className="mr-2" />
                      History
                    </b>
                  }
                >
                  {activeTab === 'history' && (
                    <Row>
                      <Col md={12}>
                        <AuditLog
                          contentTypesRelayIds={{
                            [employee.contentType.model]: [employee.id],
                            [employee.gaiaUser.contentType.model]: [
                              employee.gaiaUser.id,
                            ],
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </Tab>
              )}
            </Tabs>
            <JobDetailModal
              jobId={jobDetailId}
              showModal={showJobDetailModal}
              toggleModal={toggleJobDetailModal}
            />
          </div>
        </Page>
      </div>
    </>
  )
}
export default EmployeeDetail
